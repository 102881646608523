import React from 'react';
import PropTypes from 'prop-types';
import { Footer } from '..';
import { ContainerFluid } from '../../App.styled';

const Campanha = ({ children }) => {
  return (
    <>
      <ContainerFluid>{children}</ContainerFluid>
      <Footer />
    </>
  );
};

Campanha.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Campanha;
