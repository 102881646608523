import styled from 'styled-components';
import { motion } from 'framer-motion';
import {
  GrBall1Mobile,
  GrBall2Mobile,
  GrBall3Mobile,
  GrBall4Mobile,
} from '../../assets';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: ${p => (p.justifyCenter ? 'center' : 'space-between')};
  width: 100%;
  max-width: 767px;
  padding-top: 0;
  min-height: 1400px;
`;

export const ContainerConheca = styled.div`
  position: relative;
  width: 100%;
  height: 1340px;
  /* border: solid 1px red; */
`;

export const Gota = styled(motion.div)`
  position: absolute;
  width: 294px;
  height: 290px;
  left: 0;
  /* border: solid 1px red; */
  background: url(${GrBall1Mobile});
  background-repeat: no-repeat;
  background-position: center;
`;

export const Gota2 = styled(motion.div)`
  position: absolute;
  width: 294px;
  height: 360px;
  left: 0;
  /* border: solid 1px red; */
  background: url(${GrBall2Mobile});
  background-repeat: no-repeat;
  background-position: center;
`;

export const Gota3 = styled(motion.div)`
  position: absolute;
  width: 310px;
  height: 400px;
  left: 0;
  /* border: solid 1px red; */
  background: url(${GrBall3Mobile});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 130%;
`;

export const Gota4 = styled(motion.div)`
  position: absolute;
  width: 310px;
  height: 340px;
  left: 0;
  /* border: solid 1px red; */
  background: url(${GrBall4Mobile});
  background-repeat: no-repeat;
  background-position: center;
`;

export const Gota5 = styled(motion.div)`
  position: absolute;
  width: 297px;
  height: 297px;
  left: 0;
  /* border: solid 1px red; */
  background: url(${GrBall4Mobile});
  background-repeat: no-repeat;
`;

export const Title = styled(motion.h2)`
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  font-weight: 700;
  text-align: center;
  margin-top: 0;
  margin-bottom: 46px;
  max-width: calc(100vw - 20px);

  span {
    text-decoration: underline;
  }

  @media (min-width: 768px) {
    font-size: 56px;
    line-height: 64px;
    margin-top: 0;
    margin-bottom: 46px;
  }
`;

export const ContainerText = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 172px;
  height: 172px;
  border-radius: 100px;
  font-size: 24px;
  line-height: 26px;
  color: #ffffff;
  font-weight: 500;
  /* border: solid 1px red; */
`;

export const Text = styled.span`
  text-align: center;
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
  font-weight: 500;
  /* border: solid 1px orange; */

  &.smaller {
    font-size: 20px;
    line-height: 26px;
  }
`;

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: inherit;
  padding-top: 0;
  height: auto;
  /* border: solid 1px yellow; */
`;
