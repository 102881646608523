/* eslint-disable */
import React from 'react';
import { Container, ContainerFull } from '../../assets/globalStyle';
import {
  HeaderSectionImageText,
  DivisorButton,
  ContentTextMenuFamilyOffice,
  TransformarVidaFinanceira
} from '../../components';

function ParaVoceWealthPage() {
  const gotoWhatsApp = (phoneNumber) => {
    const url = `https://wa.me/${phoneNumber}`;
    window.open(url, '_blank');
  }

  return (
    <React.Fragment>
      <ContainerFull>
        <HeaderSectionImageText
          themeType="darkBlue"
          image="ImageParaVoceFamilyOffice"
          title="Yuno Family Office"
          text="Para você que possui um <b>patrimônio consolidado</b> e se preocupa em <b>usufruir, preservar e transferir bens para seus herdeiros.</b>" />
      </ContainerFull>
      <ContainerFull>
        <ContentTextMenuFamilyOffice />
      </ContainerFull>
      <ContainerFull>
        <DivisorButton handleClick={() => gotoWhatsApp(5562993376742)} bordered title="Inicie agora seu Planejamento Financeiro" themeType="whiteBlack" />
      </ContainerFull>
      <ContainerFull>
        <TransformarVidaFinanceira />
      </ContainerFull>
    </React.Fragment>
  );
}

export default ParaVoceWealthPage;
