import styled from 'styled-components';
import { ArrowLeftIcon } from '../../assets';

export const ButtonWrapper = styled.div`
  height: 40px;
  width: 40px;
  opacity: 0.8;
  transition: all 0.3s ease;
  background-color: #ffffff;
  pointer-events: all;
  transform: rotate(90deg);

  a {
    display: block;
    border: solid 1px red;
    width: 40px;
    height: 40px;
  }

  a:-webkit-any-link:active {
    color: #ffffff !important;
  }

  -webkit-mask: url(${ArrowLeftIcon}) no-repeat center;
  mask: url(${ArrowLeftIcon}) no-repeat center;
  -webkit-mask-size: 40px 40px;
  mask-size: 40px 40px;

  &:hover {
    opacity: 1;
  }
`;
