/* eslint-disable */
import styled from 'styled-components';
import { motion } from 'framer-motion'

export const Container = styled.div`
  width: 420px;
  background: ${p => p.bg};

  @media(max-width: 767px) {
    /* width: calc(100% - 20px); */
    width: 100%;
  }
`;

export const Header = styled.div`
  margin-top: -1px;
  display: flex;
  padding: 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  background: ${p => p.color};
  transition: all 0.3s ease;

  h2 {
    color: #ffffff;
    text-align: center;
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    margin: 0;
    padding: 0;

    @media(max-width: 767px) {
      font-size: 22px;
    }
  }
`;

export const Body = styled.div`
  position: relative;
  height: auto;
  min-height: 250px;
  padding: 20px 3px 30px 3px;
  background: ${p => p.bg};
  color: #282728;
`;

export const Slider = styled.div`
  position: relative;
  width: calc(100vw + 20px);
  height: 170px;
  overflow: hidden;

  @media (min-width: 768px) {
    width: 100%;
  }
`

export const LogosContainer = styled(motion.div)`
  display: flex;
  flex-wrap: nowrap;
  width: auto;
  height: 170px;
`

export const PersonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${p => p.largura}px;
  min-width: ${p => p.largura}px;
  height: 170px;
  min-height: 170px;

  /* @media (max-width: 767px) {
    width: 33%;
    max-width: 33%;
    min-width: 33%;
  } */

  h2 {
    color: #000;
    text-align: center;
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    margin: 8px 0;
  }

  p {
    color: #000;
    text-align: center;
    font-size: 10px;
    font-weight: 400;
  }
`;

export const ImageContainer = styled.div`
  width: 75px;
  min-width: 75px;
  height: 75px;
  min-height: 75px;
  border-radius: 75px;
  border: solid 2px #f8f8f8;
  transition: all .3s ease;

  &.active {
    border: solid 2px #E2E2E2;
  }

  background: url(${p => p.image});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
`;

export const BulletsContainer = styled.div`
  position: absolute;
  left: 0;
  bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  height: 50px;

  svg {
    transform: scale(1.1);
    cursor: pointer;
    color: #0C053F;
    margin: 0 20px;
    transition: all .3s ease;

    &.left {
      transform: scale(-1.1);
    }

    &:hover {
      color: #210b9d;
    }
  }
`

export const Bullet = styled.span`
  width: 10px;
  height: 10px;
  background: #E2E2E2;
  border-radius: 7px;
  cursor: pointer;
  transform: scale(1);
  transition: all 0.3s ease;

  &.active {
    transform: scale(1.2);
    background: #0C053F;
  }

  &:hover:not(.active) {
    transform: scale(1.1);
    background: #0C053F;
  }
`


