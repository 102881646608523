import styled from 'styled-components';
import { motion } from 'framer-motion';
import { ImageHomeViverMelhor } from '../../assets';

export const ContainerFull = styled.div`
  position: relative;
  display: flex;
  padding: 0 20px;
  width: 100%;
  margin-top: auto;

  @media (max-width: 767px) {
    min-height: 1460px;
  }

  &.melhor-investimento {
    background: url(${ImageHomeViverMelhor}), #0c053f;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: center 0px;

    @media (min-width: 768px) {
      background-position: center -60px;
    }
  }

  @media (min-width: 768px) {
    min-height: 1320px;
  }

  &.separator-bootom {
    border-bottom: solid 1px rgba(255, 255, 255, 0.1);
  }
`;

export const Selo = styled(motion.img)`
  position: absolute;
  top: 180px;
  left: 50%;
  /* border: solid 1px pink; */

  @media (max-width: 767px) {
    width: 120px;
    transform: translateX(-50%);
  }

  @media (min-width: 768px) {
    left: 70px;
    top: 180px;
    /* border: solid 1px yellow; */
  }

  @media (min-width: 992px) {
    top: 180px;
    width: 170px;
    /* border: solid 1px red; */
  }

  @media (min-width: 1200px) {
    top: 32px;
    width: 150px;
    /* border: solid 1px orange; */
  }

  @media (min-width: 1600px) {
    top: 32px;
    width: initial;
    /* border: solid 1px green; */
  }
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${p => (p.column ? 'column' : 'row')};
  align-items: center;
  justify-content: ${p => (p.justifyCenter ? 'center' : 'space-between')};
  width: 100%;
  max-width: 1340px;
  margin: ${p => (p.margin ? p.margin : '0 auto')};
  height: 400px;
  justify-content: flex-start;

  @media (min-width: 768px) {
    height: 100%;
    padding: 0 20px;
  }

  @media (min-width: 992px) {
    height: 500px;
    padding: 0 20px;
    /* border: solid 1px red; */
  }

  @media (min-width: 1200px) {
    height: 600px;
    padding: 0 180px;
    /* border: solid 1px orange; */
  }

  @media (min-width: 1400px) {
    height: 1220px;
    padding: 0 150px;
    /* border: solid 1px green; */
  }
`;

export const Title = styled(motion.h1)`
  font-size: 30px;
  letter-spacing: 0;
  color: #ffffff;
  font-weight: 600;
  margin-top: 20px;

  @media (min-width: 768px) {
    font-size: 40px;
  }

  @media (min-width: 992px) {
    font-size: 50px;
  }

  @media (min-width: 1200px) {
    font-size: 48px;
  }

  @media (min-width: 1600px) {
    font-size: 66px;
  }
`;

export const Text = styled(motion.p)`
  font-size: 21px;
  line-height: 32px;
  color: #ffffff;
  font-weight: 200;
  width: calc(100% - 20px);
  margin-top: 300px;

  @media (min-width: 768px) {
    margin-top: 424px;
    width: 660px;
  }
`;

export const ButtonClose = styled.button`
  width: 30px;
  height: 30px;
  border: 0;
  outline: 0;
  cursor: pointer;
  display: flex;
  background: transparent;
  align-items: center;
  justify-content: center;
`;

export const Button = styled(motion.div)`
  border: solid 1px #ffffff;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  padding: 9px 10px 9px 20px;
  border-radius: 40px;
  color: #140361;
  cursor: pointer;
  background: rgba(255, 255, 255, 0);
  transition: all 0.2s ease;
  margin-top: 40px;
  background-color: #dcbf64;

  &:hover {
    background: #c4a651;
  }

  @media (max-width: 767px) {
    font-size: 12px;
  }

  @media (min-width: 768px) {
    margin-top: 90px;
  }
`;

export const CardsContainer = styled(motion.div)`
  width: 100%;
  margin-top: 100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 30px;

  @media (max-width: 767px) {
    margin-top: 40px;
    align-items: center;
    justify-content: center;
  }
`;

export const Card = styled.div`
  display: flex;
  height: auto;
  padding: 20px 15px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  background: #130b4a;
  box-shadow: 3px 10px 20px 0px rgba(0, 0, 0, 0.5);

  h2 {
    width: auto;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    color: #ffffff;
    white-space: nowrap;
  }

  p {
    width: 260px;
    text-align: left;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: #f7f7f7;
  }
`;
