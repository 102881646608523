import styled from 'styled-components';
import { motion } from 'framer-motion';
import { GrGraficoDescubraEstrategia } from '../../assets';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${p => (p.column ? 'column' : 'row')};
  align-items: flex-start;
  justify-content: ${p => (p.justifyCenter ? 'center' : 'space-between')};
  width: 100%;
  max-width: 1340px;
  margin: ${p => (p.margin ? p.margin : '0 auto')};
  min-height: 420px;

  @media (min-width: 768px) {
    align-items: center;
    height: auto;
  }

  @media (min-width: 992px) {
    height: auto;
    /* border: solid 1px red; */
  }

  @media (min-width: 1200px) {
    height: auto;
    /* border: solid 1px orange; */
  }

  @media (min-width: 1400px) {
    height: auto;
    /* border: solid 1px green; */
  }
`;

export const Grafico = styled(motion.div)`
  position: absolute;
  top: -56%;
  left: 0;
  width: 100%;
  height: 725px;
  background: url(${GrGraficoDescubraEstrategia});
  background-position: 0;
  background-size: 100%;
  background-repeat: no-repeat;
  z-index: 0;

  @media (min-width: 768px) {
    top: -40%;
    width: 100%;
    background-size: 100% 100%;
  }
`;

export const GroupLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;

  @media (min-width: 768px) {
    width: 50%;
  }
`;

export const GroupRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  height: 100%;

  @media (min-width: 768px) {
    width: 50%;
  }
`;

export const Title = styled(motion.h2)`
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  font-weight: 700;
  text-align: left;
  margin-top: 0;
  margin-bottom: 30px;
  max-width: calc(100vw - 20px);
  z-index: 1;

  span {
    text-decoration: underline;
  }

  @media (min-width: 768px) {
    font-size: 30px;
    line-height: 47px;
    margin-top: 0;
    margin-bottom: 20px;
    max-width: 634px;
  }

  @media (min-width: 992px) {
    font-size: 40px;
    line-height: 47px;
  }
`;

export const BigTitle = styled(motion.h1)`
  font-size: 56px;
  line-height: 24px;
  color: #ffffff;
  font-weight: 700;
  text-align: left;
  margin-top: 0;
  margin-bottom: 46px;
  max-width: calc(100vw - 20px);
  z-index: 1;

  span {
    text-decoration: underline;
  }

  @media (min-width: 768px) {
    font-size: 56px;
    line-height: 50px;
    margin-top: 0;
    margin-bottom: 46px;
    max-width: 634px;
  }
`;

export const Text = styled(motion.h3)`
  font-size: 16px;
  line-height: 26px;
  color: #ffffff;
  text-align: right;
  font-weight: 500;
  margin-bottom: 40px;
  max-width: 500px;
  z-index: 1;

  @media (min-width: 768px) {
    font-size: 24px;
    max-width: 500px;
    line-height: 32px;
  }
`;

export const Section = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: inherit;
  padding-top: 0;
  height: 350px;
  padding: 0 20px;
  /* border-bottom: solid 1px red; */

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    height: 410px;
    padding: 0 40px;
  }

  @media (min-width: 992px) {
    padding: var(--border-distance);
  }
`;
