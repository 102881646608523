import styled from 'styled-components';
import { motion } from 'framer-motion';
import { GrBall1, GrBall3, GrBall4, GrBall2 } from '../../assets';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${p => (p.column ? 'column' : 'row')};
  align-items: flex-start;
  justify-content: ${p => (p.justifyCenter ? 'center' : 'space-between')};
  width: 100%;
  max-width: 1340px;
  padding-top: 170px;
  min-height: 340px;

  @media (min-width: 768px) {
    align-items: center;
    height: auto;
  }

  @media (min-width: 992px) {
    height: auto;
    /* border: solid 1px red; */
  }

  @media (min-width: 1200px) {
    height: auto;
    /* border: solid 1px orange; */
  }

  @media (min-width: 1340px) {
    height: auto;
    /* border: solid 1px green; */
  }
`;

export const ContainerConheca = styled.div`
  position: relative;
  width: 1340px;
  height: 425px;
`;

export const Gota1 = styled(motion.div)`
  position: absolute;
  width: 500px;
  height: 491px;
  /* border: solid 1px red; */
  background: url(${GrBall1});
  background-repeat: no-repeat;
`;

export const Gota2 = styled(motion.div)`
  position: absolute;
  width: 500px;
  height: 491px;
  /* border: solid 1px red; */
  background: url(${GrBall2});
  background-repeat: no-repeat;
`;

export const Gota3 = styled(motion.div)`
  position: absolute;
  width: 500px;
  height: 491px;
  /* border: solid 1px red; */
  background: url(${GrBall3});
  background-repeat: no-repeat;
`;

export const Gota4 = styled(motion.div)`
  position: absolute;
  width: 500px;
  height: 491px;
  /* border: solid 1px red; */
  background: url(${GrBall4});
  background-repeat: no-repeat;
`;

export const Title = styled(motion.h2)`
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  font-weight: 700;
  text-align: center;
  margin-top: 0;
  margin-bottom: 46px;
  max-width: calc(100vw - 20px);

  span {
    text-decoration: underline;
  }

  @media (min-width: 768px) {
    font-size: 56px;
    line-height: 64px;
    margin-top: 0;
    margin-bottom: 46px;
  }
`;

export const ContainerText = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 172px;
  height: 172px;
  border-radius: 100px;
  font-size: 16px;
  line-height: 26px;
  color: #ffffff;
  font-weight: 500;
  /* border: solid 1px red; */

  &.estrategia {
    @media (min-width: 768px) {
      width: 200px;
    }
  }
  &.sonhos-realizados {
    @media (min-width: 768px) {
      width: 136px;
    }
  }

  @media (min-width: 768px) {
  }
`;

export const Text = styled.span`
  text-align: center;
  font-size: 26px;
  line-height: 32px;
  color: #ffffff;
  font-weight: 500;
  /* border: solid 1px orange; */

  &.smaler-text {
    @media (min-width: 768px) {
      font-size: 22px;
      line-height: 28px;
      width: 130px;
    }
  }

  @media (min-width: 768px) {
  }
`;

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: inherit;
  padding-top: 0;
  height: 600px;
  overflow-x: hidden;
  /* border-bottom: solid 1px red; */

  @media (min-width: 768px) {
    height: 610px;
  }
`;
