import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: ${p => p.height};
  align-items: center;
`;

export const Nav = styled.nav`
  display: flex;
  height: 100%;
`;

export const Menu = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`;

export const SubButton = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 24px;
  font-size: 1rem;
  font-weight: 500;
  line-height: 28px;
  opacity: 0.8;
  transition: all 0.3s ease;
  height: 100%;
  border-bottom: solid 1px rgba(255, 255, 255, 0);
  border-top: solid 1px rgba(255, 255, 255, 0.2);

  &:has(a.active) {
    border-bottom: solid 1px rgba(255, 255, 255, 1);
    border-top: solid 1px rgba(255, 255, 255, 1);
    opacity: 1;
  }

  &:hover {
    a {
      opacity: 1 !important;
      color: ${p =>
        p.color === 'white' ? 'var(--color-white)' : 'var(--color-white)'};
    }
  }
  a {
    display: inline-flex;
    flex-direction: column;
    white-space: nowrap;
    align-items: center;
    line-height: 16px;
    height: 100%;
    opacity: 0.5;

    span {
      font-size: 12px;
      font-weight: 200;
      white-space: nowrap;
    }

    color: ${p =>
      p.color === 'white' ? 'var(--color-white)' : 'var(--color-white)'};
    transition: all 0.3s ease;

    &.active {
      color: var(--color-white);
      opacity: 1;
    }
  }
`;

export const SubMenu = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 100%;
  left: 0;
  height: auto;
  min-height: 60px;
  width: auto;
  min-width: 150px;
  background: ${p => p.background};
`;

export const Button = styled.li`
  position: relative;
  padding: 0 40px;
  font-size: 1rem;
  font-weight: 500;
  line-height: 26px;
  opacity: 1;
  transition: all 0.3s ease;
  height: 100%;
  border-bottom: solid 1px rgba(255, 255, 255, 0);

  &:has(a.active) {
    border-bottom: solid 1px rgba(255, 255, 255, 1);
  }

  &:hover {
    a {
      opacity: 0.8;
      color: ${p =>
        p.color === 'white' ? 'var(--color-white)' : 'var(--color-white)'};
    }
  }
  a {
    display: inline-flex;
    align-items: center;
    height: 100%;
    opacity: 0.5;

    color: ${p =>
      p.color === 'white' ? 'var(--color-white)' : 'var(--color-white)'};
    transition: all 0.3s ease;

    &.active {
      color: var(--color-white);
      opacity: 1;
    }
  }
`;

export const ButtonOutside = styled.li`
  margin: 0 15px;
  line-height: 26px;
`;

export const Hamburguer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 25px;
  height: 25px;
  align-self: end;
  z-index: 99;

  span {
    width: 22px;
    height: 3px;
    border-radius: 3px;
    background: #f7f7f7;
    transform: translateX(0);
    transition: all 0.25s ease;

    &.active {
      transform: translateX(5px);
    }
  }
`;
