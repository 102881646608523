/* eslint-disable */
import React from 'react';
import { useResize } from '../../hooks';
import { ContainerFull,
          Container,
          ImageContainer,
          TextContainer,
          Text,
          Title } from './index.styled';

const QuemSomos2Component = () => {

  const { isMobile } = useResize();

  return (
    <ContainerFull>
      { !isMobile &&
        <Container>
          <ImageContainer />
          <TextContainer>
            <Title initial={{ opacity: 0, y: 10 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{
                duration: 0.5,
                delay: 0.3,
              }}>Em um mundo onde o foco está sempre no <b>“o que”</b> fazer, pouco se discute sobre o <b>“porquê”.</b></Title>
            <Text initial={{ opacity: 0, y: 10 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{
                duration: 0.5,
                delay: 0.3,
              }}>Foi com essa percepção que os fundadores Gustavo Alves, João Gondim e Mauricio Vono se uniram em meados de 2017 para criar a Yuno: uma empresa de planejamento financeiro focada em Planejamento de Vida!</Text>
          </TextContainer>
        </Container>
      }
      { isMobile &&
        <Container>
            <TextContainer>
              <Title initial={{ opacity: 0, y: 10 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{
                  duration: 0.5,
                  delay: 0.3,
                }}>Em um mundo onde o foco está sempre no <b>“o que”</b> fazer, pouco se discute sobre o <b>“porquê”.</b></Title>
            </TextContainer>
            <ImageContainer />
            <TextContainer>
              <Text initial={{ opacity: 0, y: 10 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{
                  duration: 0.5,
                  delay: 0.3,
                }}>Foi com essa percepção que os fundadores Gustavo Alves, João Gondim e Mauricio Vono se uniram em meados de 2017 para criar a Yuno: uma empresa de planejamento financeiro focada em Planejamento de Vida!</Text>
          </TextContainer>
        </Container>
      }
    </ContainerFull>
  );
};

export default QuemSomos2Component;
