import styled from 'styled-components';
import { LogoSlogan } from '../../assets';

export const Container = styled.div`
  width: 110px;
  height: 70px;
  background: url(${p => (p.type === 'white' ? LogoSlogan : LogoSlogan)});
  background-repeat: no-repeat;
  background-size: contain;

  &.pointer {
    cursor: pointer;
  }

  &.bigger {
    width: 240px;
    height: 60px;

    @media (min-width: 768px) {
      width: 391px;
      height: 86px;
    }
  }
`;
