import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './index.styled';

const LogoComponent = ({ handleClick, type, className }) => {
  return <Container className={className} onClick={handleClick} type={type} />;
};

LogoComponent.propTypes = {
  handleClick: PropTypes.func,
  type: PropTypes.oneOf(['white', 'color']),
  className: PropTypes.string,
};

LogoComponent.defaultProps = {
  handleClick: () => {},
  type: 'white',
  className: '',
};

export default LogoComponent;
