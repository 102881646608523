/* eslint-disable */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { BsChevronRight } from 'react-icons/bs';
import { ContainerFull,
          SaibaMaisWrapper,
          SaibaMais,
          Text,
          Icone,
          LineNumbers,
          BoxText,
          Title } from './index.styled';

const AtendemosTodoBrasilComponent = () => {

  const navigate = useNavigate();
  const openLink = link => navigate(`/${link}`);

  return (
    <ContainerFull className="atendemos-todo-brasil">
          <Title initial={{ opacity: 0, y: 10 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{
              duration: 0.5,
              delay: 0.3,
            }}>De Goiânia, atendemos <span>o Brasil todo.</span></Title>
          <Text initial={{ opacity: 0, y: 10 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{
              duration: 0.5,
              delay: 0.3,
            }}>O nosso legado é a história de sucesso de centenas de famílias.</Text>
          <LineNumbers initial={{ opacity: 0, y: 10 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{
              duration: 0.5,
              delay: 0.3,
            }}>
            <span className="line-100"></span>
            <BoxText>
              <h2>+7</h2>
              <p>Anos de<br />mercado</p>
            </BoxText>
            <span className="line-80"></span>
            <BoxText>
              <h2>+700</h2>
              <p>Famílias atendidas em<br />16 estados e 9 países</p>
            </BoxText>
            <span className="line-80"></span>
            <BoxText>
              <h2>Ø</h2>
              <p>zero comissões<br />sobre investimentos</p>
            </BoxText>
            <span className="line-auto"></span>
          </LineNumbers>
          <SaibaMaisWrapper initial={{ opacity: 0, y: 10 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{
              duration: 0.5,
              delay: 0.3,
            }}>
            <SaibaMais onClick={() => openLink('sobre-nos')} className="h-button" >
              <span>Saiba mais</span>
              <Icone color="#FFFFFF" bulletColor="#0c053f">
                {React.createElement(BsChevronRight)}
                </Icone>
            </SaibaMais>
          </SaibaMaisWrapper>

    </ContainerFull>
  );
};

export default AtendemosTodoBrasilComponent;
