import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: ${p => (p.justifyCenter ? 'center' : 'space-between')};
  width: 100%;
  max-width: 1340px;
  margin: ${p => (p.margin ? p.margin : '0 auto')};
  min-height: 296px;

  @media (min-width: 768px) {
    align-items: center;
    height: auto;
  }

  @media (min-width: 992px) {
    height: auto;
    /* border: solid 1px red; */
  }

  @media (min-width: 1200px) {
    height: auto;
    /* border: solid 1px orange; */
  }

  @media (min-width: 1400px) {
    height: auto;
    /* border: solid 1px green; */
  }
`;

export const Title = styled(motion.h2)`
  font-size: 32px;
  color: #ffffff;
  font-weight: 700;
  margin-bottom: 0;
  text-align: center;

  max-width: calc(100vw - 20px);
  white-space: nowrap;

  span {
    text-decoration: underline;
  }

  @media (min-width: 768px) {
  }

  @media (min-width: 992px) {
    font-size: 32px;
    margin-top: 32px;
    margin-bottom: 0;
    max-width: 500px;
  }
  @media (min-width: 1200px) {
    font-size: 40px;
    margin-top: 22px;
    max-width: 100%;
  }
`;

export const ContainerEsq = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 140px;

  @media (min-width: 992px) {
    flex-direction: row;
    margin: 0 0 20px 0;
    width: 50%;
    transform: scale(0.7);
  }

  @media (min-width: 1200px) {
    transform: scale(1);
  }
`;

export const ContainerDir = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  button {
    white-space: nowrap;
    margin-top: 20px;
  }

  @media (min-width: 992px) {
    width: 50%;
    justify-content: flex-end;

    button {
      margin-top: 0;
    }
  }
`;

export const Img = styled(motion.img)`
  width: 300px;
  height: auto;
  margin: 0 auto;
  padding-bottom: 80px;

  @media (min-width: 992px) {
    display: block;
    max-width: 320px;
    max-height: 139px;
    width: auto;
    height: auto;
    padding-bottom: 0;
    margin: 0;
  }
`;

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: inherit;
  padding-top: 0;
  min-height: 340px;
  /* border-bottom: solid 1px red; */

  @media (min-width: 992px) {
    flex-direction: row;
    justify-content: space-between;
    padding: var(--border-distance);
    height: 340px;
  }
`;
