/* eslint-disable */
import React from 'react';
import { useResize } from '../../hooks';
import { ContainerFull,
          Container,
          TextContainer,
          Text,
          Title } from './index.styled';

const QuemSomos3Component = () => {
  const { isMobile } = useResize();

  return (
    <ContainerFull>
      { !isMobile &&
        <Container>
          <TextContainer>
            <Title initial={{ opacity: 0, y: 10 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{
                duration: 0.5,
                delay: 0.3,
              }}>No mercado, percebemos que as soluções disponíveis eram superficiais, focadas no aspecto financeiro e deixando de lado o propósito por trás de cada pessoa. Além disso, o conflito de interesse presente nas orientações dos agentes de investimentos limitavam os resultados dos clientes, pois centralizavam as relações em torno de captar mais recursos para as companhias.</Title>
          </TextContainer>
        </Container>
      }
      { isMobile &&
      <React.Fragment>
        <Container column>
            <TextContainer>
              <Title initial={{ opacity: 0, y: 10 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{
                  duration: 0.5,
                  delay: 0.3,
                }}>No mercado, percebemos que as soluções disponíveis eram superficiais, focadas no aspecto financeiro e deixando de lado o propósito por trás de cada pessoa. Além disso, o conflito de interesse presente nas orientações dos agentes de investimentos limitavam os resultados dos clientes, pois centralizavam as relações em torno de captar mais recursos para as companhias.</Title>
            </TextContainer>
          </Container>
        </React.Fragment>
      }
    </ContainerFull>
  );
};

export default QuemSomos3Component;
