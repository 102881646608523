import React from 'react';
import PropTypes from 'prop-types';
import { ButtonWrapper } from './index.styled';

const ButtonColorComponent = ({
  handleClick,
  size,
  children,
  bgcolors,
  colors,
  style,
  className,
  animateInView,
}) => {
  const inView = {
    initial: { opacity: 0, y: 10 },
    transition: {
      duration: 0.5,
      delay: 0.1,
    },
  };

  const simple = {
    initial: { opacity: 1, y: 0 },
    transition: {
      duration: 0,
      delay: 0,
    },
  };

  return (
    <ButtonWrapper
      bgcolors={bgcolors}
      colors={colors}
      size={size}
      onClick={handleClick}
      style={style}
      className={className}
      variants={animateInView ? inView : simple}
      initial="initial"
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition="transition"
    >
      {children}
    </ButtonWrapper>
  );
};

ButtonColorComponent.propTypes = {
  handleClick: PropTypes.func,
  size: PropTypes.string,
  bgcolors: PropTypes.arrayOf(PropTypes.string),
  colors: PropTypes.arrayOf(PropTypes.string),
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.shape]),
  className: PropTypes.string,
  animateInView: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

ButtonColorComponent.defaultProps = {
  handleClick: () => {},
  size: '20px',
  bgcolors: ['#F2F1FF', '#4739EB', '#1C11A7'],
  colors: ['#4739EB', '#FFFFFF', '#FFFFFF'],
  style: {},
  animateInView: false,
  className: '',
};

export default ButtonColorComponent;
