/* eslint-disable */
import React from 'react';
import { SplitMenu } from '..'
import { Section, Title, Container, ContainerFull } from './index.styled';

const ParaGarantirmosIssoComponent = () => {

  return (
    <ContainerFull>
      <Container>
        <Section>
          <Title>Para garantirmos isso,<br />
          <span>exploramos estratégias dentro <b>de seis áreas financeiras:</b></span></Title>
          <SplitMenu type="yuno" />
        </Section>
      </Container>
    </ContainerFull>
  );
};

export default ParaGarantirmosIssoComponent;
