import styled from 'styled-components';

export const ContainerFull = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  background: #f7f7f7;
  overflow: hidden;
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${p => (p.column ? 'column' : 'row')};
  align-items: center;
  justify-content: ${p => (p.justifyCenter ? 'center' : 'space-between')};
  width: 100%;
  max-width: 1340px;
  margin: ${p => (p.margin ? p.margin : '0 auto')};
  height: 100%;
`;

export const Title = styled.h5`
  font-weight: 400;
  margin-bottom: 1.5rem;
  line-height: 1rem;
  font-size: 20px;

  span {
    font-weight: 400;
    font-size: 14px;
  }

  @media (max-width: 767px) {
    padding: 40px;
    margin-bottom: 0;

    span {
      display: inline-block;
      margin-top: 20px;
    }
  }

  @media (min-width: 768px) {
    font-size: 37px;
    line-height: 3.6rem;
    span {
      font-size: 21px;
    }
  }
`;

export const Section = styled.section`
  width: inherit;
  display: flex;
  flex-direction: column;
  padding: 0 0 10px 0;
  height: auto;
  min-height: auto;

  @media (min-width: 768px) {
    padding: 66px 0 40px 0px;
    min-height: 870px;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
  height: auto;
  min-height: 300px;
`;
