/* eslint-disable */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FaChevronRight } from 'react-icons/fa';
import { useResize } from '../../hooks';
import { Container,
        Header,
        Body,
        LogosContainer,
        Slider,
        BulletsContainer,
        ImageContainer,
        BigImageContainer,
        Bullet,
       } from './index.styled';

const ParceirosComponent = ({ style, className }) => {

  const { isMobile, width } = useResize();
  const steps = 7;
  const [vwidth, setVWidth ] = useState(width);
  const [fraction, setFraction ] = useState(null);
  const [ step, setStep ] = useState(0);

  useEffect(() => {
    isMobile ? setVWidth(width) : setVWidth(414);
    setFraction(vwidth / 2);

  }, [isMobile, fraction, vwidth]);

  const nextStep = () => {
    setStep((step) => step + 1);
  }

  const prevStep = () => {
    setStep(step + (steps - 1))
  }

  return(
      <Container className={`${className}`} style={style} bg="#0C053F">
        <Header color="#0C053F">
          <h2>Conheça nossos parceiros:</h2>
        </Header>
        <Body bg="#F7F7F7">
          <Slider>
            <LogosContainer initial={{ opacity: 0, x: 50 }}
                            animate={{ opacity: 1, x: (vwidth * -(step % steps) -4) }}
                            transition={{
                              duration: 0.3,
                              delay: 0.1,
                              ease: 'easeOut',
                            }}>
              <ImageContainer largura={fraction} className="xp" />
              <ImageContainer largura={fraction} className="btg" />
              <ImageContainer largura={fraction} className="orama" />
              <ImageContainer largura={fraction} className="sicredi" />
              <ImageContainer largura={fraction} className="teodoro" />
              <ImageContainer largura={fraction} className="rbr" />
              <ImageContainer largura={fraction} className="avenue" />
              <ImageContainer largura={fraction} className="shield" />
              <ImageContainer largura={fraction} className="ativore" />
              <ImageContainer largura={fraction} className="bptax" />
              <ImageContainer largura={fraction} className="highpar" />
              <ImageContainer largura={fraction} className="oliveira" />
              <BigImageContainer largura={vwidth} />
            </LogosContainer>
          </Slider>
          <BulletsContainer>
            <FaChevronRight className="left" onClick={prevStep}/>
            <Bullet className={(step % steps) === 0 ? 'active' : ''} onClick={() => setStep(0)} />
            <Bullet className={(step % steps) === 1 ? 'active' : ''} onClick={() => setStep(1)} />
            <Bullet className={(step % steps) === 2 ? 'active' : ''} onClick={() => setStep(2)} />
            <Bullet className={(step % steps) === 3 ? 'active' : ''} onClick={() => setStep(3)} />
            <Bullet className={(step % steps) === 4 ? 'active' : ''} onClick={() => setStep(4)} />
            <Bullet className={(step % steps) === 5 ? 'active' : ''} onClick={() => setStep(5)} />
            <Bullet className={(step % steps) === 6 ? 'active' : ''} onClick={() => setStep(6)} />
            <FaChevronRight className="right" onClick={nextStep} />
          </BulletsContainer>
        </Body>
      </Container>
    )
};

ParceirosComponent.propTypes = {
  className: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.shape]),
};

ParceirosComponent.defaultProps = {
  className: '',
  style: {},
};

export default ParceirosComponent;
