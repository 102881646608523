import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { HiOutlineArrowRight } from 'react-icons/hi';
import { useResize } from '../../hooks';
import { getImage } from '../../utils/index';
import {
  CardWrapper,
  Title,
  Description,
  Background,
  FrontCard,
  HoverCard,
  HoverTitle,
  SaibaMais,
  Icone,
} from './index.styled';

const CardCircleComponent = ({
  handleClick,
  size,
  style,
  image,
  title,
  description,
  descriptionHover,
}) => {
  const [showHover, setShowHover] = useState(false);
  const { width, isTablet, isMobile } = useResize();

  const isNotDesktop = () => isMobile || isTablet || width < 992;

  const togleVisibility = () => {
    if (isNotDesktop()) {
      setShowHover(prevState => !prevState);
    }
  };

  const updateOnMouse = updateTo => {
    if (!isNotDesktop()) setShowHover(updateTo);
  };

  return (
    <CardWrapper
      size={size}
      onMouseEnter={() => updateOnMouse(true)}
      onMouseLeave={() => updateOnMouse(false)}
      onClick={togleVisibility}
      style={style}
      className={showHover ? 'hovered' : ''}
    >
      <FrontCard size={size}>
        <Title dangerouslySetInnerHTML={{ __html: title }} />
        <Description dangerouslySetInnerHTML={{ __html: description }} />
        <Background className="background" size={size} url={getImage(image)} />
      </FrontCard>
      <HoverCard
        size={size}
        className={showHover ? 'hover-card hovered' : 'hover-card'}
      >
        <HoverTitle
          className="h-title"
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <Description
          className="h-description"
          dangerouslySetInnerHTML={{ __html: descriptionHover }}
        />
        <SaibaMais onClick={handleClick} className="h-button">
          <span>Saiba mais</span>
          <Icone color="#FFFFFF">
            {React.createElement(HiOutlineArrowRight)}
          </Icone>
        </SaibaMais>
      </HoverCard>
    </CardWrapper>
  );
};

CardCircleComponent.propTypes = {
  handleClick: PropTypes.func,
  size: PropTypes.string,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  descriptionHover: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.shape]),
};

CardCircleComponent.defaultProps = {
  handleClick: () => {},
  size: '270',
  style: {},
};

export default CardCircleComponent;
