import styled from 'styled-components';
import { motion } from 'framer-motion';

export const CardWrapper = styled(motion.li)`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 270px;
  min-width: 270px;
  max-width: 270px;
  border-radius: 16px;
  height: 270px;
  max-height: 270px;
  transition: 0.3s ease all;
  transform: scale(0.94);
  padding: 30px;
  background: #5960ff;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.16);
  border-radius: 24px;

  &:hover {
    div {
      opacity: 1;
    }
  }
`;

export const Title = styled.h3`
  font-size: 48px;
  color: #ffffff;
  font-weight: bold;
`;

export const Text = styled.p`
  font-size: 24px;
  color: #ffffff;
`;
