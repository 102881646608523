/* eslint-disable */
import React, { useEffect, useRef } from 'react';
import { AnimatePresence } from 'framer-motion';
import { Modal, ModalWrapper } from './index.styled';

const ModalComponent = ({ toggleModal, isOpen, children, isClickOutside }) => {
    const modalRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (isClickOutside && modalRef.current && !modalRef.current.contains(event.target) && isOpen) {
                toggleModal();
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [modalRef, isClickOutside, toggleModal])

    return (
      <AnimatePresence>
        {isOpen &&
          <Modal initial={{ opacity: 0 }}
                 animate={{ opacity: 1 }}
                 exit={{ opacity: 0 }}
                 transition={{
                  duration: 0.25
                }}>
              <ModalWrapper ref={modalRef}
                initial={{ scale: 0.5,  y: '-50%' }}
                animate={{ scale: 1, y: '-50%' }}
                exit={{ scale: 0.5, y: '-50%' }}
                transition={{
                duration: 0.05
              }}
                >
                  {children}
              </ModalWrapper>
          </Modal>
        }
      </AnimatePresence>
    )
}

export default ModalComponent;