/* eslint-disable */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { BsChevronRight } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import data from '../../mock-data/quiz.json'
import { Container,
        Header,
        Body,
        FirstStep,
        ChooseContainer,
        Result,
        SaibaMais,
        Icone
       } from './index.styled';

const QuizComponent = ({ style, className }) => {

  const settingsSaibaMais = [
    {
      link: "/para-voce/grow",
      bulletColor: "#889cff"
    },
    {
      link: "/para-voce/wealth",
      bulletColor: "#8c8b8c"
    },
    {
      link: "/para-voce/family-office",
      bulletColor: "#b19b52"
    },
    {
      link: "/para-empresas",
      bulletColor: "#6c6c6c"
    },
  ]

  const [ step, setStep ] = useState(0);
  const [ score, setScore ] = useState(0);
  const navigate = useNavigate();
  const openLink = link => navigate(`${link}`);

  const getHeaderStep = () => {
    if(step === 0) {
      return (
        <span>Responda a essas perguntas<br />para que possamos te ajudar!</span>
      )
    }

    if(step === 1) {
      return (
        <span>Responda a essas perguntas<br />para que possamos te ajudar!</span>
      )
    }

    if(step === 2) {
      return (
        <span>Responda a essas perguntas<br />para que possamos te ajudar!</span>
      )
    }

    if(step === "work") {
      return (
        <span className="work">Nós te indicamos o <b>Yuno Work</b></span>
      )
    }

    if(step === "grow") {
      return (
        <span className="grow">Nós te indicamos o <b>Yuno Grow</b></span>
      )
    }

    if(step === "wealth") {
      return (
        <span className="wealth">Nós te indicamos o <b>Yuno Wealth</b></span>
      )
    }

    if(step === "family-office") {
      return (
        <span className="family-office">Nós te indicamos o <b>Yuno Family Office</b></span>
      )
    }

    return '';
  }

  const gotoStepWork = () => {
    setStep("work");
  }

  const getBgColor = () => {
    if(step === 0) return '#0C053F';
    if(step === 1) return '#0C053F';
    if(step === 2) return '#0C053F';
    if(step === "work") return '#000000';
    if(step === "grow") return '#140361';
    if(step === "wealth") return '#0C053F';
    if(step === "family-office") return '#060328';

    return '#FFFFFF';
  }

  const getBgBodyColor = () => {
    if(step === 0) return '#FFFFFF';
    if(step === 1) return '#FFFFFF';
    if(step === 2) return '#FFFFFF';
    if(step === "work") return '#000000';
    if(step === "grow") return '#140361';
    if(step === "wealth") return '#0C053F';
    if(step === "family-office") return '#060328';

    return 'transparent';
  }

  const nextStep = (scoreChoosen) => {
    setScore((score) => score + scoreChoosen);

    if(step === 1) {
      setStep(2);
    } else if(step === 2) {
      const total = score + scoreChoosen;
      if(total <= 6) {
        setStep("grow");
      } else if(total > 6 && total <= 12) {
        setStep("wealth");
      } else if(total > 12) {
        setStep("family-office");
      }
    }
  }

  const getQuestions = () => {
    const questions = data.filter(q => q.step === step).map(question => {
      return (
        <p key={question.id}
           dangerouslySetInnerHTML={{ __html: question.title }}
           onClick={() => nextStep(question.score)}></p>
      )
    })
    return questions;
  }

  const getBodyStep = () => {
    if(step === 0) {
      return (
        <FirstStep>
          <h3>Você é uma <b>pessoa jurídica</b><br />ou <b>pessoa física?</b></h3>
          <ChooseContainer>
            <div className="pessoa-fisica" onClick={() => setStep(1)}>
              <span>Sou uma <br />pessoa</span>
            </div>
            <div className="pessoa-juridica" onClick={gotoStepWork}>
              <span>Sou uma <br />empresa</span>
            </div>
          </ChooseContainer>
        </FirstStep>
      )
    }

    if(step === 1) {
      return (
        <FirstStep>
          <h3><b>Qual das afirmativas abaixo mais se assemelha com sua realidade financeira atual?</b></h3>
          <div className="questions">
            {getQuestions()}
          </div>
        </FirstStep>
      )
    }

    if(step === 2) {
      return (
        <FirstStep>
          <h3><b>Qual o valor do seu patrimônio?</b></h3>
          <div className="questions">
            {getQuestions()}
          </div>
        </FirstStep>
      )
    }

    if(step === "work") {
      return (
        <Result bg={getBgColor()}>
          <div className="work" />
          <p>Para empresários que querem ter às  suas mãos planejamento e ferramentas estratégicas de gestão.</p>
          <SaibaMais onClick={() => openLink(settingsSaibaMais[3].link)} className="h-button">
            <span>Saiba mais</span>
            <Icone color="#FFFFFF" bulletColor={settingsSaibaMais[3].bulletColor}>
              {React.createElement(BsChevronRight)}
            </Icone>
          </SaibaMais>
        </Result>
      )
    }

    if(step === "grow") {
      return (
        <Result bg={getBgColor()}>
          <div className="grow" />
          <p>Feito para quem quer formar patrimônio e, para isso, busca estratégias eficientes de gestão orçamentária.</p>
          <SaibaMais onClick={() => openLink(settingsSaibaMais[0].link)} className="h-button">
            <span>Saiba mais</span>
            <Icone color="#FFFFFF" bulletColor={settingsSaibaMais[0].bulletColor}>
              {React.createElement(BsChevronRight)}
            </Icone>
          </SaibaMais>
        </Result>
      )
    }

    if(step === "wealth") {
      return (
        <Result bg={getBgColor()}>
          <div className="wealth" />
          <p className="mb-0">Feito para pessoas e famílias que buscam expandir e proteger seus recursos por meio de gestão patrimonial estratégica.</p>
          <SaibaMais onClick={() => openLink(settingsSaibaMais[1].link)} className="h-button">
            <span>Saiba mais</span>
            <Icone color="#FFFFFF" bulletColor={settingsSaibaMais[1].bulletColor}>
              {React.createElement(BsChevronRight)}
            </Icone>
          </SaibaMais>
        </Result>
      )
    }

    if(step === "family-office") {
      return (
        <Result bg={getBgColor()}>
          <div className="family-office" />
          <p>Feito para famílias com patrimônio consolidado, que buscam proteger e usufruir de seus bens.</p>
          <SaibaMais onClick={() => openLink(settingsSaibaMais[2].link)} className="h-button">
            <span>Saiba mais</span>
            <Icone color="#FFFFFF" bulletColor={settingsSaibaMais[2].bulletColor}>
              {React.createElement(BsChevronRight)}
            </Icone>
          </SaibaMais>
        </Result>
      )
    }

    return '';
  }

  return(
      <Container className={`${className}`} style={style} bg={getBgColor()}>
        <Header color={getBgColor()}>
          <h2>Qual a melhor solução para o meu contexto?</h2>
          {getHeaderStep()}
        </Header>
        <Body bg={getBgBodyColor()}>
          {getBodyStep()}
        </Body>
      </Container>
    )
};

QuizComponent.propTypes = {
  className: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.shape]),
};

QuizComponent.defaultProps = {
  className: '',
  style: {},
};

export default QuizComponent;
