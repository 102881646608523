import styled from 'styled-components';
import { motion } from 'framer-motion';

export const CardWrapper = styled(motion.li)`
  position: relative;
  width: 220px;
  min-height: 134px;
  transition: 0.3s ease all;
`;

export const Order = styled.h3`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  font-size: 176px;
  color: #7386ff;
  opacity: 0.12;
  font-weight: 700;
  margin: 0 !important;
  padding: 0 !important;
`;

export const Text = styled.p`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align: left;
  width: 154px;
  left: ${p => p.distance};
  font-size: 20px;
  font-weight: 600;
  color: #5566d6;
  margin: 0 !important;
  padding: 0 !important;
  z-index: 1;
`;
