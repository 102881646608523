import styled from 'styled-components';

const Colors = {
  white: 'rgb(255, 255, 255)',
  gray0: 'rgb(220, 220, 220)',
  gray100: 'rgb(170, 170, 170)',
  gray200: 'rgb(95, 95, 95)',
  gray300: 'rgb(56, 56, 56)',
  gray400: 'rgb(0, 0, 0)',
};

export const Container = styled.div`
  width: 50%;
  height: auto;

  &.pointer {
    cursor: pointer;
  }

  &.bigger {
    width: 110px;
    height: 70px;

    @media (min-width: 768px) {
      width: 110px;
      height: 70px;
    }
  }
`;

export const Mandala = styled.svg`
  width: '100%';
  height: 'auto';
`;

export const PathText = styled.path`
  pointer-events: none;
  transition: all 0.3s ease;
  fill: ${p => p.fillColor};
`;

export const Path = styled.path`
  fill: ${p => Colors[p.fillColor]};
  cursor: pointer;
  opacity: 1;
  transition: all 0.3s ease;

  &:hover {
    fill: ${p => p.activeColor};
    &:not(.active) {
      opacity: 0.8;
    }
  }

  &.active {
    fill: ${p => p.activeColor};
  }
`;
