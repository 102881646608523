import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as Icons from 'react-icons/bs';
import { ButtonWrapper, Icone } from './index.styled';

const ButtonColorIconComponent = ({
  handleClick,
  size,
  children,
  bgcolors,
  colors,
  icon,
  style,
}) => {
  const [act, setAct] = useState(null);
  useEffect(() => {
    if (icon.length > 0) setAct(React.createElement(Icons[icon]));
  }, [icon]);
  return (
    <ButtonWrapper
      bgcolors={bgcolors}
      colors={colors}
      size={size}
      onClick={handleClick}
      padding={icon}
      style={style}
    >
      {children}
      {act && <Icone color={colors[0]}>{act}</Icone>}
    </ButtonWrapper>
  );
};

ButtonColorIconComponent.propTypes = {
  handleClick: PropTypes.func,
  size: PropTypes.string,
  bgcolors: PropTypes.arrayOf(PropTypes.string),
  colors: PropTypes.arrayOf(PropTypes.string),
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.shape]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

ButtonColorIconComponent.defaultProps = {
  handleClick: () => {},
  size: '20px',
  bgcolors: ['#F2F1FF', '#4739EB', '#1C11A7'],
  colors: ['#4739EB', '#FFFFFF', '#FFFFFF'],
  icon: '',
  style: {},
};

export default ButtonColorIconComponent;
