/* eslint-disable */
import styled from 'styled-components';
import { motion } from 'framer-motion'
import { ParceiroAtivore,
        ParceiroAvenue,
        ParceiroBrTax,
        ParceiroBTG,
        ParceiroHighpar,
        ParceiroOliveira,
        ParceiroOrama,
        ParceiroRbr,
        ParceiroSantanna,
        ParceiroShield,
        ParceiroSicred,
        ParceiroTeodoro,
        ParceiroXp, } from '../../assets';

export const Container = styled.div`
  width: 420px;
  background: ${p => p.bg};

  @media(max-width: 767px) {
    /* width: calc(100% - 20px); */
    width: 100%;
  }
`;

export const Header = styled.div`
  margin-top: -1px;
  display: flex;
  padding: 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  background: ${p => p.color};
  transition: all 0.3s ease;

  h2 {
    color: #ffffff;
    text-align: center;
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    margin: 0;
    padding: 0;

    @media(max-width: 767px) {
      font-size: 22px;
    }
  }
`;

export const Body = styled.div`
  position: relative;
  height: auto;
  min-height: 195px;
  padding: 30px 4px;
  background: ${p => p.bg};
  color: #282728;
`;

export const Slider = styled.div`
  position: relative;
  width: calc(100vw + 20px);
  height: 102px;
  overflow: hidden;

  @media (min-width: 768px) {
    width: 100%;
  }
`

export const LogosContainer = styled(motion.div)`
  display: flex;
  flex-wrap: nowrap;
  width: auto;
  height: 102px;
`
export const ImageContainer = styled.div`
  width: ${p => p.largura}px;
  min-width: ${p => p.largura}px;
  height: 100px;
  padding: 4px;

  &.xp {
    background: url(${ParceiroXp});
    background-repeat: no-repeat;
    background-size: 163px 37px;
    background-position: center;
  }

  &.btg {
    background: url(${ParceiroBTG});
    background-repeat: no-repeat;
    background-size: 163px auto;
    background-position: center;
  }

  &.orama {
    background: url(${ParceiroOrama});
    background-repeat: no-repeat;
    background-size: 163px auto;
    background-position: center;
  }

  &.sicredi {
    background: url(${ParceiroSicred});
    background-repeat: no-repeat;
    background-size: 163px auto;
    background-position: center;
  }

  &.teodoro {
    background: url(${ParceiroTeodoro});
    background-repeat: no-repeat;
    background-size: 163px auto;
    background-position: center;
  }

  &.rbr {
    background: url(${ParceiroRbr});
    background-repeat: no-repeat;
    background-size: 163px auto;
    background-position: center;
  }

  &.avenue {
    background: url(${ParceiroAvenue});
    background-repeat: no-repeat;
    background-size: 163px auto;
    background-position: center;
  }

  &.shield {
    background: url(${ParceiroShield});
    background-repeat: no-repeat;
    background-size: 163px auto;
    background-position: center;
  }

  &.ativore {
    background: url(${ParceiroAtivore});
    background-repeat: no-repeat;
    background-size: 163px 48px;
    background-position: center;
  }

  &.bptax {
    background: url(${ParceiroBrTax});
    background-repeat: no-repeat;
    background-size: 163px auto;
    background-position: center;
  }

  &.highpar {
    background: url(${ParceiroHighpar});
    background-repeat: no-repeat;
    background-size: 163px auto;
    background-position: center;
  }

  &.oliveira {
    background: url(${ParceiroOliveira});
    background-repeat: no-repeat;
    background-size: 163px auto;
    background-position: center;
  }
`
export const BigImageContainer = styled.div`
  width: 410px;
  min-width: 410px;
  height: 100px;
  background: url(${ParceiroSantanna});
  background-repeat: no-repeat;
  background-size: 300px 44px;
  background-position: center;

  @media (max-width: 767px) {
    width: ${p => p.largura}px;
    min-width: ${p => p.largura}px;
  }
`

export const BulletsContainer = styled.div`
  position: absolute;
  left: 0;
  bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  height: 50px;

  svg {
    transform: scale(1.1);
    cursor: pointer;
    color: #0C053F;
    margin: 0 20px;
    transition: all .3s ease;

    &.left {
      transform: scale(-1.1);
    }

    &:hover {
      color: #210b9d;
    }
  }
`

export const Bullet = styled.span`
  width: 10px;
  height: 10px;
  background: #E2E2E2;
  border-radius: 7px;
  cursor: pointer;
  transform: scale(1);
  transition: all 0.3s ease;

  &.active {
    transform: scale(1.2);
    background: #0C053F;
  }

  &:hover:not(.active) {
    transform: scale(1.1);
    background: #0C053F;
  }
`


