import styled from 'styled-components';
import { motion } from 'framer-motion';

export const CardWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  width: 270px;
  min-width: 270px;
  max-width: 270px;
  border-radius: 16px;
  height: auto;
  transition: 0.3s ease all;
  padding: 14px;
  background: #f5f5f5;
  box-shadow: 0px 6px 32px rgba(255, 255, 255, 0.48);
  border-radius: 16px;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    position: absolute;
    margin-bottom: 0px;
  }

  .arrow-down {
    display: none;
    position: absolute;
    width: 0;
    height: 0;
    border-left: 14px solid transparent;
    border-right: 14px solid transparent;
    left: 40px;
    bottom: -19px;
    border-top: 20px solid #f5f5f5;

    @media (min-width: 1045px) {
      display: block;
    }
  }

  .arrow-right {
    display: none;
    position: absolute;
    right: -19px;
    top: 30px;
    width: 0;
    height: 0;
    border-top: 14px solid transparent;
    border-bottom: 14px solid transparent;
    border-left: 20px solid #f5f5f5;

    @media (min-width: 1045px) {
      display: block;
    }
  }

  .arrow-left {
    display: none;
    position: absolute;
    left: -19px;
    top: 70%;
    width: 0;
    height: 0;
    border-top: 14px solid transparent;
    border-bottom: 14px solid transparent;
    border-right: 20px solid #f5f5f5;

    @media (min-width: 1045px) {
      display: block;
    }
  }

  .arrow-up {
    display: none;
    position: absolute;
    right: 80px;
    top: -19px;
    width: 0;
    height: 0;
    border-left: 14px solid transparent;
    border-right: 14px solid transparent;
    border-bottom: 20px solid #f5f5f5;

    @media (min-width: 1045px) {
      display: block;
    }
  }

  @media (min-width: 768px) {
    position: absolute;
    width: 290px;
    min-width: 290px;
    max-width: 290px;
    left: ${p => p.left}%;
    top: ${p => p.top}%;
  }
`;

export const Title = styled.h3`
  font-size: 16px;
  color: #212121;
  font-weight: 700;
`;

export const Text = styled.p`
  font-size: 16px;
  color: #212121;
  font-weight: 400;
`;
