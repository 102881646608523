import React from 'react';
import PropTypes from 'prop-types';
import { AiFillStar } from 'react-icons/ai';

import {
  CardWrapper,
  Background,
  Header,
  Image,
  ContentInfo,
  Nome,
  Data,
  StarsContainer,
  ContentDescricao,
  Text,
} from './index.styled';

const CardFieldComponent = ({ size, style, data, className }) => {
  let firstClick = 0;
  let posMouse = 0;

  const total = Math.ceil(Number(data?.estrelas));

  const onClick = e => {
    // Se moveu o mouse mais de 5px retorna
    const mouseMoved =
      posMouse - e.clientX < 0
        ? (posMouse - e.clientX) * -1
        : posMouse - e.clientX;
    if (mouseMoved > 5) return;

    // Se o click demorou mais de 300 segundos retorna
    if (Date.now() - firstClick > 300) {
      // console.log('barrado pelo return', Date.now() - firstClick);
    }
  };

  const setDown = e => {
    firstClick = Date.now();
    posMouse = e.clientX;
  };

  const renderStarts = () => {
    const stars = Array.from(Array(5).keys());
    const elements = stars.map((star, index) => (
      <AiFillStar
        size="20"
        key={`start-${star}`}
        color={index < total ? '#F3BB1C' : '#707070'}
      />
    ));

    return elements;
  };

  return (
    <CardWrapper
      className={`glide__slide ${className}`}
      size={size}
      onMouseUp={onClick}
      onMouseDown={setDown}
      style={style}
    >
      <Header>
        <Image src={data.image} />
        <ContentInfo>
          <Nome>{data.name}</Nome>
          <Data>{data.tempo}</Data>
          <StarsContainer>{renderStarts()}</StarsContainer>
        </ContentInfo>
      </Header>
      <ContentDescricao>
        <Text>{data.descricao}</Text>
      </ContentDescricao>
      <Background size={size} />
    </CardWrapper>
  );
};

CardFieldComponent.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.shape]),
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.shape]),
};

CardFieldComponent.defaultProps = {
  size: '270',
  className: '',
  data: {},
  style: {},
};

export default CardFieldComponent;
