import styled from 'styled-components';
import { motion } from 'framer-motion';
import { GrGraficoSeuDinheiro, BgSeuDinheiro } from '../../assets';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${p => (p.column ? 'column' : 'row')};
  align-items: flex-start;
  justify-content: ${p => (p.justifyCenter ? 'center' : 'space-between')};
  width: 100%;
  max-width: 1340px;
  margin: ${p => (p.margin ? p.margin : '0 auto')};
  min-height: 420px;

  @media (min-width: 768px) {
    align-items: center;
    height: auto;
  }

  @media (min-width: 992px) {
    height: auto;
    /* border: solid 1px red; */
  }

  @media (min-width: 1200px) {
    height: auto;
    /* border: solid 1px orange; */
  }

  @media (min-width: 1400px) {
    height: auto;
    /* border: solid 1px green; */
  }
`;

export const Grafico = styled(motion.div)`
  position: absolute;
  top: -115px;
  left: 0;
  width: 100%;
  height: 163px;
  background: url(${GrGraficoSeuDinheiro});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 0;

  @media (min-width: 768px) {
    top: -264px;
    width: 100%;
    height: 265px;
    background-size: 100% 100%;
  }
`;

export const Group = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: calc(100% - 40px);
  padding-top: 0;

  @media (min-width: 768px) and (max-width: 1044px) {
    width: 100%;

    > div {
      position: relative !important;
      left: 0;
      top: 0;
      margin: 20px 0;
    }
  }

  @media (min-width: 1045px) {
    width: 100%;
  }
`;

export const Title = styled(motion.h2)`
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
  font-weight: 700;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 30px;
  max-width: calc(100vw - 20px);
  z-index: 1;

  @media (min-width: 768px) {
    font-size: 30px;
    line-height: 47px;
    margin-top: 0;
    margin-bottom: 20px;
  }

  @media (min-width: 992px) {
    font-size: 48px;
    line-height: 60px;
  }
`;

export const Section = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: inherit;
  padding-top: 0;
  height: auto;
  padding: 0 20px;
  /* border-bottom: solid 1px red; */

  background: url(${BgSeuDinheiro}), #ffffff;
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center top;
  padding-bottom: 150px;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    height: 1300px;
    padding: 0 40px;
    background-size: auto 1300px;
    background-position: center 0px;
  }

  @media (min-width: 1045px) {
    height: 920px;
    background-size: 100% 920px;
    padding: var(--border-distance);
  }
`;
