import styled from 'styled-components';

export const theme = {
  default: {
    background: '#0c053f',
    color: '#ffffff',
  },
  lightBlue: {
    background: '#140361',
    color: '#ffffff',
  },
  darkBlue: {
    background: '#060328',
    color: '#ffffff',
  },
  light: {
    background: '#ffffff',
    color: '#0C053F',
  },
  lightBlue2: {
    background: '#ffffff',
    color: '#140361',
  },
  whiteBlack: {
    background: '#ffffff',
    color: '#000000',
  },
  blackWhite: {
    background: '#000000',
    color: '#ffffff',
  },
};

export const Colors = {
  skyBlue: '#7386ff',
};

export const ContainerFluid = styled.div`
  width: 100%;
  background: var(--color-white);

  &.vh100 {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 100vh;
  }
`;

export const Container = styled.div`
  max-width: 1340px;
  margin: 0 auto;
`;
