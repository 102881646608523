import React from 'react';
import PropTypes from 'prop-types';
import { getImage } from '../../utils/index';
import { CardWrapper, Background } from './index.styled';

const CardCarouselCursosComponent = ({
  handleClick,
  size,
  style,
  image,
  className,
}) => {
  let firstClick = 0;
  let posMouse = 0;

  const onClick = e => {
    // Se moveu o mouse mais de 5px retorna
    const mouseMoved =
      posMouse - e.clientX < 0
        ? (posMouse - e.clientX) * -1
        : posMouse - e.clientX;
    if (mouseMoved > 5) return;

    // Se o click demorou mais de 300 segundos retorna
    if (Date.now() - firstClick > 300) {
      // console.log('barrado pelo return', Date.now() - firstClick);
      return;
    }

    handleClick();
  };

  const setDown = e => {
    firstClick = Date.now();
    posMouse = e.clientX;
  };

  return (
    <CardWrapper
      className={`glide__slide ${className}`}
      size={size}
      onMouseUp={onClick}
      onMouseDown={setDown}
      style={style}
    >
      <Background size={size} url={getImage(image)} />
    </CardWrapper>
  );
};

CardCarouselCursosComponent.propTypes = {
  handleClick: PropTypes.func,
  className: PropTypes.string,
  size: PropTypes.string,
  image: PropTypes.string.isRequired,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.shape]),
};

CardCarouselCursosComponent.defaultProps = {
  handleClick: () => {},
  size: '270',
  className: '',
  style: {},
};

export default CardCarouselCursosComponent;
