/* eslint-disable */
import React, { useState, useEffect } from "react";
import { ContainerFull,
          Container,
          Text,
          TextContainer,
          Title } from './index.styled';
import { AnimatePresence, motion } from "framer-motion";

const texts = ["Dinheiro", "Riqueza", "Liberdade", "Tempo"];
const textInterval = 4000;

const textVariants = {
  initial: { x: "50%", opacity: 0 },
  animate: { x: 0, opacity: 1., transition: { duration: 0.5, ease: "easeOut" } },
  exit: { x: "-50%", opacity: 0, transition: { duration: 0.3, ease: "easeIn" } }
};

const QualidadeDeVidaComponent = () => {

  const [currentTextIndex, setCurrentTextIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, textInterval);

    return () => clearInterval(interval);
  }, []);

  return (
    <ContainerFull className="qualidade-vida">
      <Container column>
            <TextContainer>
            <Title initial={{ opacity: 0, y: 10 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{
              duration: 0.5,
              delay: 0.3,
            }}>Qualidade de vida é</Title>
            <AnimatePresence exitBeforeEnter>
              <motion.div
                key={currentTextIndex}
                initial="initial"
                animate="animate"
                exit="exit"
                variants={textVariants}
              >
                <Text
                  initial={{ opacity: 0}}
                  whileInView={{ opacity: 1 }}
                  viewport={{ once: true }}
                  transition={{
                    duration: 0.5,
                    delay: 0.1,
                  }}
                >
                  {texts[currentTextIndex]}
                </Text>
              </motion.div>
            </AnimatePresence>
            </TextContainer>
      </Container>
    </ContainerFull>
  );
};

export default QualidadeDeVidaComponent;
