/* eslint-disable */
import React from 'react';
import { SplitMenu } from '..'
import PropTypes from 'prop-types';
import { ContainerFull,
          Container,
          TextContainer,
          Text,
          Section,
          SplitContainer,
          Title } from './index.styled';

const ContentTextMenuFamilyOfficeComponent = ({ style, className }) => {

  return (
      <ContainerFull style={style} className={className}>
        <Container>
          <TextContainer>
            <Title
              initial={{ opacity: 0, y: 10 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{
                duration: 0.5,
                delay: 0.3,
              }}>
                A profissionalização dos cuidados dos seus bens e da sua família através de um planejamento patrimonial e sucessório.
            </Title>
            <Text
              style={{marginTop: '1.5rem'}}
              initial={{ opacity: 0, y: 10 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{
                duration: 0.5,
                delay: 0.3,
              }}>
                <b>Extrema riqueza</b> e extrema responsabilidade andam de mãos dadas ao lidar com investimentos, impostos, questões jurídicas e empresariais.
            </Text>
            <Text
              style={{marginTop: '0.5rem'}}
              initial={{ opacity: 0, y: 10 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{
                duration: 0.5,
                delay: 0.3,
              }}>
                Portanto, para aumentar o seu patrimônio, gerenciar riscos e facilitar a sua vida, o <b>Yuno Family Office</b> se torna indispensável em todos os casos.
            </Text>
            <Text
              style={{marginTop: '0.5rem'}}
              initial={{ opacity: 0, y: 10 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{
                duration: 0.5,
                delay: 0.3,
              }}>
                Através de um plano sucessório e tributário, você encontrará a melhor estrutura para cuidar do seu patrimônio.
            </Text>
            <Text
              style={{marginTop: '0.5rem'}}
              initial={{ opacity: 0, y: 10 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{
                duration: 0.5,
                delay: 0.3,
              }}>
                Seja ela holding, offshore, doação em vida, testamento, fundo exclusivo, seguro de vida ou previdência, a estratégia a ser implementada será pensada especialmente para garantir <b>harmonia familiar</b> e a <b>perpetuidade patrimonial.</b>
            </Text>
          </TextContainer>
          <Section initial={{ opacity: 0, y: 10 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{
                duration: 0.5,
                delay: 0.3,
              }}>
            <Title><span>Nossas</span> principais <br className="sm-only"/> entregas</Title>
            <Text className="mb-10">dentro das <b>6 áreas</b> do <br className="sm-only"/> <b>planejamento financeiro</b></Text>
            <SplitContainer>
              <SplitMenu type="family-office" />
            </SplitContainer>
          </Section>
        </Container>
      </ContainerFull>
  );
};

ContentTextMenuFamilyOfficeComponent.propTypes = {
  className: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.shape]),
};

ContentTextMenuFamilyOfficeComponent.defaultProps = {
  className: '',
  style: {},
};

export default ContentTextMenuFamilyOfficeComponent;
