import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${p => (p.column ? 'column' : 'row')};
  align-items: flex-start;
  justify-content: ${p => (p.justifyCenter ? 'center' : 'space-between')};
  width: 100%;
  max-width: 1340px;
  margin: ${p => (p.margin ? p.margin : '0 auto')};
  padding: 20px;
  min-height: 330px;

  @media (min-width: 768px) {
    min-height: 500px;
    align-items: center;
    height: auto;
  }

  @media (min-width: 992px) {
    height: auto;
    /* border: solid 1px red; */
  }

  @media (min-width: 1200px) {
    height: auto;
    /* border: solid 1px orange; */
  }

  @media (min-width: 1400px) {
    height: auto;
    /* border: solid 1px green; */
  }
`;

export const Title = styled(motion.h2)`
  font-size: 50px;
  line-height: 32px;
  color: #ffffff;
  font-weight: 700;
  margin-top: 0px;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    font-size: 96px;
    margin-top: 86px;
    margin-bottom: 58px;
  }
`;

export const Text = styled(motion.h3)`
  font-size: 18px;
  text-align: center;
  line-height: 30px;
  color: #ffffff;
  font-weight: 700;
  margin-bottom: 40px;

  @media (min-width: 768px) {
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 80px;
  }
`;

export const Gif = styled(motion.img)`
  width: 308px;
  height: auto;

  @media (min-width: 768px) {
    width: auto;
    height: 48px;
  }
`;

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: inherit;
  padding-top: 20px;
  height: auto;

  @media (min-width: 768px) {
    height: 566px;
    // height: 700px;
  }
`;
