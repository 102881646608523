/* eslint-disable */
import React, {useState} from 'react';
import { ContainerFull } from '../../assets/globalStyle';
import {
  QuemSomos2,
  QuemSomos3,
  QuemSomos4,
  QualidadeDeVida,
  DivisorButton,
  Modal,
  Quiz,
} from '../../components';


function SobreNosPage() {

  const [isModalOpen, setIsModalOpen] = useState(false);


  const toggleModal = () => {
      setIsModalOpen(!isModalOpen);
  }

  return (
    <React.Fragment>
      <Modal isOpen={isModalOpen} toggleModal={toggleModal} isClickOutside={true}>
         <Quiz />
      </Modal>
      <ContainerFull>
        <QualidadeDeVida />
      </ContainerFull>
      <ContainerFull>
        <QuemSomos2 />
      </ContainerFull>
      <ContainerFull>
        <QuemSomos3 />
      </ContainerFull>
      <ContainerFull>
        <QuemSomos4 />
      </ContainerFull>
      <ContainerFull>
        <DivisorButton
        handleClick={() => setIsModalOpen(true)}
        title="Descubra a melhor solução para o seu contexto!" themeType="default" />
      </ContainerFull>
    </React.Fragment>
  );
}

export default SobreNosPage;
