/* eslint-disable */
import styled from 'styled-components';
import GRLogoBranco from './assets/LogoYunoBranco.png';
import GRLogoGrow from './assets/logo-carousel-yuno-grow.png';
import GRLogoFamilyeOffice from './assets/logo-carousel-yuno-family-office.png';
import GRLogoWealth from './assets/logo-carousel-yuno-wealth.png';
import GRLogoWork from './assets/logo-carousel-yuno-work.png';
import { motion } from 'framer-motion';

export const Wrapper = styled.div`
  width: 850px;
  max-width: 100%;
  min-height: 400px;
  height: 100%;
  flex-shrink: 0;
  padding: 10px;

  @media (min-width: 850px) {
    width: 850px;
    min-width: 850px;
    padding: 0;
  }
`;

export const ContainerLeft = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  min-height: 400px;
  background: ${p => p.background};
  border-radius: 6px;
  box-shadow: 5px 7px 15px 0px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  transition: all 0.40s cubic-bezier(0.03, 0.55, 0.41, 0.96);

  @media (max-width: 767px) {
  }

  @media (min-width: 768px) {
    border-radius: 10px;
    width: 440px;

    &.active {
      width: 100%;
    }

    &:after {
      content: '';
      position: absolute;
      top: -2px;
      right: -2px;
      width: 32px;
      height: calc(100% + 4px);
      background: #F7F7F7;
      z-index: 0;
    }
  }
`;

export const Hamburguer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 40px;
  height: 36px;
  margin-top: 12px;

  span {
    width: 30px;
    height: 5px;
    border-radius: 5px;
    background: #F7F7F7;
    transform: translateX(0);
    transition: all 0.25s ease;

    &.active {
      transform: translateX(5px);
    }
  }

`;

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 40px 0px;
  width: 100%;
  align-items: flex-start;
  height: 100%;

  @media (max-width: 767px) {
    /* min-width: calc(100vw - 40px);
    max-width: calc(100vw - 40px); */
  }

  @media (min-width: 768px) {
    padding: 40px 0px;
    width: 410px;
    min-width: 410px;
  }
`;

export const Button = styled.div`
  position: relative;
  margin-top: 10px;
  display: flex;
  align-items: center;
  width: calc(100% - 15px);
  height: 68px;
  min-height: 68px;
  color: #F7F7F7;
  margin-left: 16px;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  /* transition: color 0.40s cubic-bezier(0.03, 0.55, 0.41, 0.96); */
  cursor: pointer;

  &.disabled {
    pointer-events: none;
    cursor: initial;
    opacity: 0.5;
  }

  span {
    font-size: 22px;
    line-height: 28px;
    font-weight: 500;
    color: #F7F7F7;
    /* transition: all 0.40s cubic-bezier(0.03, 0.55, 0.41, 0.96); */
  }

  div.icon-container {
    display: flex;
    justify-content: center;
    color: #F7F7F7;
    width: 90px;
    min-width: 90px;
  }

  @media (max-width: 767px) {
    span {
      font-size: 16px;
      line-height: 20px;
    }

    div.icon-container {
      display: flex;
      justify-content: center;
      color: #F7F7F7;
      width: 70px;
      min-width: 70px;
    }
  }

  &:after {
    content: "";
    opacity: 0;
    position: absolute;
    height: 10px;
    width: 20px;
    bottom: 0;
    top: -15px;
    right: -5px;
    border-radius: 0 0 0 10px;
    -moz-border-radius: 0 0 0 10px;
    -webkit-border-radius: 0 0 0 10px;
    -webkit-box-shadow: -10px 0 0 0 #f7f7f7;
    box-shadow: -10px 0 0 0 #f7f7f7;
    transform: rotate(-90deg);
    transition: color 0.40s cubic-bezier(0.03, 0.55, 0.41, 0.96);
    z-index: 0;
  }

  &:before {
    content: "";
    opacity: 0;
    position: absolute;
    height: 10px;
    width: 20px;
    bottom: -15px;
    right: -5px;
    border-radius: 0 0 10px 0;
    -moz-border-radius: 0 0 10px 0;
    -webkit-border-radius: 0 0 10px 0;
    -webkit-box-shadow: 10px 0 0 0 #f7f7f7;
    box-shadow: 10px 0 0 0 #f7f7f7;
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    transform: rotate(-90deg);
    /* transition: all 0.40s cubic-bezier(0.03, 0.55, 0.41, 0.96); */
    z-index: 0;
  }

  &.active {

    &:after {
      opacity: 1;
    }

    &:before {
      opacity: 1;
    }

    background: #f7f7f7;
    span {
      color: ${p => p.hovercolor};
    }

    div.icon-container {
      color: ${p => p.hovercolor};
    }
  }
`;

export const WhiteBar = styled.div`
  position: relative;
  flex: 0 100%;
  height:  ${p => p.altura}px;
  background: #F7F7F7;
  padding: 20px 0 20px 20px;
  overflow: hidden;
  z-index: 1;

  &.hidden {
    display: none;
  }
`;

export const SubmenuWrapper = styled.div`
  opacity: ${p => p.opacity};
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  top: ${p => p.posy}px;
  transition: opacity 0.3s ease;

  &.to-bottom {
    top: initial !important;
    bottom: 10px;
  }

  p {
    padding: 0;
    margin: 0;
  }
`

export const SubmenuMobileWrapper = styled(motion.div)`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  padding-left: 40px;
  gap: 8px;

  &.to-bottom {
    top: initial !important;
    bottom: 10px;
  }

  p {
    padding: 0;
    margin: 0;
  }
`

export const SubButton = styled.div`
  display: flex;
  width: 400px;
  min-width: 400px;
  padding: 15px 40px 15px 20px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 5px;
  background: var(--principais-do-site-branco, #FFF);
  box-shadow: 2px 2px 4px 0px rgba(0,0,0,0.25);

  @media(max-width: 767px) {
    width: 100%;
    min-width: 100%;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  span {
    font-size: 14px;
    font-weight: 500;
    color: #282728;

    &.subtitle {
      font-size: 12px;
      font-weight: 200;
      font-style: italic;

      &.no-italic {
        font-style: normal;
        font-weight: 500;
      }
    }
  }

  @media (min-width: 768px) {

    width: 400px;
    min-width: 400px;
    padding: 15px 40px 15px 20px;

    span {
    font-size: 14px;
    font-weight: 500;
    color: #282728;

    &.subtitle {
      font-size: 12px;
      font-weight: 200;
      font-style: italic;

      &.no-italic {
        font-style: normal;
        font-weight: 500;
      }
    }
  }
  }
`

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 70px;
  padding-left: 20px;
  padding-right: 20px;

  @media (min-width: 768px) {
    padding-left: 40px;
    padding-right: 24px;
  }
`;

export const WrapperContent = styled.div`
  @media (min-width: 768px) {
    max-width: 100%;

    .wrapper-title {
      max-width: 100%;
    }
  }
`;

export const LogoYuno = styled.div`
  width: 137px;
  height: 46px;
  background: url(${GRLogoBranco});
  background-repeat: no-repeat;
  background-size: cover;

  @media (min-width: 768px) {
    width: 137px;
    height: 46px;
  }
`;

export const LogoGrow = styled.div`
  width: 128px;
  height: 60px;
  background: url(${GRLogoGrow});
  background-repeat: no-repeat;
  background-size: cover;
`;

export const LogoFamilyeOffice = styled.div`
  width: 153px;
  height: 60px;
  background: url(${GRLogoFamilyeOffice});
  background-repeat: no-repeat;
  background-size: cover;
`;

export const LogoWealth = styled.div`
  width: 138px;
  height: 60px;
  background: url(${GRLogoWealth});
  background-repeat: no-repeat;
  background-size: cover;
`;

export const LogoWork = styled.div`
  width: 130px;
  height: 60px;
  background: url(${GRLogoWork});
  background-repeat: no-repeat;
  background-size: cover;
`;
