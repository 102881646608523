/* eslint-disable */
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { ImageParaEmpresa,
        ImageParaVoceGrow,
        ImageParaVoceWealth,
        ImageParaVoceFamilyOffice } from '../../assets';

const images = {
  ImageParaEmpresa,
  ImageParaVoceGrow,
  ImageParaVoceWealth,
  ImageParaVoceFamilyOffice
}

export const ContainerFull = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  margin-top: auto;
  background: #ffffff;


  @media (min-width: 768px) {
    height: 100%;
    min-height: 280px;
  }

  @media (min-width: 992px) {
    flex-direction: row;
    min-height: 400px;
  }

  @media (min-width: 1200px) {
    min-height: 380px;
  }

  @media (min-width: 1600px) {
    min-height: 450px;
  }
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 280px;
  margin: 0 auto;
  justify-content: flex-start;

  @media (min-width: 768px) {
    min-height: 280px;
  }

  @media (min-width: 992px) {
    flex-direction: row;
    min-height: 320px;
  }

  @media (min-width: 1200px) {
    min-height: 380px;
  }

  @media (min-width: 1600px) {
    min-height: 450px;
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 400px;
  background-image: url(${props => images[props.image]});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  order: 1;

  @media (max-width: 767px) {
    max-height: 300px;
    min-height: 300px;
  }

  @media (min-width: 768px) {
    height: 100%;
    min-height: 400px;
    order: 2;
  }

  @media (min-width: 992px) {
    width: 50%;
    flex-direction: row;
    min-height: 400px;
  }

  @media (min-width: 1200px) {
    min-height: 380px;
  }

  @media (min-width: 1600px) {
    min-height: 450px;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 200px;
  padding: 40px;
  border-top: solid 1px rgba(255,255,255,0.1);
  background: ${props => props.theme.type.background};
  order: 2;

  @media (min-width: 768px) {
    justify-content: center;
    width: 100%;
    padding-left: 40px;
    min-height: 300px;
    order: 1;
  }

  @media (min-width: 992px) {
    border-top: none;
    width: 50%;
    padding: 70px 40px;
    padding-left: 40px;
    min-height: 400px;

  }

  @media (min-width: 1200px) {
    min-height: 380px;
  }

  @media (min-width: 1600px) {
    min-height: 450px;
  }
`;

export const Title = styled(motion.h1)`
  font-size: 40px;
  line-height: 46px;
  margin-bottom: 30px;
  letter-spacing: 0;
  color: ${props => props.theme.type.color};
  font-weight: 600;
  @media (min-width: 1600px) {
    font-size: 50px;
    margin-bottom: 36px;
  }
`;

export const Text = styled(motion.p)`
  font-size: 22px;
  color: ${props => props.theme.type.color};
  font-weight: 200;

  @media (max-width: 767px) {
    font-size: 18px;
    height: 100%;
    width: 100%;
  }

  @media (min-width: 1600px) {
    width: 670px;
  }
`;

export const ButtonClose = styled.button`
  width: 30px;
  height: 30px;
  border: 0;
  outline: 0;
  cursor: pointer;
  display: flex;
  background: transparent;
  align-items: center;
  justify-content: center;
`;

export const Button = styled(motion.div)`
  border: solid 1px #ffffff;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  padding: 9px 10px 9px 20px;
  border-radius: 40px;
  color: #ffffff;
  cursor: pointer;
  background: rgba(255, 255, 255, 0);
  transition: all 0.2s ease;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }

  @media (min-width: 768px) {
    margin-top: 90px;
  }
`;

export const CardsContainer = styled(motion.div)`
  width: 100%;
  margin-top: 100px;
  display: flex;
  justify-content: space-between;
`;

export const Card = styled.div`
  display: flex;
  height: auto;
  padding: 20px 15px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  background: #130b4a;
  box-shadow: 3px 10px 20px 0px rgba(0, 0, 0, 0.5);

  h2 {
    width: auto;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    color: #ffffff;
    white-space: nowrap;
  }

  p {
    width: 260px;
    text-align: left;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: #f7f7f7;
  }
`;
