import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: ${p => `${p.size}px`};
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;

  &.active {
    &:after {
      opacity: 0.5;
    }

    img {
      object-position: center -34px;
      transform: scale(1.02);
    }
  }

  &:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    background-image: url(${p => p.bgImage});
    background-repeat: no-repeat;
    background-position: center;
    transition: 0.3s ease;
    opacity: 1;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
    transition: all 0.15s ease-out;
    transform: scale(1);
    transform-origin: center;
  }
`;
