import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${p => (p.column ? 'column' : 'row')};
  align-items: center;
  justify-content: ${p => (p.justifyCenter ? 'center' : 'space-between')};
  width: 100%;
  max-width: 1340px;
  margin: ${p => (p.margin ? p.margin : '0 auto')};
  padding: 20px;

  @media (min-width: 768px) {
    /* border: solid 1px yellow; */
    padding: var(--border-distance);
  }
`;

export const Section = styled.section`
  width: inherit;
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-flow: row wrap;
  height: auto;
  background: ${p => p.bgcolor};
`;

export const GalleryItem = styled.div`
  width: calc(100%);
  margin-bottom: 10px;

  @media screen and (min-width: 768px) {
    width: calc(100% / 2 - 20px);
  }

  @media screen and (min-width: 1200px) {
    width: calc(100% / 3 - 40px);

    &:not(:nth-child(3n + 1)) {
      //margin-left: 10px;
    }
    &:nth-child(3n + 1) {
      //margin-left: 10px;
    }
  }
`;
