import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled.div`
  position: relative;
  display: flex;
  background: transparent;
  flex-direction: ${p => (p.column ? 'column' : 'row')};
  align-items: flex-start;
  width: 100%;
  max-width: 1340px;
  margin: ${p => (p.margin ? p.margin : '0 auto')};

  @media (min-width: 768px) {
    align-items: center;
    height: auto;
  }

  @media (min-width: 992px) {
    height: auto;
    /* border: solid 1px red; */
  }

  @media (min-width: 1200px) {
    height: auto;
    /* border: solid 1px orange; */
  }

  @media (min-width: 1400px) {
    height: auto;
    /* border: solid 1px green; */
  }
`;

export const Title = styled(motion.h2)`
  font-size: 18px;
  line-height: 28px;
  color: #ffffff;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 16px;

  @media (min-width: 768px) {
    margin-top: 0;
    margin-bottom: 16px;
  }
`;

export const Text = styled(motion.h3)`
  margin: 40px 0 20px 0;
  font-size: 11px;
  line-height: 24px;
  color: #ffffff;
  font-weight: 400;
`;

export const List = styled(motion.ul)`
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-left: none;
  }
`;

export const Item = styled(motion.li)`
  flex: 0 0 100%;
  list-style: none;
  color: #ffffff;
  font-size: 11px;
  line-height: 24px;
  margin-bottom: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;

  &:hover {
    color: #ffffff;
  }

  @media (min-width: 768px) {
    flex: 0 0 50%;
    font-size: 14px;
    max-width: 220px;
    margin-bottom: 8px;
    margin-left: auto;
    max-width: initial;
  }

  @media (min-width: 1300px) {
    flex: 0 0 33%;
  }

  svg {
    margin-left: 0.5rem;
  }
`;

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  width: inherit;
  height: auto;
  padding-bottom: 32px;
`;
