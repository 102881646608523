import './App.css';
import { BrowserRouter } from 'react-router-dom';
import { GlobalStyle } from './assets/globalStyle';
import Switch from './routes';

function App() {
  return (
    <BrowserRouter>
      <GlobalStyle />
      <Switch />
    </BrowserRouter>
  );
}

export default App;
