/* eslint-disable */
import React from 'react';
import { Container, ContainerFull } from '../../assets/globalStyle';
import {
  HeaderSectionImageText,
  DivisorButton,
  ContentTextMenuWealth,
  TransformarVidaFinanceira
} from '../../components';


function ParaVoceWealthPage() {

  const gotoWhatsApp = (phoneNumber) => {
    const url = `https://wa.me/${phoneNumber}`;
    window.open(url, '_blank');
  }

  return (
    <React.Fragment>
      <ContainerFull>
        <HeaderSectionImageText
          themeType="default"
          image="ImageParaVoceWealth"
          title="Yuno Wealth"
          text="Para você que busca <b>expandir e proteger seus recursos</b> por meio de uma gestão patrimonial estratégica." />
      </ContainerFull>
      <ContainerFull>
        <ContentTextMenuWealth />
      </ContainerFull>
      <ContainerFull>
        <DivisorButton handleClick={() => gotoWhatsApp(5562993376742)} bordered title="Inicie agora seu Planejamento Financeiro" themeType="light" />
      </ContainerFull>
      <ContainerFull>
        <TransformarVidaFinanceira />
      </ContainerFull>
    </React.Fragment>
  );
}

export default ParaVoceWealthPage;
