import React from 'react';
import PropTypes from 'prop-types';
import { CardWrapper, Title, Text } from './index.styled';

const CardGrayComponent = ({ title, text, style, className, delay }) => {
  return (
    <CardWrapper
      initial={{ opacity: 0, y: 16 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{
        duration: 0.6,
        ease: 'easeOut',
        delay,
      }}
      className={`${className}`}
      style={style}
    >
      <Title>{title}</Title>
      <Text>{text}</Text>
    </CardWrapper>
  );
};

CardGrayComponent.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  delay: PropTypes.number,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.shape]),
};

CardGrayComponent.defaultProps = {
  title: '',
  text: '',
  delay: 0,
  className: '',
  style: {},
};

export default CardGrayComponent;
