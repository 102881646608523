import React from 'react';
import { Link } from 'react-router-dom';
import { Logo } from '../../components';
import LogoYoutube from './assets/$2am7c204wr8.png';
import LogoInstagram from './assets/$94upf20b9ho.png';
import {
  ContainerFull,
  Container,
  DivParagraph,
  ContentLeft,
  ContentRight,
  Button,
  Img,
} from './index.styled';

const FooterComponent = () => {
  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };
  const navigateToTop = e => {
    e.preventDefault();
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'auto',
    });
  };
  return (
    <ContainerFull>
      <Container className="column-sm">
        <ContentLeft>
          <Link to="/" onClick={navigateToTop}>
            <Logo className="bigger pointer" />
          </Link>
          <DivParagraph>
            <p>© 2022 | Todos os Direitos Reservados</p>
          </DivParagraph>
        </ContentLeft>
        <ContentRight>
          <Button
            onClick={() => openInNewTab('https://www.youtube.com/c/Yunofp')}
          >
            <Img src={LogoYoutube} alt="Visite nosso canal no Youtube!" />
          </Button>
          <Button
            onClick={() => openInNewTab('https://www.instagram.com/yuno.fp/')}
          >
            <Img src={LogoInstagram} alt="Visite nosso Instagram!" />
          </Button>
        </ContentRight>
      </Container>
    </ContainerFull>
  );
};

export default FooterComponent;
