/* eslint-disable */
import React from 'react';
import { ContainerFull,
          Container,
          ImageContainer,
          TextContainer,
          Text,
          Title } from './index.styled';

const QuemSomos4Component = () => {

  return (
    <ContainerFull>
      <Container>
        <TextContainer>
          <Title initial={{ opacity: 0, y: 10 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{
                duration: 0.5,
                delay: 0.3,
              }}>A <b>Yuno</b> veio com a missão de mudar essa realidade.</Title>
          <Text initial={{ opacity: 0, y: 10 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{
              duration: 0.5,
              delay: 0.3,
            }}>Acreditamos que cada pessoa tem uma razão para buscar crescimento e segurança. E por isso, nosso objetivo é <b>apoiar</b> cada cliente na busca por seu propósito. Não importando, se já acumulou uma grande quantia de patrimônio ou se está apenas iniciando essa jornada.</Text>
          <Text initial={{ opacity: 0, y: 10 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{
              duration: 0.5,
              delay: 0.3,
            }}>Nossa missão é garantir <b>qualidade de vida</b> no presente, e um caminho próspero em direção à sua <b>liberdade financeira.</b></Text>
        </TextContainer>
      </Container>
    </ContainerFull>
  );
};

export default QuemSomos4Component;
