import React from 'react';
import PropTypes from 'prop-types';
import { CloseIcon } from '../../assets';

import { ButtonClose } from './index.styled';

const ButtonCloseComponent = ({ handleClick, size }) => {
  return (
    <ButtonClose onClick={handleClick}>
      <img src={CloseIcon} alt="close" style={{ width: size, height: size }} />
    </ButtonClose>
  );
};

ButtonCloseComponent.propTypes = {
  handleClick: PropTypes.func,
  size: PropTypes.string,
};

ButtonCloseComponent.defaultProps = {
  handleClick: () => {},
  size: '20px',
};

export default ButtonCloseComponent;
