/* eslint-disable */
import styled from 'styled-components';
import { motion } from 'framer-motion';

export const ContainerFull = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  margin-top: auto;
  background: #ffffff;

  @media (min-width: 768px) {
    min-height: 450px;
  }
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  justify-content: flex-start;

  @media (min-width: 992px) {
    flex-direction: row;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  padding: 40px;

  /* @media (max-width: 767px) {
    br {
      display: none;
    }
  } */

  @media (min-width: 992px) {
    padding: 90px 40px 0 40px;
    width: 40%;
  }

  @media (min-width: 1200px) {
    width: 45%;
  }

  @media (min-width: 1400px) {
    width: 45%;
  }
  @media (min-width: 1740px) {
    width: 50%;
  }
`;

export const Section = styled(motion.section)`
  padding: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: auto;

  .sm-only {
      @media (min-width: 768px) {
      display: none;
    }
  }

  @media (max-width: 767px) {
    padding: 10px;
    border-top: solid 4px #140361;
    h1 {
      text-align: left;
      font-size: 32px;
      line-height: 40px;
      padding: 20px 10px 0 30px ;
    }

    p {
      padding-left: 30px;
    }
  }

  @media (min-width: 992px) {
    padding: 90px 0;
    width: 60%;
  }

  @media (min-width: 1200px) {
    width: 55%;
  }

  @media (min-width: 1400px) {
    width: 55%;
  }
  @media (min-width: 1740px) {
    width: 50%;

  }
`;

export const Title = styled(motion.h1)`
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 10px;
  letter-spacing: 0;
  color: #282728;
  font-weight: 700;
  width: 100%;
  text-align: justify;
  text-justify: inter-word;

  @media (min-width: 768px) {
    font-size: 22px;
  }

  @media (min-width: 992px) {
    font-size: 24px;
    line-height: 28px;
    max-width: 670px;
    /* border: solid 1px yellow; */
  }

  @media (min-width: 1200px) {
    font-size: 28px;
    line-height: 34px;
    max-width: 480px;
    /* border: solid 1px green; */
  }

  @media (min-width: 1400px) {
    font-size: 30px;
    line-height: 38px;
    max-width: 510px;
    /* border: solid 1px orange; */
  }
  @media (min-width: 1740px) {
    font-size: 36px;
    line-height: 42px;
    max-width: 600px;
    /* border: solid 1px red; */
  }

  span {
    font-weight: 400;
  }
`;

export const Text = styled(motion.p)`
  font-size: 16px;
  color: #282728;
  font-weight: 400;
  width: 100%;
  text-align: justify;

  &.mb-10 {
    margin-bottom: 10px;
  }

  @media (min-width: 768px) {
    font-size: 16px;
  }

  @media (min-width: 992px) {
    font-size: 14px;
    max-width: 480px;
    /* border: solid 1px yellow; */
  }

  @media (min-width: 1200px) {
    font-size: 16px;
    max-width: 480px;
    /* border: solid 1px green; */
  }

  @media (min-width: 1400px) {
    font-size: 17px;
    max-width: 510px;
    /* border: solid 1px orange; */
  }
  @media (min-width: 1740px) {
    font-size: 20px;
    max-width: 600px;
    /* border: solid 1px red; */
  }
`;

export const SplitContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  /* height: 740px; */
  height: auto;
  min-height: auto;
  transition: all 0.3s ease;
  transform-origin: top left;
  transform: scale(1);

  @media (max-width: 768px) {
    /* height: 780px; */
    transform: scale(1);
    margin-bottom: 0px;
  }
  @media (min-width: 768px) {
    transform: scale(0.82);
    /* height: 740px; */
    margin-bottom: -50px;
  }

  @media (min-width: 992px) {
    transform: scale(0.68);
    margin-bottom: -180px;
  }

  @media (min-width: 1200px) {
    transform: scale(0.76);
    margin-bottom: -200px;
  }

  @media (min-width: 1400px) {
    transform: scale(0.85);
    margin-bottom: -100px;
  }
  @media (min-width: 1740px) {
    transform: scale(1);
    margin-bottom: 0px;
  }
`;
