import styled, { createGlobalStyle } from 'styled-components';
import { rgba } from 'polished';
import { BGParaEducar, BGParaVoce, BGParaEmpresas } from './index';

export const GlobalStyle = createGlobalStyle`
  :root {
    --color-blue-1:#F2F1FF;
    --color-primary-dark:#1C11A7;
    --color-blue: #4739EB;
    --color-primary: #4739EB;
    --color-primary-medium: #5960FF;
    --color-secundary: #FF0C73;
    --color-secundary-medium: #FF538E;
    --color-secundary-opaque: ${rgba('#FF538E', 0.7)};
    --color-background-error: #F9DDDD;
    --color-feedback-error: #E05753;
    --color-gray: #616161;
    --color-gray-2: #757575;
    --color-white: #FFFFFF;
    --color-black: #000000;

    --border-distance: 0 85px;

    --scroll: smooth;
  }

  html,
  body {
      width: 100%!important;
      padding: 0!important;
      margin: 0!important;
      font-family: 'Poppins', sans-serif !important;
      font-size: 16px;
  }

  a {
    -webkit-tap-highlight-color: rgba(0,0,0,0) !important;
  }

  h2 {
    font-family: 'Poppins', sans-serif;
    font-size: 1.2rem;
    color: var(--color-gray);
    &.subtitle {
      margin: 0;
      margin-bottom: 12px;
      letter-spacing: 1.5px;
      text-transform: uppercase;

      @media (min-width: 768px) {
        margin-bottom: 20px;
      }

      &.pink {
        color: var(--color-secundary);
      }
    }
  }

  ul, li, a {
    list-style: none;
    margin: 0;
    padding: 0;
    text-decoration: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }

  p {
    margin: 0;
    padding: 0;
  }

  h1,
  h3,
  h4,
  h5,
  h6 {
      font-family: 'Poppins', sans-serif;
      font-weight: bold;
      color: #212121;
      margin: 0;

      &.color-light {
        color: #FFFFFF;
      }
  }

  h5 {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;

    @media (min-width: 768px) {
      font-size: 32px;
    }
  }

  * {
    box-sizing: border-box;
    scroll-behavior: var(--scroll);
    -webkit-font-smoothing: antialiased;
    font-family: 'Poppins', sans-serif !important;
    -webkit-tap-highlight-color: transparent !important;
  }

  ::-webkit-scrollbar {
      width: 10px;
  }

  ::-webkit-scrollbar-track {
      background: #FFF;
  }

  ::-webkit-scrollbar-thumb {
      -webkit-border-radius: 10px;
      border-radius: 10px;
      background: var(--color-primary);
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }

  .fluid {
    width: 100%;
    padding: 0 20px;

    &.bg-white {
      background: var(--color-white);
    }
  }

  .p-top {
    padding-top: 60px;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    width: 100%;
    max-width: 1340px;
    height: 100%;
  }

  .section-home {
    position: relative;
    height: 600px;
  }
  .d-none-sm {
    @media (max-width: 767px) {
      display: none;
    }
  }

  .d-none-md {
    @media (min-width: 768px) {
      display: none;
    }
  }
`;

export const ContainerFull = styled.div`
  position: relative;
  display: flex;
  width: 100%;
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${p => (p.column ? 'column' : 'row')};
  align-items: center;
  justify-content: ${p => (p.justifyCenter ? 'center' : 'space-between')};
  width: 100%;
  max-width: 1340px;
  margin: ${p => (p.margin ? p.margin : '0 auto')};
  padding: ${p => (p.distance ? 'var(--border-distance)' : '0')};
  height: 100%;

  &.para-educar {
    background: url(${BGParaEducar}), #ffffff;
    background-size: 700px auto;
    background-repeat: no-repeat;
    background-position: -100px 0;

    @media (min-width: 768px) {
      background-size: 1440px auto;
      background-position: center 0px;
    }
  }

  &.para-voce {
    background: url(${BGParaVoce}), #011837;
    background-size: auto 1100px;
    background-repeat: no-repeat;
    background-position: center 0;

    @media (min-width: 768px) {
      background-size: 1440px auto;
      background-position: center 0px;
    }
  }

  &.para-empresas {
    background: url(${BGParaEmpresas}), #011837;
    background-size: auto 740px;
    background-repeat: no-repeat;
    background-position: center -60px;

    @media (min-width: 768px) {
      background-size: 1440px auto;
      background-position: center -70px;
    }
  }
`;
