import React from 'react';
import PropTypes from 'prop-types';
import { ButtonWrapper } from './index.styled';

const ButtonMidiaSocialComponent = ({
  handleClick,
  size,
  children,
  bgcolors,
  colors,
  style,
}) => {
  return (
    <ButtonWrapper
      bgcolors={bgcolors}
      colors={colors}
      size={size}
      onClick={handleClick}
      style={style}
    >
      {children}
    </ButtonWrapper>
  );
};

ButtonMidiaSocialComponent.propTypes = {
  handleClick: PropTypes.func,
  size: PropTypes.string,
  bgcolors: PropTypes.arrayOf(PropTypes.string),
  colors: PropTypes.arrayOf(PropTypes.string),
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.shape]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

ButtonMidiaSocialComponent.defaultProps = {
  handleClick: () => {},
  size: '20px',
  bgcolors: ['#F2F1FF', '#4739EB', '#1C11A7'],
  colors: ['#4739EB', '#FFFFFF', '#FFFFFF'],
  style: {},
};

export default ButtonMidiaSocialComponent;
