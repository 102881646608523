/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { BsChevronRight } from 'react-icons/bs';
import { getImage } from '../../utils/index';
import {
  CardWrapper,
  Description,
  Background,
  FrontCard,
  HoverCard,
  SaibaMais,
  Icone,
} from './index.styled';

const CardSquaredComponent = ({
  handleClick,
  size,
  style,
  image,
  bulletColor,
  description,
}) => {

  return (
    <CardWrapper
      size={size}
      style={style}
    >
      <FrontCard size={size}>
        <Description dangerouslySetInnerHTML={{ __html: description }} />
        <Background className="background" size={size} url={getImage(image)} />
        <SaibaMais onClick={handleClick} className="h-button">
          <span>Saiba mais</span>
          <Icone color="#FFFFFF" bulletColor={bulletColor}>
            {React.createElement(BsChevronRight)}
          </Icone>
        </SaibaMais>
      </FrontCard>
    </CardWrapper>
  );
};

CardSquaredComponent.propTypes = {
  handleClick: PropTypes.func,
  size: PropTypes.string,
  bulletColor: PropTypes.string,
  image: PropTypes.string.isRequired,
  descriptionHover: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.shape]),
};

CardSquaredComponent.defaultProps = {
  handleClick: () => {},
  size: '280',
  bulletColor: "transparent",
  style: {},
};

export default CardSquaredComponent;
