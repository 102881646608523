import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './index.styled';

const DescriptionComponent = ({ color, children, style, type, fontNormal }) => {
  const getClassName = () => {
    switch (type) {
      case 'subtitle':
        return 'subtitle';
      case 'description':
        return 'description';
      default:
        return 'subtitle';
    }
  };

  return (
    <Container
      className={`${getClassName()} ${fontNormal === true ? 'normal' : ''}`}
      style={style}
      color={color}
    >
      <p>{children}</p>
    </Container>
  );
};

DescriptionComponent.propTypes = {
  color: PropTypes.string,
  type: PropTypes.oneOf(['subtitle', 'description']),
  fontNormal: PropTypes.bool,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf([null])]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

DescriptionComponent.defaultProps = {
  color: 'black',
  type: 'subtitle',
  fontNormal: false,
  style: {},
};

export default DescriptionComponent;
