import styled from 'styled-components';

export const ButtonWrapper = styled.button`
  position: relative;
  border: 0;
  border-radius: 6px;
  outline: 0;
  cursor: pointer;
  display: flex;
  font-weight: 700;
  font-size: 16px;
  background: ${c => c.bgcolors[0]};
  padding: ${p => (p.padding.length > 0 ? '12px 48px 12px 16px' : '12px 16px')};
  align-items: flex-start;
  color: ${c => c.colors[0]};
  transition: all 0.2s linear;

  a {
    color: ${c => c.colors[0]};

    &.bg-after {
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: transparent;
        z-index: 1;
      }
    }
  }

  &:hover {
    background: ${c => c.bgcolors[1]};
    color: ${c => c.colors[1]};

    a {
      color: ${c => c.colors[1]};
    }
  }

  &:active {
    background: ${c => c.bgcolors[2]};
    color: ${c => c.colors[2]};

    a {
      color: ${c => c.colors[2]};
    }
  }
`;

export const Icone = styled.span`
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 100%;
    height: auto;
    color: ${p => (p.color ? p.color : '#ffffff')}
    box-shadow: 0px 0px 17px -7px rgba(0, 0, 0, 0.75);
  }
`;
