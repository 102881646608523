export const getImage = img => {
  if (img.includes('http')) {
    return img;
  }
  // eslint-disable-next-line
  return require(`../assets/images/${img}`);
};

export const getDocument = doc => {
  if (doc.includes('http')) {
    return doc;
  }
  // eslint-disable-next-line
  return require(`../assets/documents/${doc}`);
};
