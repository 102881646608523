import styled from 'styled-components';
import { PathLinhas, PathSeta } from '../../assets';

export const Container = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 3;
  pointer-events: none;
`;

export const ImgPath = styled.div`
  position: absolute;
  width: 1131px;
  height: 1778px;
  background: url(${PathLinhas});
  background-position: 0 196px;
  background-repeat: no-repeat;
`;

export const Seta = styled.div`
  position: absolute;
  width: 58px;
  height: 58px;
  background: url(${PathSeta});
  background-repeat: no-repeat;
  z-index: 4;
`;
