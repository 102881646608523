/* eslint-disable */
import React from 'react';
import { SplitMenu } from '..'
import PropTypes from 'prop-types';
import { ContainerFull,
          Container,
          TextContainer,
          Text,
          Section,
          SplitContainer,
          Title } from './index.styled';

const ContentTextMenuWealthComponent = ({ style, className }) => {

  return (
      <ContainerFull style={style} className={className}>
        <Container>
          <TextContainer>
            <Title
              initial={{ opacity: 0, y: 10 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{
                duration: 0.5,
                delay: 0.3,
              }}>
                A garantia de realização dos seus objetivos, através de uma gestão patrimonial estratégica.
            </Title>
            <Text
              style={{marginTop: '1.5rem'}}
              initial={{ opacity: 0, y: 10 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{
                duration: 0.5,
                delay: 0.3,
              }}>
                Para você que tem mais de <b>700 mil reais em investimentos,</b> tanto no mercado financeiro ou imobilizado, mas se sente inseguro ou percebe que pode não estar explorando o potencial máximo em rendimentos.<br />
            </Text>
            <Text
              style={{marginTop: '0.5rem'}}
              initial={{ opacity: 0, y: 10 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{
                duration: 0.5,
                delay: 0.3,
              }}>
                Com <b>total isenção do conflito de interesse,</b> nós te guiaremos acerca de seus investimentos sempre orientados pelo seu plano de vida, garantindo um futuro tranquilo e próspero.
            </Text>
            <Text
              style={{marginTop: '1.5rem'}}
              initial={{ opacity: 0, y: 10 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{
                duration: 0.5,
                delay: 0.3,
              }}>
                Com <b>Yuno Wealth</b> você terá certeza de estar no caminho certo.
            </Text>
          </TextContainer>
          <Section initial={{ opacity: 0, y: 10 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{
                duration: 0.5,
                delay: 0.3,
              }}>
           <Title><span>Nossas</span> principais <br className="sm-only"/> entregas</Title>
            <Text className="mb-10">dentro das <b>6 áreas</b> do <br className="sm-only"/> <b>planejamento financeiro</b></Text>
            <SplitContainer>
              <SplitMenu type="wealth" />
            </SplitContainer>
          </Section>
        </Container>
      </ContainerFull>
  );
};

ContentTextMenuWealthComponent.propTypes = {
  className: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.shape]),
};

ContentTextMenuWealthComponent.defaultProps = {
  className: '',
  style: {},
};

export default ContentTextMenuWealthComponent;
