/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import dataYuno from '../../mock-data/yuno.json';
import dataParaEmpresa from '../../mock-data/paraempresa.json';
import dataGrow from '../../mock-data/grow.json';
import dataFamilyOffice from '../../mock-data/family-office.json';
import dataWealth from '../../mock-data/wealth.json';
import { useResize } from '../../hooks';
import { IconGestaoOrcamentaria,
        IconGestaoPatrimonial,
        IconPlanoDeAposentadoria,
        IconGestaoDeRisco,
        IconPlanejamentoTributario,
        IconPlanejamentoSucessorio } from './svgs';
import { Wrapper,
        ContainerLeft,
        Header,
        LogoYuno,
        LogoGrow,
        LogoFamilyeOffice,
        LogoWealth,
        LogoWork,
        MenuContainer,
        Hamburguer,
        Button,
        WhiteBar,
        SubmenuWrapper,
        SubButton,
        SubmenuMobileWrapper,
       } from './index.styled';

const iconComponents = {
  IconGestaoOrcamentaria,
  IconGestaoPatrimonial,
  IconPlanoDeAposentadoria,
  IconGestaoDeRisco,
  IconPlanejamentoTributario,
  IconPlanejamentoSucessorio,
}

const SplitMenuComponent = ({ type }) => {

  const { isMobile } = useResize();
  const submenuRef = useRef(null);
  const buttonRefs = useRef([]);
  const [submenuHeight, setSubmenuHeight] = useState(0);
  const [ menuData, setMenuData ] = useState([]);
  const [ theme, setTheme ] = useState({
    background: 'transparent',
    type: ''
  });

  const [ subMenuToBottom, setSubMenuToBottom ] = useState(false)
  const [ isOpen, setIsOpen ] = useState(false);
  const [ alturaContainer, setAlturaContainer ] = useState(440);
  const [ currentButtonData, setCurrentButtonData ] = useState({
    posy: 0,
    submenu: []
  });

  const ref = useRef(null);

  useEffect(() => {
    if(ref.current) {
      setAlturaContainer(ref.current.offsetHeight);
    }
  }, [ref.current, alturaContainer]);

  useEffect(() => {
    if (submenuRef.current) {

      let totalHeight = submenuRef.current.getBoundingClientRect().height;
      setSubmenuHeight(totalHeight);

      // posição do botao + altura do submenu
      let totalSumHeight = currentButtonData.posy + totalHeight;

      setSubMenuToBottom(totalSumHeight > alturaContainer)
    }
  }, [submenuHeight, currentButtonData]);

  useEffect(() => {
    let data = '';

    if(type === 'yuno') data = dataYuno;
    if(type === 'work') data = dataParaEmpresa;
    if(type === 'grow') data = dataGrow;
    if(type === 'family-office') data = dataFamilyOffice;
    if(type === 'wealth') data = dataWealth;

    const newMenuData = data.menu.map(item => {

      return {
        ...item,
        active: false
      }
    })

    setMenuData(newMenuData);
    setTheme({
      background: data.background,
      type: data.type
    })

  }, []);

  const renderLogo = () => {
    if(theme.type === "yuno") {
      return <LogoYuno />
    }

    if(theme.type === "grow") {
      return <LogoGrow />
    }

    if(theme.type === "family-office") {
      return <LogoFamilyeOffice />
    }

    if(theme.type === "wealth") {
      return <LogoWealth />
    }

    if(theme.type === "work") {
      return <LogoWork />
    }

    return '';
  }

  const toggleActiveButton = (id) => (event) => {

    const buttonPosY = event.currentTarget.offsetTop + 8;

    const newMenuData = menuData.map(item => {
      if(item.id === id) {

        if(item.active === true) {
          setCurrentButtonData({
            posy: buttonPosY,
            submenu: []
          })
          setIsOpen(false);
        } else {
          setCurrentButtonData({
            posy: buttonPosY,
            submenu: item.submenu
          })
          if(!isOpen) setIsOpen(true);
        }

        return {
          ...item,
          active: !item.active
        }
      }

      return {
        ...item,
        active: false
      }
    });

    setMenuData(newMenuData);
  }

  const handleButtonClick = (id) => (event) => {
    toggleActiveButton(id)(event);
  };

  const triggerButtonClick = (index) => {
    if (buttonRefs.current[index] && buttonRefs.current[index].current) {
      buttonRefs.current[index].current.click();
    }
  };

  const renderButtons = () => {
    const buttons = menuData.map((button) => {

      const IconComponent = iconComponents[button.icone];

      const hasSubmenu = button.submenu.length !== 0;

      const classButton = `${button.active ? 'active' : ''} ${!hasSubmenu ? 'disabled' : ''}`
      return (<Button key={button.id}
                      onClick={handleButtonClick(button.id)}
                      hovercolor={theme.background}
                      className={classButton.trim()}>
        <div className="icon-container" color={theme.background}>
          {IconComponent && <IconComponent />}
        </div>
        <span>{button.Title}</span>
      </Button>)
    })
    return buttons;
  }

  const renderButtonsMobile = () => {
    const buttons = menuData.map((button) => {

      const IconComponent = iconComponents[button.icone];

      const hasSubmenu = button.submenu.length !== 0;

      const classButton = `${button.active ? 'active' : ''} ${!hasSubmenu ? 'disabled' : ''}`
      return (
      <React.Fragment key={button.id}>
      <Button onClick={handleButtonClick(button.id)}
                      hovercolor={theme.background}
                      className={classButton.trim()}>
        <div className="icon-container" color={theme.background}>
          {IconComponent && <IconComponent />}
        </div>
        <span>{button.Title}</span>
      </Button>
      {currentButtonData.submenu.length > 0 &&
        isMobile && renderSubMenu() &&
        hasSubmenu &&
        button.active &&
        <SubmenuMobileWrapper initial={{ opacity: 0, x: 50 }}
                              animate={{ opacity: 1, x: 0 }}
                              transition={{
                                duration: 0.5,
                                ease: 'easeOut'
                              }}>
          {renderSubMenu()}
        </SubmenuMobileWrapper>
      }
      </React.Fragment>
      )
    })
    return buttons;
  }

  const renderSubMenu = () => {
    if(currentButtonData.submenu.length === 0) return '';
    const submenu = currentButtonData.submenu.map(item => {
      const subtitleClass = `subtitle ${item.italic === false && "no-italic"}`;
      return (
        <SubButton key={item.id}>
          {item.title && <span dangerouslySetInnerHTML={{__html: item.title}}/>}
          {item.subtitle && <span className={subtitleClass} dangerouslySetInnerHTML={{__html: item.subtitle}}/>}
        </SubButton>
      );
    })

    return submenu;
  }

  const toggleOpenMenu = () => {
    if(isOpen) {
      setIsOpen(!isOpen);
      setCurrentButtonData({
        posy: 0,
        submenu: []
      })

      const newMenuData = menuData.map(item => {
        return {
          ...item,
          active: false
        }
      });

      setMenuData(newMenuData);
    } else {
      setIsOpen(!isOpen);
    }
  }

  return (
    <Wrapper>
      <ContainerLeft ref={ref} background={theme.background} className={isOpen ? 'active' : ''}>
        <MenuContainer>
          <Header>
            {renderLogo()}
            <Hamburguer>
              <span />
              <span className={isOpen ? 'active' : ''} />
              <span />
            </Hamburguer>
          </Header>
          { !isMobile && renderButtons() }
          { isMobile && renderButtonsMobile() }
        </MenuContainer>
        <WhiteBar altura={alturaContainer} className={isMobile ? 'hidden': ''}>
          <SubmenuWrapper className={subMenuToBottom ? "to-bottom" : ""} ref={submenuRef} posy={currentButtonData.posy} opacity={isOpen ? 1 : 0}>
            {currentButtonData.submenu.length > 0 && !isMobile && renderSubMenu()}
          </SubmenuWrapper>
        </WhiteBar>
      </ContainerLeft>
    </Wrapper>
  );
};

SplitMenuComponent.propTypes = {
  type: PropTypes.string,
};

SplitMenuComponent.defaultProps = {
  type: "yuno",
};

export default SplitMenuComponent;
