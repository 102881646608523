/* eslint-disable */
import styled from 'styled-components';
import { QuizPessoaFisica,
        QuizPessoaJuridica,
        GrQuizFamilyOffice,
        GrQuizGrow,
        GrQuizWealth,
        GrQuizWork } from '../../assets';

export const Container = styled.div`
  width: 420px;
  background: ${p => p.bg};
`;

export const Header = styled.div`
  margin-top: -1px;
  display: flex;
  padding: 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  background: ${p => p.color};
  transition: all 0.3s ease;

  h2 {
    color: #ffffff;
    text-align: center;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    margin: 0;
    padding: 0;
  }

  span {
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 200;
    color: #ffffff;

    &.work {
      color:#6C6C6C;
      font-weight: 400;
      b {
        color: #ffffff;
        font-weight: 600;
      }
    }

    &.grow {
      color:#889CFF;
      font-weight: 400;
      b {
        color: #ffffff;
        font-weight: 600;
      }
    }

    &.wealth {
      color:#8C8B8C;
      font-weight: 400;
      b {
        color: #ffffff;
        font-weight: 600;
      }
    }

    &.family-office {
      color:#B19B52;
      font-weight: 400;
      b {
        color: #ffffff;
        font-weight: 600;
      }
    }
  }
`;

export const Body = styled.div`
  position: relative;
  height: auto;
  min-height: 300px;
  padding: 30px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  background: ${p => p.bg};
  color: #282728;
`;

export const Result = styled.div`
  position: absolute;
  padding: 0 30px 30px 30px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  min-height: inherit;
  background: ${p => p.bg};

  p {
    color: #F7F7F7;
    font-size: 13px;
    font-weight: 200;
    margin-bottom: 20px;

    &.mb-0 {
      margin-bottom: 0px;
    }
  }

  div.work {
    width: 100%;
    height: 155px;
    min-height: 155px;
    background: url(${GrQuizWork});
    background-repeat: no-repeat;
    background-size: cover;
  }

  div.grow {
    width: 100%;
    height: 155px;
    min-height: 155px;
    background: url(${GrQuizGrow});
    background-repeat: no-repeat;
    background-size: cover;
  }

  div.wealth {
    width: 100%;
    height: 155px;
    min-height: 155px;
    background: url(${GrQuizWealth});
    background-repeat: no-repeat;
    background-size: cover;
  }

  div.family-office {
    width: 100%;
    height: 155px;
    min-height: 155px;
    background: url(${GrQuizFamilyOffice});
    background-repeat: no-repeat;
    background-size: cover;
  }
`;

export const FirstStep = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  h3 {
    color: #282728;
    font-weight: 200;
  }

  div.questions {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding-bottom: 20px;

    p {
      font-size: 14px;
      padding: 15px;
      text-align: center;
      background: #E2E2E2;
      color: #282728;
      border-radius: 8px;
      transition: all 0.3s ease;
      cursor: pointer;

      &:hover {
        background: #0C053F;
        color: #E2E2E2;
      }
    }
  }
`

export const ChooseContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  div {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 157px;
    height: 157px;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;

    &:hover {
      &:before {
        content: '';
        background: rgba(12,5,63, 0.4)
      }

      span {
        color: #ffffff;
      }
    }

    span {
      z-index: 1;
      text-align: center;
      color: #282728;
      font-size: 20px;
      font-weight: 500;
      transition: all 0.3s ease;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(255,255,255, 0.8);
      transition: all 0.3s ease;
    }

    &.pessoa-fisica {
      background: url(${QuizPessoaFisica});
      background-repeat: no-repeat;
      background-size: cover;
    }

    &.pessoa-juridica {
      background: url(${QuizPessoaJuridica});
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
`

export const SaibaMais = styled.div`
  position: relative;
  width: 120px;
  opacity: 1;
  display: inline-flex;
  align-items: center;
  padding-right: 30px;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 1px;
  font-size: 14px;
  color: #ffffff;
  transition: 0.25s ease-in-out all;
  transform-origin: center;
  transform: scale(1);
  cursor: pointer;

  &:hover {
    span {
      width: 144px;
    }
  }
`;

export const Icone = styled.span`
  position: absolute;
  top: 50%;
  right: -10px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 30px;
  height: 30px;
  background: ${p => p.bulletColor};
  border-radius: 30px;
  padding-right: 4px;
  z-index: -1;
  transition: 0.25s ease-in-out all;

  svg {
    width: 21px;
    height: auto;
    color: ${p => (p.color ? p.color : '#ffffff')}
    box-shadow: 0px 0px 17px -7px rgba(0, 0, 0, 0.75);
  }
`;
