import styled from 'styled-components';

export const CardWrapper = styled.li`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 270px;
  min-width: 270px;
  height: 360px;
  max-height: 360px;
  transition: 0.3s ease all;
  transform: scale(0.94);

  &.glide__slide--active {
    transform: scale(1);
  }

  &:hover {
    div {
      opacity: 1;
    }
  }
`;

export const Background = styled.div`
  position: absolute;
  top: 0;
  height: 0;
  width: 270px;
  height: 100%;
  background: url(${p => p.url});
  background-size: 270px 360px;
  background-position: center center;
  background-repeat: no-repeat;
  z-index: 0;
  transition: 0.4s ease all;
  opacity: 0.9;
`;
