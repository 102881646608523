import React from 'react';
import PropTypes from 'prop-types';
import { CardWrapper, Order, Text } from './index.styled';

const CardPerguntaComponent = ({
  title,
  order,
  distance,
  style,
  className,
  delay,
}) => {
  return (
    <CardWrapper
      initial={{ opacity: 0, y: 16 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{
        duration: 0.6,
        ease: 'easeOut',
        delay,
      }}
      className={`${className}`}
      style={style}
    >
      <Order>{order}</Order>
      <Text distance={distance}>{title}</Text>
    </CardWrapper>
  );
};

CardPerguntaComponent.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  order: PropTypes.string,
  delay: PropTypes.number,
  distance: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.shape]),
};

CardPerguntaComponent.defaultProps = {
  title: '',
  order: '',
  delay: 0,
  distance: '50px',
  className: '',
  style: {},
};

export default CardPerguntaComponent;
