import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  width: 45px;
  height: 45px;
  border-radius: 26px;
  cursor: pointer;
  background: #ffffff;
  transition: all 0.2s linear;

  &:hover {
    background: ${c => c.bgcolors[1]};

    svg {
      width: 24px;
      height: auto;
      color: ${c => c.bgcolors[0]};
    }
  }

  &:active {
    background: ${c => c.bgcolors[2]};
  }

  svg {
    width: 24px;
    height: auto;
    color: ${c => c.bgcolors[1]};
    transition: all 0.2s linear;
  }
`;
