/* eslint-disable */
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { ImageQuemSomos2 } from '../../assets';

export const ContainerFull = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  margin-top: auto;
  background: #ffffff;

  /* @media (min-width: 768px) {
    min-height: 420px;
  } */
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  justify-content: flex-start;

  @media (min-width: 768px) {
    flex-direction: row;
    height: 100%;
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  background-image: url(${ImageQuemSomos2});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;

  @media (max-width: 767px) {
    height: 100%;
    min-height: 280px;
  }

  @media (min-width: 768px) {
    width: 50%;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 40px 40px;

  @media (min-width: 768px) {
    width: 50%;
    padding: 10px 30px;
    min-height: 300px;
  }

  @media (min-width: 992px) {
    padding: 0px 40px;
    min-height: 400px;
  }

  @media (min-width: 1200px) {
    padding: 0px 64px;
    min-height: 500px;
  }

  @media (min-width: 1600px) {
    padding: 0px 80px;
    min-height: 600px;
  }
`;

export const Title = styled(motion.h1)`
  font-size: 26px;
  line-height: 34px;
  margin-bottom: 0px;
  letter-spacing: 0;
  color: #282728;
  font-weight: 400;
  text-align: justify;
  text-justify: inter-word;

  @media (min-width: 768px) {
    /* border: solid 1px yellow; */
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 30px;
  }

  @media (min-width: 992px) {
    /* border: solid 1px orange; */
    font-size: 24px;
    line-height: 30px;
    max-width: 90%;
  }

  @media (min-width: 1200px) {
    /* border: solid 1px red; */
    font-size: 30px;
    line-height: 40px;
    max-width: 90%;
  }

  @media (min-width: 1600px) {
    /* border: solid 1px blue; */
    font-size: 40px;
    line-height: 46px;
    max-width: 84%;
  }
`;

export const Text = styled(motion.p)`
  font-size: 16px;
  color: #282728;
  font-weight: 400;
  text-align: justify;
  text-justify: inter-word;

  @media (min-width: 768px) {
    /* border: solid 1px yellow; */
    font-size: 16px;
  }

  @media (min-width: 992px) {
    /* border: solid 1px orange; */
    font-size: 18px;
  }

  @media (min-width: 1200px) {
    /* border: solid 1px red; */
    font-size: 20px;
  }

  @media (min-width: 1600px) {
    /* border: solid 1px blue; */
    font-size: 25px;
  }
`;

export const ButtonClose = styled.button`
  width: 30px;
  height: 30px;
  border: 0;
  outline: 0;
  cursor: pointer;
  display: flex;
  background: transparent;
  align-items: center;
  justify-content: center;
`;

export const Button = styled(motion.div)`
  border: solid 1px #ffffff;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  padding: 9px 10px 9px 20px;
  border-radius: 40px;
  color: #ffffff;
  cursor: pointer;
  background: rgba(255, 255, 255, 0);
  transition: all 0.2s ease;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }

  @media (min-width: 768px) {
    margin-top: 90px;
  }
`;

export const CardsContainer = styled(motion.div)`
  width: 100%;
  margin-top: 100px;
  display: flex;
  justify-content: space-between;
`;

export const Card = styled.div`
  display: flex;
  height: auto;
  padding: 20px 15px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  background: #130b4a;
  box-shadow: 3px 10px 20px 0px rgba(0, 0, 0, 0.5);

  h2 {
    width: auto;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    color: #ffffff;
    white-space: nowrap;
  }

  p {
    width: 260px;
    text-align: left;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: #f7f7f7;
  }
`;
