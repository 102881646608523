/* eslint-disable */
import React, {useState} from 'react';
import { ContainerFull, Container } from '../../assets/globalStyle';
import {
  PlanejamentoFinanceiro,
  MelhorInvestimento,
  RealizarSonhos,
  TransformarVidaFinanceira,
  AtendemosTodoBrasil,
  FaleConosco,
  FiqueConectado,
  DivisorButton,
  Modal,
  Quiz,
} from '../../components';

function HomePage() {
  const [isModalOpen, setIsModalOpen] = useState(false);


  const toggleModal = () => {
      setIsModalOpen(!isModalOpen);
  }

  const gotoWhatsApp = (phoneNumber) => {
    const url = `https://wa.me/${phoneNumber}`;
    window.open(url, '_blank');
  }

  return (
    <React.Fragment>
      <Modal isOpen={isModalOpen} toggleModal={toggleModal} isClickOutside={true}>
         <Quiz />
      </Modal>
      <ContainerFull>
        <MelhorInvestimento />
      </ContainerFull>
      <ContainerFull>
          <TransformarVidaFinanceira />
      </ContainerFull>
      <ContainerFull>
        <DivisorButton
        handleClick={() => setIsModalOpen(true)}
        title="Descubra a melhor solução para o seu contexto!" themeType="default" />
      </ContainerFull>
      <ContainerFull>
        <AtendemosTodoBrasil />
      </ContainerFull>
      <ContainerFull>
        <DivisorButton handleClick={() => gotoWhatsApp(5562993376742)} title="Inicie agora seu Planejamento Financeiro" themeType="light" />
      </ContainerFull>
      {/* <Container column> */}
            {/* <PlanejamentoFinanceiro /> */}
          {/* <RealizarSonhos /> */}
          {/* <TransformarVidaFinanceira /> */}
          {/* <FiqueConectado /> */}
          {/* <FaleConosco /> */}
      {/* </Container> */}
    </React.Fragment>
  );
}

export default HomePage;
