/* eslint-disable */
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { BgSobreQualidadeVida, BgSobreQualidadeVidaMobile } from '../../assets';

export const ContainerFull = styled.div`
  width: 100%;

  &.qualidade-vida {
    background: url(${BgSobreQualidadeVidaMobile}), #0c053f;
    min-height: 780px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }
  &.qualidade-vida {
    @media (min-width: 768px) {
      background: url(${BgSobreQualidadeVida}), #0c053f;
      min-height: 990px;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
    }
  }
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${p => (p.column ? 'column' : 'row')};
  width: 100%;
  max-width: 1340px;
  margin: 0 auto;
  justify-content: flex-start;
  height: 100vh;
  padding: 10px;

  @media (min-width: 768px) {
    padding: 20px;
    height: 100%;
  }

  @media (min-width: 992px) {
    padding: 0;
  }
`;

export const Title = styled(motion.h1)`
  font-size: 30px;
  letter-spacing: 0;
  color: #0C053F;
  font-weight: 400;

  @media (min-width: 768px) {
    font-size: 34px;
  }

  @media (min-width: 992px) {
    font-size: 36px;
  }

  @media (min-width: 1200px) {
    font-size: 44px;
  }

  @media (min-width: 1600px) {
    font-size: 50px;
  }
`;

export const Text = styled(motion.p)`
  font-size: 50px;
  color: #0C053F;
  font-weight: 600;
  width: 240px;

  @media (min-width: 768px) {
    font-size: 50px;
    margin-top: 10px;
    width: 400px;
  }

  @media (min-width: 992px) {
    margin-top: 20px;
    font-size: 60px;
    width: 660px;
  }

  @media (min-width: 1200px) {
    font-size: 70px;
  }

  @media (min-width: 1600px) {
    font-size: 90px;
  }
`;

export const TextContainer = styled.div`
  width: calc(100% - 40px);
  max-width: 400px;
  height: auto;
  overflow: hidden;
  margin: 0 auto;
  margin-top: 20px;

  @media (min-width: 768px) {
    width: 600px;
    max-width: 100%;
    margin: 0;
    margin-top: 380px;
    padding-left: 10px;
  }

  @media (min-width: 992px) {
    width: 800px;
    margin-top: 367px;
    padding-left: 40px;
  }

  @media (min-width: 1200px) {
    padding-left: 40px;
  }

  @media (min-width: 1600px) {
    padding-left: 0px;
  }
`

export const ButtonClose = styled.button`
  width: 30px;
  height: 30px;
  border: 0;
  outline: 0;
  cursor: pointer;
  display: flex;
  background: transparent;
  align-items: center;
  justify-content: center;
`;

export const Button = styled(motion.div)`
  border: solid 1px #ffffff;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  padding: 9px 10px 9px 20px;
  border-radius: 40px;
  color: #ffffff;
  cursor: pointer;
  background: rgba(255, 255, 255, 0);
  transition: all 0.2s ease;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }

  @media (min-width: 768px) {
    margin-top: 90px;
  }
`;

export const CardsContainer = styled(motion.div)`
  width: 100%;
  margin-top: 100px;
  display: flex;
  justify-content: space-between;
`;

export const Card = styled.div`
  display: flex;
  height: auto;
  padding: 20px 15px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  background: #130b4a;
  box-shadow: 3px 10px 20px 0px rgba(0, 0, 0, 0.5);

  h2 {
    width: auto;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    color: #ffffff;
    white-space: nowrap;
  }

  p {
    width: 260px;
    text-align: left;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    color: #f7f7f7;
  }
`;
