import styled from 'styled-components';
import { LogoWhite, CFPLogo, CVMLogo } from '../../assets';

export const ContainerFull = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  width: 100%;
  margin-top: auto;
  border-top: solid 1px rgba(255, 255, 255, 0.1);
  background: ${props => props.theme.type.background};

  &.separator-bootom {
    border-bottom: solid 1px rgba(255, 255, 255, 0.1);
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  max-width: 1340px;
  height: 100%;
  font-size: 0.63rem;
  letter-spacing: 0.5px;

  @media (max-width: 767px) {
    padding: 38px 0 0 0;
  }

  @media (min-width: 768px) {
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    &.flex-md-row {
      flex-direction: row;
      align-items: flex-end;
    }
  }
`;

export const ContentTop = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 20px;
  padding-top: 40px;

  @media (max-width: 767px) {
    width: calc(100% - 40px);
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 0 auto;
    & > * {
      padding-left: 16px;
      &:not(:first-child) {
        padding-left: 40px;
      }
    }
  }

  @media (min-width: 768px) {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    padding-right: 120px;
    margin-top: 0;
    padding: 40px 0;
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LogoContainer = styled.div`
  width: 220px;
  height: 70px;
  background: url(${LogoWhite});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
`;

export const LogoCVM = styled.div`
  width: 110px;
  height: 51px;
  background: url(${CVMLogo});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
`;

export const LogoCFP = styled.div`
  width: 75px;
  height: 51px;
  background: url(${CFPLogo});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
`;

export const Address = styled.p`
  width: 196px;
  color: #f7f7f7;
  border-top: solid 1px #f7f7f7;
  margin-top: 40px;
  margin-left: auto;
  padding: 10px 0;
  font-size: 11px;
  font-weight: 100;
  white-space: nowrap;
`;

export const DivContactInfos = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;

  &.flex-between {
    justify-content: space-between;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    width: 100%;
    border-top: solid 1px rgba(255, 255, 255, 0.35);
    margin-top: 20px;

    div:not(:first-child) {
      padding-right: 18px;
    }
  }

  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0;

    justify-content: space-between;
    align-items: flex-start;
  }

  @media (min-width: 992px) {
    padding: 0;
  }
`;

export const ImageVIver = styled.img`
  width: auto;
  height: 38px;
  margin-right: 80px;
`;

export const DivParagraph = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  color: #ffffff;
  font-size: 12px;
`;

export const LogosContainer = styled.div`
  display: flex;
  gap: 44px;
  align-items: center;
  @media (max-width: 767px) {
    justify-content: center;
    margin: 30px auto 0px auto;
  }
`;

export const Termos = styled.div`
  color: var(--color-gray-2);

  a {
    color: var(--color-gray-2);
    font-size: 12px;
    cursor: pointer;
    opacity: 1;
    transition: all 0.3s ease;

    &:hover {
      opacity: 0.8;
    }

    &:not(:first-child) {
      margin-left: 24px;
    }
  }

  @media (max-width: 767px) {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 30px;
    a {
      display: inline-block;
    }
  }

  @media (min-width: 768px) {
    position: relative;
    top: initial;
    right: initial;
  }
`;

export const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (max-width: 767px) {
    margin: 20px 0;
  }

  a {
    color: #ffffff;
    font-size: 12px;
    font-weight: 100;
  }

  .whatsapp {
    margin-right: 8px;
  }
`;

export const Title = styled.h2`
  color: #ffffff;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  margin: 0;
`;

export const SocialMedia = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: #ffffff;

  svg {
    width: 25px;
    height: auto;
  }
`;
