import styled from 'styled-components';
import { getImage } from '../../utils';

export const CardWrapper = styled.li`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  width: 270px;
  min-width: 270px;
  border-radius: 16px;
  height: 308px;
  max-height: 308px;
  transition: 0.3s ease all;
  transform: scale(0.94);
  gap: 6px;

  &.glide__slide--active {
    transform: scale(1);
  }

  &:hover {
    div {
      opacity: 1;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  min-height: 90px;
  gap: 8px;
  z-index: 1;
`;

export const Image = styled.div`
  width: 88px;
  height: 88px;
  border-radius: 44px;
  background: ${p => `url(${getImage(p.src)})`};
  background-repeat: no-repeat;
  background-size: cover;
`;

export const Nome = styled.h3`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  font-weight: 700;
  color: #212121;
`;

export const Data = styled.p`
  color: #212121;
  font-size: 12px;
  font-weight: 400;
`;

export const StarsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  width: 100%;
  height: 24px;
`;

export const ContentInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: calc(100% - 90px);
`;

export const ContentDescricao = styled.div`
  display: block;
  width: 100%;
  height: calc(100%);
  overflow-y: auto;
  z-index: 1;
`;

export const Text = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #212121 !important;
`;

export const Background = styled.div`
  position: absolute;
  top: 0;
  height: 0;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.4);
  z-index: 0;
  transition: 0.4s ease all;
  opacity: 0.9;
`;
