import styled from 'styled-components';
import { motion } from 'framer-motion';

export const ButtonWrapper = styled(motion.button)`
  border: 0;
  border-radius: 6px;
  outline: 0;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-weight: 500;
  background: ${c => c.bgcolors[0]};
  padding: 12px 16px;
  align-items: flex-start;
  color: ${c => c.colors[0]};
  transition: all 0.2s linear;

  &.m-auto-xs {
    @media (max-width: 767px) {
      margin: 0 auto;
    }
  }

  &.bigger {
    padding: 14px 40px;
    border-radius: 24px;

    @media (min-width: 768px) {
      font-size: 24px;
      padding: 14px 95px;
      border-radius: 24px;
    }
  }

  &.medium {
    padding: 14px 20px;
    border-radius: 24px;

    @media (min-width: 768px) {
      font-size: 24px;
      padding: 14px 52px;
      border-radius: 24px;
    }
  }

  &.bold {
    font-weight: 700;
  }

  &:hover {
    background: ${c => c.bgcolors[1]};
    color: ${c => c.colors[1]};
  }

  &:active {
    background: ${c => c.bgcolors[2]};
    color: ${c => c.colors[2]};
  }
`;
