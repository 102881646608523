import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${p => (p.column ? 'column' : 'row')};
  align-items: center;
  justify-content: ${p => (p.justifyCenter ? 'center' : 'space-between')};
  width: 100%;
  max-width: 1340px;
  margin: ${p => (p.margin ? p.margin : '0 auto')};
  padding: 20px;
  height: 100%;

  @media (min-width: 768px) {
    padding: ${p => (p.distance ? 'var(--border-distance)' : '0')};
  }
`;

export const Title = styled(motion.h5)`
  text-align: center;
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
  color: #212121;

  @media (min-width: 768px) {
    font-size: 40px;
    line-height: 48px;
  }
`;

export const Button = styled(motion.button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  padding: 12px 16px;
  color: #4739eb;
  border: solid 1px currentColor;
  border-radius: 24px;
  background: #ffffff;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;

  &:hover {
    color: #1c11a7;
  }

  @media (min-width: 768px) {
    width: 254px;
    margin-right: 18px;
  }
`;

export const Text = styled.p`
  font-size: 24px;
  line-height: 32px;
  color: #212121;
  font-weight: 600;
  text-align: right;
  max-width: 480px;
`;

export const Section = styled.section`
  width: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 0px;
  height: 640px;
  background: ${p => p.bgcolor};

  @media (min-width: 768px) {
    margin-top: 150px;
    height: 600px;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  height: auto;

  @media (min-width: 768px) {
    flex-direction: column;
    height: auto;
    padding-bottom: 100px;
  }
`;
