import styled from 'styled-components';
import { BGFaleConosco } from '../../assets';

export const ContainerFull = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  width: 100%;
  height: auto;
  margin-top: auto;
  background: #ffffff;
  background: linear-gradient(
      0deg,
      rgba(28, 17, 167, 0.59),
      rgba(28, 17, 167, 0.59)
    ),
    url(${BGFaleConosco});
  background-size: cover;
  background-position: center;

  @media (min-width: 768px) {
    flex-direction: row;
    background-position: initial;
    height: 660px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  max-width: 1340px;
  height: 100%;
  font-size: 0.63rem;
  letter-spacing: 0.5px;
  padding: ${p => (p.distance ? 'var(--border-distance)' : '0')};

  div h1 strong {
    font-family: 'Poppins', sans-serif !important;
    @media (max-width: 767px) {
      font-size: 24px !important;
      font-weight: 700 !important;
    }
  }

  .bricks--section.rd-section {
    box-shadow: 0px 8px 24px rgba(68, 70, 117, 0.15);
    border-radius: 16px !important;
    overflow: hidden;

    input[id^='rd-text_field'],
    input[id^='rd-email_field'] {
      font-family: 'Poppins', sans-serif !important;
      padding-left: 18px !important;
    }

    input[id^='rd-phone_field'] {
      font-family: 'Poppins', sans-serif !important;
      padding-left: 10px !important;
    }

    div[id^='rd-text'] p {
      line-height: 1.2rem !important;
    }

    input[id^='checkbox-2'] {
      margin-left: 8px !important;
    }

    .check-label {
      display: inline-flex;
      align-items: center;
      gap: 6px;
      width: 100%;
      white-space: nowrap;
    }

    .rd-button {
      border: none !important;
      opacity: 1;
      transition: all 0.3s ease;
      margin-top: 20px !important;

      &:hover {
        opacity: 0.9;
      }

      &:disabled {
        opacity: 0.4;
        cursor: not-allowed !importants;
      }
    }
  }

  .b-radius {
    position: relative;
  }

  .termos {
    display: inline-flex;
    font-size: 14px !important;
    color: rgb(200, 200, 200)
    cursor: pointer;

    a {
      color: blue;
      text-decoration: underline;
    }

    @media (max-width: 767px) {
      top: 270px;
    }
  }

  @media (max-width: 767px) {
    padding: 38px 0 0 0;
  }

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    font-size: 0.85rem;
    letter-spacing: 0.4px;
  }

  @media (min-width: 1000px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;
