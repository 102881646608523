/* eslint-disable */
import styled from 'styled-components';
import { motion } from 'framer-motion'

export const Modal = styled(motion.div)`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
`;

export const ModalWrapper = styled(motion.div)`
  position: absolute;
  top: 50%;
  width: auto;
  min-width: 100%;
  height: auto;
  min-height: 200px;
  background: #fff;
  transform: translateY(-50%);
  border-radius: 24px;
  display: flex;
  transition: all .3s ease;
  overflow: hidden;

  @media(max-width: 767px) {
    max-width: 100vw;
  }

  @media(min-width: 768px) {
    min-width: 420px;
  }
`;
