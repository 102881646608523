import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${p => (p.column ? 'column' : 'row')};
  align-items: flex-start;
  justify-content: ${p => (p.justifyCenter ? 'center' : 'space-between')};
  width: 100%;
  max-width: 1340px;
  margin: ${p => (p.margin ? p.margin : '0 auto')};
  padding: 20px;

  @media (min-width: 768px) {
    align-items: center;
    height: auto;
  }
`;

export const Title = styled(motion.h2)`
  font-size: 30px;
  line-height: 54px;
  color: #424242;
  font-weight: 700;
  margin-top: 0px;
  margin-bottom: -10px;
  text-align: center;

  span {
    font-size: 44px;
  }

  @media (min-width: 768px) {
    font-size: 40px;
    line-height: 70px;
    margin-top: 20px;
    margin-bottom: 58px;
    text-align: center;

    span {
      font-size: 56px;
    }
  }
`;

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: inherit;
  padding-top: 20px;
  height: auto;
`;
