import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Background = styled(motion.div)`
  position: fixed;
  display: flex;
  justify-content: flex-end;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.61);
  z-index: 10;
`;

export const Container = styled(motion.nav)`
  width: calc(100% - 48px);
  height: 100%;
  background: #0c053f;
  padding: 24px 18px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
`;

export const Nav = styled.nav`
  display: flex;
  padding-top: 20px;
`;

export const Menu = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const Button = styled.li`
  width: 100%;
  margin: 10px 0;
  font-size: 0.8rem;
  opacity: 1;
  transition: all 0.3s ease;
  font-weight: 600;
  text-align: center;

  &:hover,
  &:active {
    opacity: 0.9;
    a {
      color: var(--color-blue-1);
    }
  }
  a {
    display: block;
    padding: 4px 8px;
    color: var(--color-white);
  }
`;

export const Separator = styled.div`
  border-top: solid 1px var(--color-white);
  min-width: 200px;
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-right: 8px;
`;

export const ButtonOutside = styled.li`
  min-width: 200px;
  display: flex;
  justify-content: flex-end;
  margin: 10px 0;
  padding: 20px 8px;
  line-height: 26px;
`;

export const SubMenu = styled.div`
  display: flex;
  flex-direction: column;
  top: 100%;
  left: 0;
  height: auto;
  min-height: 60px;
  width: auto;
  min-width: 150px;
  background: ${p => p.background};
`;

export const SubButton = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 16px 24px;
  font-size: 1rem;
  font-weight: 500;
  line-height: 28px;
  transition: all 0.3s ease;
  height: 100%;

  &:hover {
    a {
      opacity: 1 !important;
      color: ${p =>
        p.color === 'white' ? 'var(--color-white)' : 'var(--color-white)'};
    }
  }
  a {
    display: inline-flex;
    flex-direction: column;
    white-space: nowrap;
    align-items: center;
    line-height: 16px;
    height: 100%;
    opacity: 1;

    span {
      font-size: 12px;
      font-weight: 200;
      white-space: nowrap;
    }

    color: ${p =>
      p.color === 'white' ? 'var(--color-white)' : 'var(--color-white)'};
    transition: all 0.3s ease;

    &.active {
      color: var(--color-white);
      opacity: 1;
    }
  }
`;
