/* eslint-disable */
import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import menu from '../../mock-data/menu.json';
// import { ButtonClose, ButtonColor } from '..';
import { ButtonColor } from '..';
import {
  Background,
  Container,
  Menu,
  SubMenu,
  SubButton,
  Nav,
  Button,
  ButtonOutside,
  Separator,
} from './index.styled';

const MenuMobileComponent = ({ closeMenuMobile }) => {
  const closeMobile = () => {
    closeMenuMobile(false);
  };

  const handlerAcessar = () => {
    /* window.open(
      'http://www.realeasy.com.br/home',
      '_blank',
      'noopener,noreferrer'
    ); */
  };
  return (
    <Background
      key="center"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.3, delay: 0.2 } }}
      exit={{ opacity: 0, transition: { duration: 0.5 } }}
      transition={{ delay: 0 }}
    >
      <Container
        key="menu"
        initial={{ opacity: 0, x: '110vw' }}
        animate={{
          opacity: 1,
          x: 0,
          transition: { duration: 0.5, delay: 0.2 },
        }}
        exit={{ opacity: 0, x: '110vw', transition: { duration: 0.35 } }}
        transition={{ delay: 0 }}
      >
        {/* <ButtonClose size="14px" handleClick={closeMobile} /> */}
        <Nav>
          <Menu>
            {menu.map(button => {
              return (
                <React.Fragment key={button.title}>
                  <Button>
                    <NavLink to={`${button.to}`} onClick={closeMobile}>
                      {button.title}
                    </NavLink>
                  </Button>
                  {button.to === "/para-voce" &&
                    <SubMenu>
                      <Separator />
                      <SubButton>
                        <NavLink to="/para-voce/grow" onClick={closeMobile}>
                          Yuno Grow<br />
                          <span>para você crescer</span>
                        </NavLink>
                      </SubButton>
                      <Separator />
                      <SubButton>
                       <NavLink to="/para-voce/wealth" onClick={closeMobile}>
                        Yuno Wealth<br />
                        <span>para crescer seu patrimônio</span>
                        </NavLink>
                      </SubButton>
                      <Separator />
                      <SubButton>
                        <NavLink to="/para-voce/family-office" onClick={closeMobile}>
                          Yuno Family Office<br />
                          <span>para gerir seu patrimônio</span>
                        </NavLink>
                      </SubButton>
                    </SubMenu>
                  }
                  <Separator />
                </React.Fragment>
              );
            })}

            {false && (
              <ButtonOutside>
                <ButtonColor
                  bgcolors={['#F2F1FF', '#FFFFFF', '#FFFFFF']}
                  colors={['#4739EB', '#1C11A7', '#1C11A7']}
                  handleClick={handlerAcessar}
                >
                  Acessar
                </ButtonColor>
              </ButtonOutside>
            )}
          </Menu>
        </Nav>
      </Container>
    </Background>
  );
};

MenuMobileComponent.propTypes = {
  closeMenuMobile: PropTypes.func,
};

MenuMobileComponent.defaultProps = {
  closeMenuMobile: () => {},
};

export default MenuMobileComponent;
