import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${p => (p.column ? 'column' : 'row')};
  align-items: center;
  justify-content: ${p => (p.justifyCenter ? 'center' : 'space-between')};
  width: 100%;
  max-width: 1340px;
  margin: ${p => (p.margin ? p.margin : '0 auto')};
  padding: 20px;
  height: 400px;

  @media (min-width: 768px) {
    height: 500px;
    /* border: solid 1px yellow; */
    padding: var(--border-distance);
  }

  @media (min-width: 992px) {
    height: 500px;
    /* border: solid 1px red; */
  }

  @media (min-width: 1200px) {
    height: 600px;
    /* border: solid 1px orange; */
  }

  @media (min-width: 1400px) {
    height: 600px;
    /* border: solid 1px green; */
  }
`;

export const Text = styled.p`
  font-size: 24px;
  line-height: 32px;
  color: #212121;
  font-weight: 600;
  text-align: right;
  max-width: 480px;
`;

export const Section = styled.section`
  width: inherit;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 20px;
  height: auto;
  background: ${p => p.bgcolor};

  @media (min-width: 768px) {
    height: 600px;
  }
`;
