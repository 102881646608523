/* eslint-disable */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FaChevronRight } from 'react-icons/fa';
import dataTeam from '../../mock-data/equipe.json';
import { getImage } from '../../utils/index';
import { useResize } from '../../hooks';
import { Container,
        Header,
        Body,
        LogosContainer,
        Slider,
        BulletsContainer,
        ImageContainer,
        PersonContainer,
        Bullet,
       } from './index.styled';

const EquipeComponent = ({ style, className }) => {

  const { isMobile, width } = useResize();

  const [vwidth, setVWidth ] = useState(width);
  const [fraction, setFraction ] = useState(null);

  const steps = dataTeam.length;
  const offSet = 3;
  const [ step, setStep ] = useState(0);

  useEffect(() => {
    isMobile ? setVWidth(width) : setVWidth(414);
    setFraction(vwidth / 3);

  }, [isMobile, fraction, vwidth]);

  const nextStep = () => {
    setStep((step) => step + 1);
  }

  const prevStep = () => {
    setStep(step + (steps - 1))
  }

  const renderImage = (image) => {

    if(image.length > 1) {
      return getImage('equipe/' + image);
    }
    return getImage('equipe/person.jpg');
  }

  const renderTeam = () => {
    const renderedPersons = dataTeam.map((person, index) => {
      return(
        <PersonContainer key={person.id} largura={fraction}>
          <ImageContainer className={(step % steps) === index ? 'active' : ''} image={renderImage(person.imagem)} />
          <h2 dangerouslySetInnerHTML={{ __html: person.nome }} />
          <p dangerouslySetInnerHTML={{ __html: person.cargo }} />
        </PersonContainer>
      )
    })
    return renderedPersons
  };

  const renderSteps = () => {
    const renderedSteps = dataTeam.map((person, index) => {
      return(
        <Bullet key={`step-${person.id}`} className={(step % steps) === index ? 'active' : ''} onClick={() => setStep(index)} />
      )
    })
    return renderedSteps
  }

  const getPosition = () => {
    var currentId = (step % steps);
    var limitId = steps - offSet;
    if(currentId > limitId) {
      currentId = limitId;
    }
    return (fraction * - currentId)
  }

  return(
      <Container className={`${className}`} style={style} bg="#0C053F">
        <Header color="#0C053F">
          <h2>Conheça nossos<br />planejadores:</h2>
        </Header>
        <Body bg="#F7F7F7">
          <Slider>
            <LogosContainer initial={{ opacity: 0, x: 50 }}
                            animate={{ opacity: 1, x: getPosition() }}
                            transition={{
                              duration: 0.3,
                              delay: 0.1,
                              ease: 'easeOut',
                            }}>
              {renderTeam()}
            </LogosContainer>
          </Slider>
          <BulletsContainer>
            <FaChevronRight className="left" onClick={prevStep}/>
              {renderSteps()}
            <FaChevronRight className="right" onClick={nextStep} />
          </BulletsContainer>
        </Body>
      </Container>
    )
};

EquipeComponent.propTypes = {
  className: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.shape]),
};

EquipeComponent.defaultProps = {
  className: '',
  style: {},
};

export default EquipeComponent;
