/* eslint-disable */
import React from 'react';
import { SplitMenu } from '..'
import PropTypes from 'prop-types';
import { ContainerFull,
          Container,
          TextContainer,
          Text,
          Section,
          SplitContainer,
          Title } from './index.styled';

const ContentTextMenuGrowComponent = ({ style, className }) => {

  return (
      <ContainerFull style={style} className={className}>
        <Container>
          <TextContainer>
            <Title
              initial={{ opacity: 0, y: 10 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{
                duration: 0.5,
                delay: 0.3,
              }}>
                O caminho mais curto entre seu contexto atual e seus objetivos.
            </Title>
            <Text
              style={{marginTop: '1.5rem'}}
              initial={{ opacity: 0, y: 10 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{
                duration: 0.5,
                delay: 0.3,
              }}>
                Como um GPS ajudamos você a traçar <b>rotas estratégicas</b> para que possa avançar em sua construção patrimonial.
            </Text>
            <Text
              style={{marginTop: '1.5rem'}}
              initial={{ opacity: 0, y: 10 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{
                duration: 0.5,
                delay: 0.3,
              }}>
                Através da <b>gestão orçamentária</b>, você conseguirá definir e cumprir metas de investimentos mensais, sem abrir mão da sua qualidade de vida.
            </Text>
            <Text
              style={{marginTop: '1.5rem'}}
              initial={{ opacity: 0, y: 10 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{
                duration: 0.5,
                delay: 0.3,
              }}>
                Você entenderá porque o nosso lema é: <b>Viver é o melhor investimento.</b>
            </Text>
            <Text
            style={{marginTop: '1.5rem'}}
              initial={{ opacity: 0, y: 10 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{
                duration: 0.5,
                delay: 0.3,
              }}>
                O seu patrimônio crescerá de duas formas: aumento da sua capacidade de poupança e melhora dos rendimentos dos seus recursos investidos.
            </Text>
          </TextContainer>
          <Section initial={{ opacity: 0, y: 10 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{
                duration: 0.5,
                delay: 0.3,
              }}>
            <Title><span>Nossas</span> principais <br className="sm-only"/> entregas</Title>
            <Text className="mb-10">dentro das <b>6 áreas</b> do <br className="sm-only"/> <b>planejamento financeiro</b></Text>
            <SplitContainer>
              <SplitMenu type="grow" />
            </SplitContainer>
          </Section>
        </Container>
      </ContainerFull>
  );
};

ContentTextMenuGrowComponent.propTypes = {
  className: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.shape]),
};

ContentTextMenuGrowComponent.defaultProps = {
  className: '',
  style: {},
};

export default ContentTextMenuGrowComponent;
