import styled from 'styled-components';
import { motion } from 'framer-motion';

export const ContainerFull = styled(motion.div)`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  z-index: 5;

  @media (min-width: 768px) {
    height: 60px;
  }

  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
  }
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${p => (p.column ? 'column' : 'row')};
  align-items: center;
  justify-content: ${p => (p.justifyCenter ? 'center' : 'space-between')};
  width: 100%;
  max-width: 1340px;
  margin: ${p => (p.margin ? p.margin : '0 auto')};
  padding: 10px 18px 10px 10px;
  height: 100%;

  @media (min-width: 992px) {
    padding: 0;
  }
`;
