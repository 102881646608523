import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  max-width: 1340px;
  margin: ${p => (p.margin ? p.margin : '0 auto')};
  pointer-events: all !important;

  @media (min-width: 768px) {
    align-items: center;
    height: auto;
  }

  @media (min-width: 992px) {
    height: auto;
    /* border: solid 1px red; */
  }

  @media (min-width: 1200px) {
    height: auto;
    /* border: solid 1px orange; */
  }

  @media (min-width: 1400px) {
    height: auto;
    /* border: solid 1px green; */
  }
`;

export const CardsContainer = styled.ul`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 40px;
  width: 100%;
  height: auto;
  margin: 20px 0;

  @media (min-width: 992px) {
    margin: 80px 0;
    gap: 10px !important;
  }
`;

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  width: inherit;
  padding-top: 20px;
  height: auto;

  @media (min-width: 768px) {
    padding-top: 0px;
    height: auto;
  }
`;
