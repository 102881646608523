import styled from 'styled-components';

export const ContainerFull = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  width: 100%;
  min-height: 110px;
  margin-top: auto;
  background: #ffffff;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1340px;
  height: 100%;
  font-size: 0.63rem;
  letter-spacing: 0.5px;

  &.column-sm {
    @media (max-width: 767px) {
      flex-direction: column;
      gap: 20px;
      padding-bottom: 20px;
    }
  }

  @media (max-width: 767px) {
    padding: 10px 0;
  }
`;

export const ContentLeft = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;

  @media (min-width: 768px) {
    align-items: center;
  }
`;

export const ContentRight = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 30px;
  height: 100%;

  @media (min-width: 768px) {
    justify-content: flex-end;
  }
`;

export const DivParagraph = styled.div`
  position: relative;
  color: #4a36ef;
  font-size: 14px;
  letter-spacing: 0;
  margin-top: -16px;

  p {
    margin: 0;
    padding: 0;
  }
`;

export const Button = styled.button`
  width: 50px;
  height: 50px;
  border: none;
  margin: 0;
  padding: 0;
  background: transparent;
  cursor: pointer;
  opacity: 1;
  transition: all 0.5s ease;

  &:hover {
    opacity: 0.9;
  }
`;

export const Img = styled.img`
  width: 50px;
  height: 50px;
`;
