/* eslint-disable */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CardSquared } from '..';
import Images from '../../mock-data/transformar-vida.json';
import { Section, Title, CardContainer, Container, ContainerFull } from './index.styled';

const TransformarVidaFinanceiraComponent = () => {
  const navigate = useNavigate();
  const openLink = link => navigate(`${link}`);

  const getImages = () => {
    const cards = Images.map(card => {
      return (
        <CardSquared
          key={`CardCircle-${card.image}`}
          image={card.image}
          description={card.description}
          descriptionHover={card.descriptionHover}
          handleClick={() => openLink(card.link)}
          bulletColor={card.bulletColor}
        />
      );
    });

    return cards;
  };

  return (
    <ContainerFull>
      <Container>
        <Section>
          <Title>A Yuno é <b>ideal para você,</b><br />
          <span>independente do seu contexto.</span></Title>
          <CardContainer>{getImages()}</CardContainer>
        </Section>
      </Container>
    </ContainerFull>
  );
};

export default TransformarVidaFinanceiraComponent;
