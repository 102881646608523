import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  gap: 10px;

  > div {
    flex: 0 0 45px;
    margin: 10px;
  }

  @media (min-width: 768px) {
    display: flex;
    padding: 0;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;

    max-width: 200px;
    min-height: 460px;
  }
`;
