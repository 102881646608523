import React from 'react';
import { useResize } from '../../hooks';
import PlayerComponent from './player';
import data from '../../mock-data/para-aprender.json';
import { Section, GalleryItem, Container } from './index.styled';

const VideoGalleryComponent = () => {
  const { width } = useResize();

  const getMarginTop = () => {
    if (width > 1200) {
      return -120;
    }
    return 0;
  };
  const renderItem = () => {
    const card = data.map((item, count) => {
      item = { ...item, count };
      return (
        <GalleryItem key={item.id + item.title}>
          <PlayerComponent data={item} />
        </GalleryItem>
      );
    });

    return card;
  };
  return (
    <Container
      distance
      style={{ background: 'transparent', marginTop: getMarginTop() }}
    >
      <Section>{renderItem()}</Section>
    </Container>
  );
};

export default VideoGalleryComponent;
