/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useLocation, useResolvedPath } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Header, Footer } from '../../components';
import { ContainerFluid } from '../../App.styled';

const Page = ({ children }) => {
  const location = useLocation();
  const { pathname } = useResolvedPath(location.pathname);
  const [ currentTheme, setCurrentTheme ] = useState("default");

  useEffect(() => {
    if(pathname.indexOf("para-voce/wealth") > -1) {
      setCurrentTheme("default");
    } else if(pathname.indexOf("para-voce/grow") > -1) {
      setCurrentTheme("lightBlue");
    } else if(pathname.indexOf("para-empresas") > -1) {
      setCurrentTheme("blackWhite");
    } else if(pathname.indexOf("para-voce") > -1) {
      setCurrentTheme("darkBlue");
    } else {
      setCurrentTheme("default");
    }

  }, [pathname])

  return (
    <>
      <Header themeType={currentTheme} />
      <ContainerFluid className="p-top">{children}</ContainerFluid>
      <Footer themeType={currentTheme} />
    </>
  );
};

Page.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Page;
