/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import {
  AnimatePresence,
  useViewportScroll,
  useTransform,
} from 'framer-motion';
import PropTypes from 'prop-types';
import { theme } from '../../App.styled';
import { useResize } from '../../hooks';
import { Logo, MenuHeader, MenuMobile } from '..';
import { ContainerFull, Container } from './index.styled';

const HeaderComponent = ({ themeType }) => {
  const [openMenu, setMenuOpen] = useState(false);
  const [typeLogo, setTypeLogo] = useState('white');
  const { isMobile } = useResize();
  const { pathname } = useLocation();

  const navigateToTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'auto',
    });
  };
  const { scrollYProgress } = useViewportScroll();
  const logoType = useTransform(scrollYProgress, [0, 0.27], [0, 1.3]);
  const headerOpacity = useTransform(
    scrollYProgress,
    [0.15, 0.23],
    [theme[themeType].background, theme[themeType].background]
  );

  useEffect(() => {
    logoType.onChange(v => {
      if (v >= 1) {
        setTypeLogo('color');
      } else if (v < 1) {
        setTypeLogo('white');
      }
    });
  }, [logoType]);

  useEffect(() => {
    if (pathname.length !== 1) {
      document.documentElement.style.setProperty('--scroll', 'auto');
      setTimeout(() => {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'auto',
        });
      }, 10);
      setTimeout(() => {
        document.documentElement.style.setProperty('--scroll', 'smooth');
      }, 500);
    } else {
      document.documentElement.style.setProperty('--scroll', 'auto');
      setTimeout(() => {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'auto',
        });
      }, 10);
      setTimeout(() => {
        document.documentElement.style.setProperty('--scroll', 'smooth');
      }, 500);
    }
  }, [pathname]);

  useEffect(() => {
    if (!isMobile) setMenuOpen(false);
  }, [isMobile]);
  return (
    <ThemeProvider theme={{ type: theme[themeType] }}>
      <ContainerFull color={typeLogo} style={{ background: headerOpacity }}>
        <AnimatePresence>
          {openMenu && (
            <MenuMobile closeMenuMobile={setMenuOpen} color={typeLogo} />
          )}
        </AnimatePresence>
        <Container>
          <Link to="/" onClick={navigateToTop}>
            <Logo type="white" className="pointer" />
          </Link>
          <MenuHeader background={theme[themeType].background} openMenuMobile={setMenuOpen} isOpen={openMenu} color={typeLogo} />
        </Container>
      </ContainerFull>
    </ThemeProvider>
  );
};

HeaderComponent.propTypes = {
  themeType: PropTypes.string,
};

HeaderComponent.defaultProps = {
  themeType: 'default',
};

export default HeaderComponent;
