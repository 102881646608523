/* eslint-disable */
import React from 'react';
import { BsChevronRight } from 'react-icons/bs';
import cardBlue from '../../mock-data/cards-blue.json';
import { ContainerFull,
          Container,
          Text,
          Title,
          Selo,
          Button,
          CardsContainer,
          Card } from './index.styled';
import { Selo10 } from '../../assets';

const MelhorInvestimentoComponent = () => {

  const gotoWhatsApp = (phoneNumber) => {
    const url = `https://wa.me/${phoneNumber}`;
    window.open(url, '_blank');
  }

  const getCards = () => {
    const cards = cardBlue.map(card => {
      return (
        <Card key={card.id}>
          <h2>{card.title}</h2>
          <p dangerouslySetInnerHTML={{ __html: card.description }} />
        </Card>
      )
    })

    return cards;
  }

  return (
    <ContainerFull className="melhor-investimento">
      <Selo src={Selo10} alt="Entre os 10 melhores consultorias independentes do Brasil" />
      <Container distance justifyCenter column>
          <Title initial={{ opacity: 0, y: 10 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{
              duration: 0.5,
              delay: 0.3,
            }}>Viver é o melhor investimento.</Title>
          <Text initial={{ opacity: 0, y: 10 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{
              duration: 0.5,
              delay: 0.3,
            }}>A <b>Yuno</b> ajuda pessoas em todo o país a <b>planejar e simplificar</b> suas complexas vidas financeiras, desvendando a essência do
          verdadeiro investimento: <b>viver plenamente</b>.</Text>
          <Button
            onClick={() => gotoWhatsApp(5562993376742)}
            initial={{ opacity: 0, y: 10 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{
              duration: 0.5,
              delay: 0.3,
            }}>Inicie agora seu Planejamento Financeiro <BsChevronRight size={24} /></Button>

          <CardsContainer initial={{ opacity: 0, y: 10 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{
              duration: 0.5,
              delay: 0.3,
            }}>
            {getCards()}
          </CardsContainer>
      </Container>

    </ContainerFull>
  );
};

export default MelhorInvestimentoComponent;
