import React from 'react';
import { Section, Container, Title } from './index.styled';

const DescompliqueFinancasComponent = () => {
  return (
    <Container distance>
      <Section>
        <Title
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{
            duration: 0.5,
            delay: 0.1,
          }}
        >
          Descomplique as suas <br />
          <span>Finanças</span>
        </Title>
      </Section>
    </Container>
  );
};

export default DescompliqueFinancasComponent;
