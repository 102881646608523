import React from 'react';
import { Container } from '../../assets/globalStyle';
import { Mandala } from '../../components';

function MandalaPage() {
  return (
    <Container column>
      <Mandala />
    </Container>
  );
}

export default MandalaPage;
