/* eslint-disable */
import React, { useRef, useEffect, useState } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import data from '../../mock-data/mapa.json';
import { ContainerFull,
        Section,
        Text,
        Container,
        Title,
        SubTitle,
        CardContainer,
        CardTextcontainer,
        CardItem,
        Divider,
        IndicatorContainer } from './index.styled';

gsap.registerPlugin( ScrollTrigger );
// gsap.defaults({ ease: 'none' });

const PlanejamentoFinanceiroPessoalComponent = () => {
  const elementRefs = useRef([]);
  const dividerRef = useRef(null);

  const [dividerHeight, setDividerHeight] = useState('50%');

  useEffect(() => {
    elementRefs.current = elementRefs.current.slice(0, data.length);

    elementRefs.current.forEach((ref, index) => {
      if (ref.current) {
        gsap.fromTo(
          ref.current,
          {
            opacity: 0.8,
            y: 0,
          },
          {
            opacity: 1,
            y: 0,
            scrollTrigger: {
              trigger: ref.current,
              start: 'top 55%',
              end: 'bottom 50%',
              toggleActions: 'play none none reverse',
              onEnter: () => {
                  ref.current.classList.add('active');
                updateDividerHeight(index);
              },
              onLeave: () => {
                // ref.current.classList.remove('active');
                updateDividerHeight(index - 1);
              },
              onEnterBack: () => {
                  ref.current.classList.add('active');
                updateDividerHeight(index);
              },
              onLeaveBack: () => {
                // ref.current.classList.remove('active');
                updateDividerHeight(index - 1);
              },
            },
          }
        );
      }
    });

    return () => {
      ScrollTrigger.getAll().forEach(instance => instance.kill());
    };

  }, [data.length]);

  const updateDividerHeight = (activeIndex) => {
    // console.log(activeIndex)
    const screenHeight = window.innerHeight;
    const halfScreenHeight = screenHeight / 2;

    // Se o índice ativo for menor que 0, significa que não há elementos com a classe active, então a div acompanha o meio da tela
    if (activeIndex < 0) {
      setDividerHeight(`${halfScreenHeight}px`);
    } else {
      // Se há elementos com a classe active, a div acompanha o meio da tela até que o último elemento active alcance o meio da tela
      const activeElement = elementRefs.current[activeIndex].current;
      const { top, bottom } = activeElement.getBoundingClientRect();

      if (top <= halfScreenHeight && bottom >= halfScreenHeight) {
        setDividerHeight(`${halfScreenHeight}px`);
      }
    }
  };

  const renderCards = () => {
    const cards = data.map((item, index) => {

      const cardRef = useRef(null);
      elementRefs.current[index] = cardRef;
      // id, indicator, title, text
      return (
        <CardItem ref={cardRef} key={item.id} className={index === 0 ? 'active' : ''}>
          <IndicatorContainer>
            <h3>{item.indicator}</h3>
          </IndicatorContainer>
          <CardTextcontainer>
            <h2>{item.title}</h2>
            <p>{item.text}</p>
          </CardTextcontainer>
        </CardItem>
      )
    })

    return cards;
  }

  return (
    <ContainerFull>
      <Divider ref={dividerRef} style={{ height: dividerHeight }} />
      <Section>
        <Title
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{
            duration: 0.5,
            delay: 0.1,
          }}
        >
          Como fazemos:
        </Title>
        <SubTitle
          initial={{ opacity: 0, y: 10 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{
            duration: 0.5,
            delay: 0.3,
          }}
        >
          Tudo começa com a criação<br className="d-none-sm" /> do seu próprio <b>MAPA!</b>
        </SubTitle>
        <Text
          initial={{ opacity: 0, y: 10 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{
            duration: 0.5,
            delay: 0.3,
          }}
        >
          Através dele, te conduzimos no sentido do seu <b>plano de vida.</b>
        </Text>
      </Section>
      <Container column>
        <CardContainer>
          {renderCards()}
        </CardContainer>
      </Container>
    </ContainerFull>
  );
};

export default PlanejamentoFinanceiroPessoalComponent;
