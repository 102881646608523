import React from 'react';
import { FiDownload } from 'react-icons/fi';
import { saveAs } from 'file-saver';
import gtag from 'ga-gtag';
import { getDocument } from '../../utils';
import Documents from '../../mock-data/documentos-regulatorios.json';
import { Section, Text, Container, Item, List } from './index.styled';

const DocumentosRegulatoriosComponent = () => {
  const saveFile = document => {
    gtag('event', 'download_regulatory_documents', {
      event_label: 'download_docs',
    });
    saveAs(getDocument(document), document);
  };

  return (
    <Container>
      <Section>
        <Text
          initial={{ opacity: 0, y: 10 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{
            duration: 0.5,
            delay: 0.5,
          }}
        >
          {`A JGV FINANÇAS PESSOAIS LTDA. é habilitada junto à Comissão de Valores Mobiliários ("CVM") para a atividade de consultoria de valores mobiliários prevista na Resolução CVM n.º 19, de 25 de fevereiro de 2021 ("RCVM 19").`}
        </Text>
        <List
          initial={{ opacity: 0, y: 10 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{
            duration: 0.5,
            delay: 0.3,
          }}
        >
          {Documents.map((document, index) => {
            return (
              <Item
                initial={{ opacity: 0, y: 10 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{
                  duration: 0.5,
                  delay: 0.1 * index,
                }}
                key={document.id}
                onClick={() => saveFile(document.link)}
              >
                {document.text}
                <FiDownload />
              </Item>
            );
          })}
        </List>
      </Section>
    </Container>
  );
};

export default DocumentosRegulatoriosComponent;
