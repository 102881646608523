/* eslint-disable */
import styled from 'styled-components';
import { motion } from 'framer-motion';

export const ContainerFull = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 80px;
  margin-top: auto;
  transition: all 0.3s ease-in;
  background: ${props => props.theme.type.background};
  cursor: pointer;

  &:hover {
    background: ${props => props.theme.type.color};

    span {
      color: ${props => props.theme.type.background} !important;
    }
  }

  &.bordered {
    border-top: solid 10px ${props => props.theme.type.color};
  }

  @media (max-width: 767px) {
    padding: 0 20px;
  }

  @media (min-width: 768px) {
    height: 80px;
  }
`;

export const Button = styled(motion.div)`
  position: relative;
  opacity: 1;
  padding-right: 40px;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 1px;
  font-size: 16px;
  font-weight: 400;
  color: ${props => props.theme.type.color};
  cursor: pointer;

  @media (max-width: 767px) {
    text-align: center;
  }

  span {
    transition: all 0.4s ease-out 0.1s;
    font-weight: 500;
  }
`;

export const Icone = styled.span`
  position: absolute;
  top: 50%;
  right: -10px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 30px;

  svg {
    width: 70%;
    height: auto;
    color: inherit;
  }
`;
