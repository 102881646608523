import React from 'react';
import { Politica } from '../../components';
import { ContainerFluid } from './index.styled';

const PoliticaFamilyOfficePage = () => {
  return (
    <ContainerFluid>
      <Politica />
    </ContainerFluid>
  );
};

export default PoliticaFamilyOfficePage;
