/* eslint-disable */
import React from 'react';
import { Container, ContainerFull } from '../../assets/globalStyle';
import {
  HeaderSectionImageText,
  DivisorButton,
  ContentTextMenuParaEmpresas,
  TransformarVidaFinanceira
} from '../../components';

function ParaEmpresasPage() {

  const gotoWhatsApp = (phoneNumber) => {
    const url = `https://wa.me/${phoneNumber}`;
    window.open(url, '_blank');
  }

  return (
    <React.Fragment>
      <ContainerFull>
        <HeaderSectionImageText
          themeType="blackWhite"
          image="ImageParaEmpresa"
          title="Yuno Work"
          text="Para pequenas empresas que buscam um crescimento seguro por meio de uma gestão financeira eficaz e focada em resultados." />
      </ContainerFull>
      <ContainerFull>
        <ContentTextMenuParaEmpresas />
      </ContainerFull>
      <ContainerFull>
        <DivisorButton handleClick={() => gotoWhatsApp(5562993376742)} bordered title="Inicie agora seu Planejamento Financeiro" themeType="whiteBlack" />
      </ContainerFull>
      <ContainerFull>
        <TransformarVidaFinanceira />
      </ContainerFull>
    </React.Fragment>
  );
}

export default ParaEmpresasPage;
