/* eslint-disable */
import React from 'react';
import { SplitMenu } from '..'
import PropTypes from 'prop-types';
import { ContainerFull,
          Container,
          TextContainer,
          Text,
          Section,
          SplitContainer,
          Title } from './index.styled';

const ContentTextMenuParaEmpresasComponent = ({ style, className }) => {

  return (
      <ContainerFull style={style} className={className}>
        <Container>
          <TextContainer>
            <Title
              initial={{ opacity: 0, y: 10 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{
                duration: 0.5,
                delay: 0.3,
              }}>
                Aceleramos os resultados da sua empresa!
            </Title>
            <Text
              style={{marginTop: '1.5rem'}}
              initial={{ opacity: 0, y: 10 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{
                duration: 0.5,
                delay: 0.3,
              }}>
                A partir de um planejamento estratégico, contamos com Consultores de ampla experiência que ajudam e ensinam a projetar metas, encontrar o custo do seu produto e planejar o <b>crescimento do seu negócio.</b>
            </Text>
            <Text
              style={{marginTop: '0.5rem'}}
              initial={{ opacity: 0, y: 10 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{
                duration: 0.5,
                delay: 0.3,
              }}>
                A Gestão Financeira da sua empresa é simplificada e se torna muito mais objetiva através da ferramenta/plataforma <b>Work BI</b>. Com ela é possível analisar e gerir todas as entradas, saídas, margens de lucro além de contas a pagar e receber.
            </Text>
            <Text
              style={{marginTop: '0.5rem'}}
              initial={{ opacity: 0, y: 10 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{
                duration: 0.5,
                delay: 0.3,
              }}>
                Mensalmente, o seu time terá <b>orientação</b> estratégica para as tomadas de decisão com foco na otimização dos resultados, respeitando e compreendendo a complexidade da empresa, suas delimitações, oportunidades e todas as variáveis relevantes.
            </Text>
          </TextContainer>
          <Section initial={{ opacity: 0, y: 10 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{
                duration: 0.5,
                delay: 0.3,
              }}>
            <Title style={{marginBottom: '1.5rem'}}><span>Nossas</span> Soluções</Title>
            <SplitContainer>
              <SplitMenu type="work" />
            </SplitContainer>
          </Section>
        </Container>
      </ContainerFull>
  );
};

ContentTextMenuParaEmpresasComponent.propTypes = {
  className: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.shape]),
};

ContentTextMenuParaEmpresasComponent.defaultProps = {
  className: '',
  style: {},
};

export default ContentTextMenuParaEmpresasComponent;
