/* eslint-disable */
import React from 'react';
import { Container, ContainerFull } from '../../assets/globalStyle';
import {
  HeaderSectionImageText,
  DivisorButton,
  ContentTextMenuGrow,
  TransformarVidaFinanceira
} from '../../components';


function ParaVoceGrowPage() {

  const gotoWhatsApp = (phoneNumber) => {
    const url = `https://wa.me/${phoneNumber}`;
    window.open(url, '_blank');
  }

  return (
    <React.Fragment>
      <ContainerFull>
        <HeaderSectionImageText
          themeType="lightBlue"
          image="ImageParaVoceGrow"
          title="Yuno Grow"
          text="Para você que deseja <b>formar patrimônio</b> a partir de uma gestão orçamentária estratégica." />
      </ContainerFull>
      <ContainerFull>
        <ContentTextMenuGrow />
      </ContainerFull>
      <ContainerFull>
        <DivisorButton handleClick={() => gotoWhatsApp(5562993376742)} bordered title="Inicie agora seu Planejamento Financeiro" themeType="lightBlue2" />
      </ContainerFull>
      <ContainerFull>
        <TransformarVidaFinanceira />
      </ContainerFull>
    </React.Fragment>
  );
}

export default ParaVoceGrowPage;
