import styled from 'styled-components';

export const ContainerFull = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  background: #f7f7f7;
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${p => (p.column ? 'column' : 'row')};
  align-items: center;
  justify-content: ${p => (p.justifyCenter ? 'center' : 'space-between')};
  width: 100%;
  max-width: 1340px;
  margin: ${p => (p.margin ? p.margin : '0 auto')};
  height: 100%;

  @media (max-width: 768px) {
    padding: 20px;
    align-items: left;
  }
`;

export const Title = styled.h5`
  font-weight: 400;
  margin-bottom: 1.5rem;
  font-size: 24px;
  line-height: 2rem;

  span {
    font-weight: 100;
    font-size: 16px;
  }

  @media (max-width: 767px) {
    span {
      font-weight: 400;
    }
  }

  @media (min-width: 768px) {
    font-size: 37px;
    line-height: 3rem;
    span {
      font-size: 23px;
    }
  }
`;

export const Text = styled.p`
  font-size: 24px;
  line-height: 32px;
  color: #212121;
  font-weight: 600;
  text-align: right;
  max-width: 480px;
`;

export const Section = styled.section`
  width: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px 0;
  height: auto;
  min-height: 650px;
`;

export const CardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
  height: auto;
  min-height: 300px;

  @media (max-width: 767px) {
    justify-content: center;
  }
`;
