import styled from 'styled-components';
import { LogoColor, LogoWhite } from '../../assets';

export const Container = styled.div`
  width: 110px;
  height: 62px;
  background: url(${p => (p.type === 'white' ? LogoWhite : LogoColor)});
  background-repeat: no-repeat;
  background-size: contain;
  transform: scale(1.04);

  &.pointer {
    cursor: pointer;
  }

  &.bigger {
    width: 110px;
    height: 62px;

    @media (min-width: 768px) {
      width: 110px;
      height: 62px;
    }
  }
`;
