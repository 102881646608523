import styled from 'styled-components';

export const CardWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: ${p => `${p.size}px`};
  height: 366px;
  min-width: ${p => `${p.size}px`};
  min-height: 366px;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  background: #140361;
  box-shadow: 5px 7px 15px 0px rgba(0, 0, 0, 0.25);

  @media (max-width: 767px) {
    width: 100%;
    max-width: 360px;
  }

  a {
    -webkit-tap-highlight-color: transparent !important;
  }
`;

export const FrontCard = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  border-radius: 10px;
  overflow: hidden;
  padding: 20px;
`;

export const Description = styled.p`
  position: absolute;
  top: calc(50% + 24px);
  width: calc(100% - 40px);
  text-align: left;
  color: #ffffff;
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
  z-index: 1;
  transition: 0.25s ease-in-out all;

  &.h-description {
    opacity: 0;
    transform-origin: center;
    transform: scale(0.9);
  }
`;

export const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(${p => p.url});
  background-size: cover;
  z-index: 0;
  transition: 0.25s ease-in-out all;
  transform: scale(1);
  overflow: hidden;
  filter: brightness(90%);
`;

export const SaibaMais = styled.div`
  position: relative;
  width: 120px;
  opacity: 1;
  display: inline-flex;
  align-items: center;
  padding-right: 30px;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 1px;
  font-size: 14px;
  color: #ffffff;
  transition: 0.25s ease-in-out all;
  transform-origin: center;
  transform: scale(1);
  cursor: pointer;

  &:hover {
    span {
      width: 144px;
    }
  }
`;

export const Icone = styled.span`
  position: absolute;
  top: 50%;
  right: -10px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 30px;
  height: 30px;
  background: ${p => p.bulletColor};
  border-radius: 30px;
  padding-right: 4px;
  z-index: -1;
  transition: 0.25s ease-in-out all;

  svg {
    width: 21px;
    height: auto;
    color: ${p => (p.color ? p.color : '#ffffff')}
    box-shadow: 0px 0px 17px -7px rgba(0, 0, 0, 0.75);
  }
`;
