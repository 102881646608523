/* eslint-disable */
import React from 'react';
import { BsChevronRight } from 'react-icons/bs';
import { ThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import { theme } from '../../App.styled';
import { ContainerFull, Button, Icone } from './index.styled';

const DivisorButtonComponent = ({ style, className, title, themeType, bordered, handleClick }) => {
  return (
    <ThemeProvider theme={{ type: theme[themeType] }}>
      <ContainerFull onClick={handleClick}  style={style} className={`${bordered ? 'bordered' : ''} ${className}`}>
        <Button className="h-button" initial={{ opacity: 0, y: 10 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{
            duration: 0.5,
            ease: 'easeIn'
          }}>
          <span>{title}</span>
          <Icone color="#FFFFFF">
            {React.createElement(BsChevronRight)}
            </Icone>
        </Button>
      </ContainerFull>
    </ThemeProvider>
  );
};

DivisorButtonComponent.propTypes = {
  themeType: PropTypes.string,
  bordered: PropTypes.bool,
  title: PropTypes.string,
  className: PropTypes.string,
  handleClick: PropTypes.func,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.shape]),
};

DivisorButtonComponent.defaultProps = {
  title: 'My button',
  handleClick: () => {},
  themeType: 'default',
  bordered: false,
  className: '',
  style: {},
};

export default DivisorButtonComponent;
