import React from 'react';
import PropTypes from 'prop-types';
import { Anchor } from './index.styled';

const AnchorComponent = ({ style, className }) => {
  return <Anchor className={`anchor ${className}`} style={style} />;
};

AnchorComponent.propTypes = {
  className: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.shape]),
};

AnchorComponent.defaultProps = {
  className: '',
  style: {},
};

export default AnchorComponent;
