import styled from 'styled-components';

export const CardWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: ${p => `${p.size}px`};
  height: ${p => `${p.size}px`};
  min-width: ${p => `${p.size}px`};
  min-height: ${p => `${p.size}px`};
  border-radius: ${p => `${Number(p.size) / 2}px`};
  overflow: hidden;
  cursor: pointer;

  a {
    -webkit-tap-highlight-color: transparent !important;
  }

  &.hovered {
    .background {
      transform: scale(1.05);
      filter: brightness(70%);
    }
  }
`;

export const FrontCard = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: ${p => `${Number(p.size) / 2}px`};
  overflow: hidden;
`;

export const HoverCard = styled.div`
  position: absolute;
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: ${p => `${Number(p.size) / 2}px`};
  background-color: var(--color-primary-medium);
  z-index: 1;
  transition: all 0.5s ease;
  overflow: hidden;

  &.hovered {
    transition: all 0.3s 0.1s ease;
    opacity: 1;

    .h-title {
      transition: all 0.3s 0.2s ease;
      transform: scale(1);
      opacity: 1;
    }
    .h-description {
      transition: all 0.4s 0.25s ease;
      transform: scale(1);
      opacity: 1;
    }
    .h-button {
      transition: all 0.5s 0.3s ease;
      transform: scale(1);
      opacity: 1;
    }
  }

  /*&:hover {
    opacity: 1;
  }*/
`;

export const Title = styled.h2`
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-size: 48px;
  color: #ffffff;
  margin: 0;
  text-align: center;
  z-index: 1;
`;

export const HoverTitle = styled.h2`
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  opacity: 0;
  font-weight: 700;
  font-size: 24px;
  color: #ffffff;
  margin: 0;
  text-align: center;
  z-index: 1;
  transform-origin: center;
  transform: scale(0.9);
  transition: 0.25s ease-in-out all;
`;

export const Description = styled.p`
  text-align: center;
  color: #ffffff;
  font-size: 16px;
  font-weight: 100;
  line-height: 26px;
  z-index: 1;
  transition: 0.25s ease-in-out all;

  &.h-description {
    opacity: 0;
    transform-origin: center;
    transform: scale(0.9);
  }
`;

export const Background = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: ${p => `${Number(p.size) / 2}px`};
  background: url(${p => p.url});
  background-size: cover;
  z-index: 0;
  transition: 0.25s ease-in-out all;
  transform: scale(1);
  overflow: hidden;
  filter: brightness(90%);
`;

export const SaibaMais = styled.div`
  position: relative;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 30px;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  text-decoration: underline;
  letter-spacing: 1px;
  font-size: 14px;
  color: #ffffff;
  transition: 0.25s ease-in-out all;
  transform-origin: center;
  transform: scale(0.9);
`;

export const Icone = styled.span`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 100%;
    height: auto;
    color: ${p => (p.color ? p.color : '#ffffff')}
    box-shadow: 0px 0px 17px -7px rgba(0, 0, 0, 0.75);
  }
`;
