import React from 'react';
import gtag from 'ga-gtag';
import { useNavigate } from 'react-router-dom';
import { Container } from '../../assets/globalStyle';
import { ContainerFluid, Row, Img, Title, Text } from './index.styled';
import Logo from './assets/logo-header.png';
import AnimacaoDireita from './assets/$yh72ew7usp.gif';

function EscolhasViverOuInvestirCampanhaSucesso() {
  const navigate = useNavigate();
  console.log('teste');
  gtag('event', 'registration_landing', {
    event_label: 'form',
  });
  setTimeout(() => {
    navigate('../../', { replace: true });
  }, 4000);
  return (
    <>
      <ContainerFluid className="container-fluid purple">
        <Container style={{ minHeight: 'inherit' }}>
          <Row className="row no-gutters align-items-center">
            <div className="col-12 col-md-6">
              <Img className="img-logo" src={Logo} alt="Yuno" />
              <Title className="mb-30">
                Parabéns, você está mais próximo de um futuro melhor!
              </Title>
              <Text className="mb-30">
                Em breve nossa equipe entrará em contato.
              </Text>
            </div>
            <div className="col-12 col-md-6 align-self-end">
              <Img className="anim-dir" src={AnimacaoDireita} alt="Yuno" />
            </div>
          </Row>
        </Container>
      </ContainerFluid>
    </>
  );
}

export default EscolhasViverOuInvestirCampanhaSucesso;
