import React from 'react';
import PropTypes from 'prop-types';
import { useResize } from '../../hooks';
import { CardWrapper, Title, Text } from './index.styled';

const CardWhiteComponent = ({
  title,
  text,
  style,
  left,
  top,
  className,
  pinDirection,
  delay,
}) => {
  const { isMobile } = useResize();
  const bounceTransition = {
    y: {
      duration: 3,
      repeat: Infinity,
      repeatType: 'reverse',
      ease: 'easeInOut',
      delay,
    },
  };
  return (
    <CardWrapper
      left={left}
      top={top}
      initial={{ opacity: 0, y: 16 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={!isMobile ? bounceTransition : null}
      animate={{
        y: !isMobile ? ['-15%', '15%'] : 0,
      }}
      className={`${className}`}
      style={style}
    >
      <Title>{title}</Title>
      <Text>{text}</Text>
      {pinDirection === 't' && <div className="arrow-up" />}
      {pinDirection === 'b' && <div className="arrow-down" />}
      {pinDirection === 'l' && <div className="arrow-left" />}
      {pinDirection === 'r' && <div className="arrow-right" />}
    </CardWrapper>
  );
};

CardWhiteComponent.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  left: PropTypes.number,
  top: PropTypes.number,
  delay: PropTypes.number,
  pinDirection: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.shape]),
};

CardWhiteComponent.defaultProps = {
  title: '',
  text: '',
  delay: 0,
  left: 0,
  top: 0,
  className: '',
  pinDirection: '',
  style: {},
};

export default CardWhiteComponent;
