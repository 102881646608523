import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { gsap } from 'gsap';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { MotionPathHelper } from 'gsap/MotionPathHelper';

import { useResize } from '../../hooks';
import { Container, ImgPath, Seta } from './index.styled';

gsap.registerPlugin(MotionPathHelper, MotionPathPlugin, ScrollTrigger);
gsap.defaults({ ease: 'none' });

const AnimatePathComponent = ({ style }) => {
  const { bsBreakPoint } = useResize();

  const seta = useRef(null);

  const canRender = () => {
    if (bsBreakPoint !== 'xs' && bsBreakPoint !== 'sm') {
      if (bsBreakPoint === 'md') {
        return {
          transform: 'translate(-40px, 80px) scale(.7)',
        };
      }
      if (bsBreakPoint === 'lg') {
        return {
          transform: 'translate(30px, 80px) scale(.7)',
        };
      }
      if (bsBreakPoint === 'xl') {
        return {
          transform: 'translate(30px, 40px) scale(.9)',
        };
      }
      return { transform: 'translate(30px, -40px) scale(1)' };
    }
    return { display: 'none' };
  };

  useEffect(() => {
    gsap.set(seta.current, {
      xPercent: 0,
      yPercent: 0,
      transformOrigin: '50% 50%',
    });
    gsap
      .timeline({
        scrollTrigger: {
          trigger: '.init-path',
          start: 'top top',
          end: '+=820',
          pin: true,
          scrub: 1,
        },
      })
      .to(seta.current, {
        duration: 20,
        ease: 'linear',
        motionPath: {
          path:
            'M460.1,312.699 C214.699,218.298 142.072,273.204 106,319.298 58.819,379.587 93.745,376.107 31.899,544.697 17.731,583.318 -34.784,657.755 -121.5,709.9 -192.991,752.89 -253.201,803.652 -292,836 -406.687,931.617 -303.824,1040.942 -183.5,1078.9 -141.976,1091.999 -117.354,1119.207 -70.262,1138.435 36.68,1182.098 13.951,1246.229 30.976,1313.235 54.646,1406.395 181.17,1445.093 263.173,1392.358 339.358,1343.364 550.151,1445.792 529.101,1611.697 ',
          autoRotate: true,
        },
      });

    return () => {
      // ScrollTrigger.getAll().forEach(t => t.kill()); // no error but media query events still fire after unmount
      // ScrollTrigger.kill(); // error, resize mq events still fire on unmount
    };
    // MotionPathHelper.create(seta.current);
  }, []);

  return (
    <>
      <Container style={(style, canRender())}>
        <Seta ref={seta} />
        <ImgPath />
        {/* <p style={{ color: 'red' }}>{bsBreakPoint}</p> */}
      </Container>
    </>
  );
};

AnimatePathComponent.propTypes = {
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.shape]),
};

AnimatePathComponent.defaultProps = {
  style: {},
};

export default AnimatePathComponent;
