import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${p => (p.column ? 'column' : 'row')};
  align-items: flex-start;
  justify-content: ${p => (p.justifyCenter ? 'center' : 'space-between')};
  width: 100%;
  max-width: 1340px;
  margin: ${p => (p.margin ? p.margin : '0 auto')};
  min-height: 420px;

  @media (min-width: 768px) {
    align-items: center;
    height: auto;
  }

  @media (min-width: 992px) {
    height: auto;
    /* border: solid 1px red; */
  }

  @media (min-width: 1200px) {
    height: auto;
    /* border: solid 1px orange; */
  }

  @media (min-width: 1400px) {
    height: auto;
    /* border: solid 1px green; */
  }
`;

export const GroupLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: auto;

  @media (min-width: 768px) {
    width: 50%;
    height: 100%;
  }
`;

export const GroupRight = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;

  @media (min-width: 768px) {
    width: 50%;
    padding-left: 20px;
  }
`;

export const Title = styled(motion.h2)`
  font-size: 20px;
  line-height: 30px;
  color: #212121;
  font-weight: 700;
  text-align: left;
  margin-top: 0;
  margin-bottom: 30px;
  max-width: calc(100vw - 20px);
  z-index: 1;

  span {
    text-decoration: underline;
  }

  @media (min-width: 768px) {
    font-size: 40px;
    line-height: 50px;
    margin-top: 0;
    margin-bottom: 20px;
    max-width: 570px;
  }
`;

export const Text = styled(motion.h3)`
  font-size: 16px;
  line-height: 26px;
  color: #212121;
  text-align: left;
  font-weight: 500;
  margin-bottom: 40px;
  max-width: 500px;
  z-index: 1;

  @media (min-width: 768px) {
    font-size: 24px;
    max-width: 100%;
    line-height: 38px;
  }
`;

export const Section = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  width: inherit;
  height: 400px;
  margin-top: 20px;
  padding: 0 20px;

  @media (min-width: 768px) and (max-width: 991px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    flex-wrap: wrap;
    margin-top: 100px;
    height: auto;
    padding: 0 40px;

    > div {
      width: 100% !important;
      padding-left: 0 !important;
    }
  }

  @media (min-width: 992px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    margin-top: 180px;
    height: 410px;
    padding: 0 40px;
    padding: var(--border-distance);
  }
`;
