import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  min-height: 460px;
  padding: 32px;
  background: #ffffff;
  box-shadow: 0px 8px 24px rgba(68, 70, 117, 0.15);
  border-radius: 16px;

  @media (min-width: 768px) {
    width: 470px;
  }
`;

export const Title = styled.h5`
  letter-spacing: 0.6px;
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  width: 100%;
`;

export const Input = styled.input`
  display: flex;
  width: 100%;
  background: #ffffff;
  border: 1px solid #d6d9e0;
  padding: 12px 14px 12px 16px;
  border-radius: 8px;

  &:not(:first-child) {
    margin-top: 20px;
  }
`;

export const Textarea = styled.textarea`
  display: flex;
  width: 100%;
  height: 72px;
  background: #ffffff;
  border: 1px solid #d6d9e0;
  padding: 12px 14px 12px 16px;
  border-radius: 8px;
  resize: none;

  &:not(:first-child) {
    margin-top: 20px;
  }
`;
