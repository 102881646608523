/* eslint-disable */
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { BGParaVoce, BGParaVoceMobile } from '../../assets';

export const ContainerFull = styled.div`
  width: 100%;

  @media (min-width: 768px) {
    background: url(${BGParaVoce}), #0c053f;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 1904px;
  }

  @media (max-width: 767px) {
    background: url(${BGParaVoceMobile}), #0c053f;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 1052px;
    max-height: 1052px;
  }


`;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${p => (p.column ? 'column' : 'row')};
  align-items: flex-start;
  justify-content: ${p => (p.justifyCenter ? 'center' : 'space-between')};
  width: 100%;
  max-width: 1340px;
  margin: ${p => (p.margin ? p.margin : '0 auto')};
  padding: 20px;
  min-height: 500px;

  @media (min-width: 768px) {
    align-items: center;
    height: auto;
  }

  @media (min-width: 992px) {
    height: auto;
    /* border: solid 1px red; */
  }

  @media (min-width: 1200px) {
    height: auto;
    /* border: solid 1px orange; */
  }

  @media (min-width: 1400px) {
    height: auto;
    /* border: solid 1px green; */
  }
`;

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  width: inherit;

  @media (min-width: 768px) {
    height: 360px;
  }
`;

export const Title = styled(motion.h2)`
  font-size: 24px;
  color: #000000;
  font-weight: 500;
  margin-top: 100px;
  margin-bottom: 20px;

  @media (max-width: 767px) {
    padding: 0 40px;
    margin-left: 0px;
  }

  @media (min-width: 768px) {
    font-size: 24px;
    margin-top: 72px;
    margin-bottom: 0px;
    margin-left: 100px;
  }
`;

export const SubTitle = styled(motion.h3)`
  font-size: 30px;
  line-height: 38px;
  color: #000000;
  font-weight: 400;

  @media (max-width: 767px) {
    padding: 0 40px;
    margin-left: 0px;
  }

  @media (min-width: 768px) {
    font-size: 36px;
    line-height: 50px;
    font-weight: 200;
    margin-left: 100px;
    margin-top: 20px;
  }
`;

export const Text = styled(motion.p)`
  font-size: 18px;
  line-height: 26px;
  color: #000000;
  font-weight: 400;
  margin-bottom: 40px;
  margin-left: 10px;
  margin-top: 26px;

  @media (max-width: 767px) {
    padding: 0 40px;
    margin-left: 0px;
  }

  @media (min-width: 768px) {
    font-size: 22px;
    line-height: 38px;
    margin-left: 100px;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media (min-width: 768px) {
    gap: 100px;
  }
`;

export const CardItem = styled(motion.div)`
  display: flex;
  display: flex;
  border-radius: 8px;
  width: 100%;
  height: 140px;
  padding: 0px 10px;
  align-items: center;

  @media (min-width: 768px) {
    width: 770px;
    height: 270px;
    padding: 0px 10px;
    border-radius: 20px;
  }

  background: rgba(247, 247, 247, 0.10);
  box-shadow: 0px 7px 7px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(7.5px);
  transition: background-color 0.2s ease;

  &.active {
    background: rgba(12, 5, 63, 0.80);

    h2, h3, p {
      color: #ffffff;
    }
  }
`;

export const IndicatorContainer = styled.div`
  display: flex;
  width: 50px;
  margin: 30px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  flex-shrink: 0;
  border-right: 1px solid rgba(255, 255, 255, 0.50);

  @media (min-width: 768px) {
    width: 125px;
    margin: 30px 0px;
  }

  h3 {
    color: rgba(255, 255, 255, 0.50);
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 800;
    line-height: 130%;
    margin: 0;

    @media (min-width: 768px) {
      font-size: 70px;
      line-height: 130%;
    }
  }
`;

export const CardTextcontainer = styled.div`
  display: flex;
  padding: 20px 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 6px;

  @media (min-width: 768px) {
    padding: 30px 30px;
    gap: 30px;
  }

  h2 {
    color: rgba(247, 247, 247, 0.50);
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    margin: 0px;

    @media (min-width: 768px) {
      font-size: 40px;
      line-height: 130%;
    }
  }

  p {
    color: rgba(255, 255, 255, 0.50);
    font-size: 10px;
    font-style: normal;
    font-weight: 200;
    line-height: 130%;
    text-align: justify;
    text-justify: inter-word;

    @media (min-width: 768px) {
      font-size: 23px;
      line-height: 130%;
    }
  }
`;

export const Divider = styled.div`
  position: fixed;
  width: 10px;
  height: 10px;
  /* background: red; */
  z-index: 1;
`;
