/* eslint-disable */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import menu from '../../mock-data/menu.json';
import { useResize } from '../../hooks';
import { ButtonColor } from '..';
import {
  Container,
  Nav,
  Menu,
  Button,
  ButtonOutside,
  Hamburguer,
  SubMenu,
  SubButton,
} from './index.styled';

const MenuHeaderComponent = ({ openMenuMobile, color, background, isOpen }) => {
  const { bsBreakPoint } = useResize();
  const [isMobile, setIsMobile] = useState(false);
  const [showSubParaVoce, setShowSubParaVoce] = useState(false);

  useEffect(() => {
    if (
      bsBreakPoint === 'xs' ||
      bsBreakPoint === 'sm' ||
      bsBreakPoint === 'md'
    ) {
      setIsMobile(true);
      return;
    }
    setIsMobile(false);
  }, [bsBreakPoint]);

  const openMobile = () => {
    openMenuMobile(!isOpen);
  };

  const handlerAcessar = () => {
    // window.open('http://www.realeasy.com.br/home', '_blank', 'noopener,noreferrer');
  };

  const handleMouseEnter = (evt) => {
    const dataTargetValue = evt.currentTarget.dataset.target;
    if(dataTargetValue !== "/para-voce") {
      setShowSubParaVoce(false);
      return;
    } else {
      setShowSubParaVoce(true);
    }
  }

  const handleMouseLeave = (evt) => {
    const dataTargetValue = evt.currentTarget.dataset.target;
    if(dataTargetValue !== "/para-voce") {
      return;
    } else {
      setShowSubParaVoce(false);
    }
  }

  return (
    <Container height={isMobile ? 'auto': '100%'}>
      {!isMobile && (
        <Nav>
          <Menu>
            {menu.map(button => {
              return (
                <Button
                  data-target={button.to}
                  onMouseOver={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  key={button.title}
                  color={color}>
                  <NavLink to={`${button.to}`}>{button.title}</NavLink>
                  {button.to === "/para-voce" && showSubParaVoce &&
                    <SubMenu color={color} background={background}>
                      <SubButton>
                        <NavLink to="/para-voce/grow">
                          Yuno Grow<br />
                          <span>para você crescer</span>
                        </NavLink>
                      </SubButton>
                      <SubButton>
                       <NavLink to="/para-voce/wealth">
                        Yuno Wealth<br />
                        <span>para crescer seu patrimônio</span>
                        </NavLink>
                      </SubButton>
                      <SubButton>
                        <NavLink to="/para-voce/family-office">
                          Yuno Family Office<br />
                          <span>para gerir seu patrimônio</span>
                        </NavLink>
                      </SubButton>

                    </SubMenu>
                  }
                </Button>
              );
            })}
            {false && (
              <ButtonOutside>
                <ButtonColor handleClick={handlerAcessar}>Acessar</ButtonColor>
              </ButtonOutside>
            )}
          </Menu>
        </Nav>
      )}
      {isMobile && (
        <Hamburguer onClick={openMobile}>
          <span />
          <span className={isOpen ? 'active' : ''} />
          <span />
        </Hamburguer>
      )}
    </Container>
  );
};

MenuHeaderComponent.propTypes = {
  openMenuMobile: PropTypes.func,
  color: PropTypes.string,
  background: PropTypes.string,
  isOpen: PropTypes.bool,
};

MenuHeaderComponent.defaultProps = {
  openMenuMobile: () => {},
  color: 'white',
  background: '#140361',
  isOpen: false
};

export default MenuHeaderComponent;
