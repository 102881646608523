import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${p => (p.column ? 'column' : 'row')};
  align-items: flex-start;
  justify-content: ${p => (p.justifyCenter ? 'center' : 'space-between')};
  width: 100%;
  max-width: 1340px;
  margin: ${p => (p.margin ? p.margin : '0 auto')};
  padding: 0 20px 20px 20px;

  @media (min-width: 768px) {
    min-height: 500px;
    align-items: center;
    height: auto;
  }

  @media (min-width: 992px) {
    height: auto;
    /* border: solid 1px red; */
  }

  @media (min-width: 1200px) {
    height: auto;
    /* border: solid 1px orange; */
  }

  @media (min-width: 1400px) {
    height: auto;
    /* border: solid 1px green; */
  }
`;

export const Title = styled(motion.h2)`
  font-size: 36px;
  line-height: 44px;
  color: #ffffff;
  font-weight: 700;
  margin-top: 0px;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    font-size: 56px;
    line-height: 70px;
    margin-top: 89px;
    margin-bottom: 26px;
  }
`;

export const SubtTitle = styled(motion.h2)`
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
  font-weight: 700;
  text-align: center;
  margin-top: 50px;
  margin-bottom: 26px;
  max-width: calc(100vw - 20px);

  @media (min-width: 768px) {
    font-size: 32px;
    line-height: 50px;
    margin-top: 18px;
    margin-bottom: 26px;
  }
`;

export const Text = styled(motion.h3)`
  font-size: 16px;
  line-height: 26px;
  color: #ffffff;
  text-align: center;
  font-weight: 500;
  margin-bottom: 40px;
  max-width: calc(100vw - 20px);

  @media (min-width: 768px) {
    max-width: 632px;
    font-size: 24px;
    line-height: 38px;
  }
`;

export const Gif = styled(motion.img)`
  width: 320px;
  height: auto;
  max-width: calc(100% - 10px);

  @media (min-width: 768px) {
    width: auto;
    height: 48px;
  }
`;

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: inherit;
  padding-top: 20px;
  height: auto;
  // border-bottom: solid 1px red;

  @media (min-width: 768px) {
    height: 520px;
  }
`;
