import styled from 'styled-components';

export const ContainerFluid = styled.section`
  min-height: 500px;

  &.purple {
    background: #7386ff;
  }

  &.blue {
    background: #4739eb;
  }

  &.short {
    min-height: 420px;
  }

  &.h-auto {
    min-height: auto;
  }

  .h-100 {
    height: 100%;
  }
`;

export const Row = styled.div`
  width: 100%;
  min-height: inherit;
  overflow: hidden;
  padding: 10px;

  &.no-hidden {
    overflow: initial;
  }

  @media (min-width: 768px) {
    padding: var(--border-distance);
  }
`;

export const Img = styled.img`
  width: 100%;
  height: auto;

  &.anim-dir {
    width: 100%;
    max-width: 550px;
    margin-bottom: -40px;
  }

  &.img-logo {
    width: 216px;
    height: auto;
    margin-bottom: 20px;
    @media (min-width: 768px) {
      margin-bottom: 50px;
    }
  }
`;

export const Title = styled.h1`
  color: #ffffff;
  font-size: 24px;
  letter-spacing: 0.2px;
  line-height: 32px;
  max-width: 400px;

  &.w-100 {
    width: 100%;
    max-width: 100%;
  }

  @media (min-width: 768px) {
    font-size: 36px;
    letter-spacing: 0.2px;
    line-height: 48px;
  }

  &.text-center {
    text-align: center;
    margin: 0 auto;
  }

  &.mb-30 {
    margin-bottom: 30px;
  }

  &.bigger {
    font-size: 20px;
    line-height: 32px;
    @media (min-width: 768px) {
      font-size: 40px;
      letter-spacing: 0.2px;
      line-height: 54px;
    }
  }
`;

export const SubTitle = styled.h2`
  color: #ffffff;
  font-size: 20px;
  letter-spacing: 0.2px;
  line-height: 32px;
  max-width: 480px;

  @media (min-width: 768px) {
    font-size: 32px;
    letter-spacing: 0.2px;
    line-height: 46px;
  }

  &.smaller {
    @media (min-width: 768px) {
      font-size: 24px;
      letter-spacing: 0.2px;
      line-height: 36px;
    }
  }

  &.m-0 {
    margin: 0;
  }

  &.text-center {
    text-align: center;
    margin: 0 auto;
  }

  &.mb-30 {
    margin-bottom: 30px;
  }
`;

export const Text = styled.p`
  color: #ffffff;
  font-size: 18px;

  &.text-center {
    text-align: center;
    margin: 0 auto;
    max-width: 80%;
  }

  &.mb-30 {
    margin-bottom: 30px;
  }

  &.smaller {
    font-size: 14px;

    @media (min-width: 768px) {
      font-size: 20px;
    }
  }

  @media (min-width: 768px) {
    font-size: 20px;
  }
`;

export const Destaque = styled.span`
  background: #7386ff;
  border-radius: 4px;
  padding: 2px 8px;
  cursor: default;
  color: #ffffff;
  font-weight: bold;
  letter-spacing: 0.1px;
`;

export const Button = styled.button`
  background: #ff0c73;
  padding: 8px 16px;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 40px;
  box-shadow: 0px 0px 17px -7px rgba(0, 0, 0, 0.75);
  transition: all 0.5s ease;
  cursor: pointer;

  a {
    color: #ffffff;
    &:hover {
      color: #ffffff;
    }
  }

  &.text-normal {
    font-weight: 400;
  }

  @media (min-width: 768px) {
    font-size: 20px;
    padding: 12px 30px;
  }

  &.center {
    display: block;
    margin: 0 auto;
  }

  &.center-sm {
    @media (max-width: 767px) {
      display: block;
      margin: 0 auto 10px auto;
    }
  }

  &:hover {
    background: #fe006c;
  }
`;

export const ListCards = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 40px 0;
  margin-top: -80px;
  z-index: 2;

  @media (max-width: 767px) {
    margin-top: -80px;
  }
`;

export const Card = styled.li`
  width: 100%;
  max-width: 790px;
  height: 164px;
  box-shadow: 0px 0px 17px -7px rgba(0, 0, 0, 0.75);
  background: #7386ff;
  border-radius: 38px;
  transform: scale(1);
  padding: 20px;

  &.center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:first-child {
    background: #1c11a7;
  }

  @media (max-width: 767px) {
    padding: 10px;
    border-radius: 20px;
    height: 150px;
  }
`;

export const Icon = styled.img`
  width: 150%;
  object-fit: contain;

  @media (min-width: 768px) {
    display: inline-block;
    width: 80%;
    margin: 0 auto;
  }
`;
