import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${p => (p.column ? 'column' : 'row')};
  align-items: center;
  justify-content: ${p => (p.justifyCenter ? 'center' : 'space-between')};
  width: 100%;
  max-width: 1340px;
  margin: ${p => (p.margin ? p.margin : '0 auto')};
  padding: 20px;
  height: 100%;

  @media (min-width: 768px) {
    padding: 0 50px;
  }

  @media (min-width: 992px) {
    padding: var(--border-distance);
  }
`;

export const Title = styled(motion.h5)`
  letter-spacing: 0.6px;
  text-align: center;

  @media (min-width: 768px) {
    font-size: 28px;
  }

  @media (min-width: 992px) {
    font-size: 32px;
  }
`;

export const Text = styled.p`
  font-size: 24px;
  line-height: 32px;
  color: #212121;
  font-weight: 600;
  text-align: right;
  max-width: 480px;
`;

export const Section = styled.section`
  width: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 40px;
  height: 500px;
  background: ${p => p.bgcolor};

  @media (min-width: 768px) {
    padding-top: 20px;
    height: 520px;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  width: 100%;
  height: 440px;

  @media (min-width: 768px) {
    height: 400px;
  }
`;
