import styled from 'styled-components';
import { motion } from 'framer-motion';
import { BgHomeAtendemos, BgHomeAtendemosMobile } from '../../assets';

export const ContainerFull = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 624px;
  margin-top: auto;
  overflow: hidden;

  @media (max-width: 768px) {
    background: url(${BgHomeAtendemosMobile}), #0c053f;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: center 0;
    height: 760px;
  }

  @media (min-width: 768px) {
    background: url(${BgHomeAtendemos}), #0c053f;
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: center 0;
    min-height: 624px;
  }

  &.separator-bootom {
    border-bottom: solid 1px rgba(255, 255, 255, 0.1);
  }
`;

export const Selo = styled(motion.img)`
  position: absolute;
  top: 32px;
  left: 70px;
`;

export const Title = styled(motion.h1)`
  font-size: 38px;
  letter-spacing: 0;
  color: #ffffff;
  font-weight: 200;
  margin-top: 236px;
  padding-left: 100px;

  span {
    font-weight: 600;
  }

  @media (max-width: 768px) {
    margin-top: 110px;
    padding: 0 20px;
    font-size: 26px;
  }
`;

export const Text = styled(motion.p)`
  font-size: 23px;
  line-height: 32px;
  color: #ffffff;
  font-weight: 100;
  padding-left: 100px;

  @media (max-width: 768px) {
    font-size: 18px;
    padding: 0 20px;
  }

  @media (min-width: 768px) {
    margin-top: 10px;
  }
`;

export const ButtonClose = styled.button`
  width: 30px;
  height: 30px;
  border: 0;
  outline: 0;
  cursor: pointer;
  display: flex;
  background: transparent;
  align-items: center;
  justify-content: center;
`;

export const SaibaMaisWrapper = styled(motion.div)`
  margin-top: 60px;
  margin-left: 100px;
`;

export const SaibaMais = styled.div`
  position: relative;
  width: 120px;
  opacity: 1;
  display: inline-flex;
  align-items: center;
  padding-right: 30px;
  font-family: 'Poppins', sans-serif;
  letter-spacing: 1px;
  font-size: 14px;
  color: #ffffff;
  transition: 0.25s ease-in-out all;
  transform-origin: center;
  transform: scale(1);
  cursor: pointer;

  &:hover {
    span {
      width: 144px;
    }
  }
`;

export const Icone = styled.span`
  position: absolute;
  top: 50%;
  right: -10px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 30px;
  height: 30px;
  background: ${p => p.bulletColor};
  border-radius: 30px;
  padding-right: 4px;
  z-index: -1;
  transition: 0.25s ease-in-out all;

  @media (max-width: 767px) {
    background: #7386ff !important;
  }

  svg {
    width: 21px;
    height: auto;
    color: ${p => (p.color ? p.color : '#ffffff')}
    box-shadow: 0px 0px 17px -7px rgba(0, 0, 0, 0.75);
  }
`;

export const LineNumbers = styled(motion.div)`
  display: flex;
  align-items: center;
  height: 66px;
  margin-top: 42px;

  span {
    display: inline-block;
    height: 1px;
    background: #ffffff;
    &.line-100 {
      width: 100px;
      min-width: 100px;
    }
    &.line-80 {
      width: 80px;
      min-width: 80px;
    }
    &.line-auto {
      width: 100%;
    }
  }

  @media (max-width: 767px) {
    margin-top: 24px;
    flex-direction: column;
    height: 340px;

    span {
      display: inline-block;
      width: 1px !important;
      min-width: 1px !important;
      background: #ffffff;
      &.line-100 {
        height: 34px;
        min-height: 34px;
      }
      &.line-80 {
        height: 50px;
        min-height: 50px;
      }
      &.line-auto {
        display: none;
      }
    }
  }
`;

export const BoxText = styled.div`
  display: flex;
  height: 66px;
  padding: 0px 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid var(--principais-do-site-branco, #fff);
  background: rgba(0, 0, 0, 0.01);
  box-shadow: 0px 7px 7px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(7.5px);
  color: #ffffff;

  h2 {
    font-size: 46px;
    color: #ffffff;
  }

  p {
    color: #ffffff;
    font-weight: 100;
    font-size: 15px;
    line-height: 1.4rem;
    text-transform: uppercase;
    white-space: nowrap;
  }
`;
