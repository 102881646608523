/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { theme } from '../../App.styled';
import { ThemeProvider } from 'styled-components';
import { ContainerFull,
          Container,
          ImageContainer,
          TextContainer,
          Text,
          Title } from './index.styled';

const HeaderSectionImageTextComponent = ({ style, className, title, text, themeType, image }) => {

  return (
    <ThemeProvider theme={{ type: theme[themeType] }}>
      <ContainerFull style={style} className={className}>
        <Container>
          <TextContainer>
            <Title
              dangerouslySetInnerHTML={{ __html: title }}
             initial={{ opacity: 0, y: 10 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{
                duration: 0.5,
                delay: 0.3,
              }} />
            <Text
              dangerouslySetInnerHTML={{ __html: text }}
              initial={{ opacity: 0, y: 10 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{
                duration: 0.5,
                delay: 0.3,
              }}/>
          </TextContainer>
          <ImageContainer image={image} />
        </Container>
      </ContainerFull>
    </ThemeProvider>
  );
};

HeaderSectionImageTextComponent.propTypes = {
  themeType: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  image: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.shape]),
};

HeaderSectionImageTextComponent.defaultProps = {
  title: 'My button',
  image:"ImageParaEmpresa",
  text: 'My text',
  themeType: 'default',
  className: '',
  style: {},
};

export default HeaderSectionImageTextComponent;
