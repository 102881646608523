/* eslint-disable react/destructuring-assignment */
import React, { useState, useRef, useEffect } from 'react';
import { getImage } from '../../../utils';
import { useResize } from '../../../hooks';
import { Container } from './index.styled';

const PlayerComponent = data => {
  const { isMobile, isTablet, width } = useResize();
  const [active, setActive] = useState(false);
  const ref = useRef(null);
  const [proportion, setProportion] = useState(1);
  const margem = 60;

  const { id, image, video, title, logo, count } = data.data;

  useEffect(() => {
    // get width image percent
    const proporcao = ref?.current ? ref.current.offsetWidth / 370 : 1;
    setProportion(proporcao);
  }, [width, proportion]);

  const toggleImageHandler = () => {
    if (isMobile || isTablet) {
      setActive(!active);
    }
  };

  const getMargin = () => {
    if (width < 1200) return 0;
    // first central image
    if (count === 1) {
      return margem;
    }

    //  left column
    if (count % 3 === 0 && count > 0) {
      return -margem;
    }

    // right column
    if ((count + 1) % 3 === 0 && count > 3) {
      return -margem;
    }

    return 0;
  };

  const returnImage = () => {
    const imageSrc = active ? video : image;
    return getImage(imageSrc);
  };

  const updateStateActive = ativo => {
    if (!isMobile && !isTablet) {
      setActive(ativo);
    }
  };
  return (
    <Container
      onMouseOver={() => updateStateActive(true)}
      onMouseLeave={() => updateStateActive(false)}
      onClick={toggleImageHandler}
      ref={ref}
      key={id}
      className={active ? 'active' : ''}
      size={proportion * 526}
      bgImage={getImage(logo)}
      style={{ marginTop: getMargin() }}
    >
      <img src={returnImage()} alt={title} />
    </Container>
  );
};

export default PlayerComponent;
