/* eslint-disable prettier/prettier */
import React from 'react';
import { useResize } from '../../hooks';
import { Description } from '..';
import { ContainerFluid, Container, WrapperContent } from './index.styled';

const PoliticaComponent = () => {
  const { isMobile } = useResize();

  const textMarginDesktop = '20px';
  return (
    <ContainerFluid id="politica" className="fluid">
      <Container>
        <WrapperContent>
          <div className="wrapper-title">
            <p className="destaque smaller">POLÍTICA DE PRIVACIDADE</p>
            <p>Última atualização: 05/11/2023.</p>
          </div>
          <Description
            style={{ marginTop: isMobile ? '10px' : textMarginDesktop }}
            fontNormal
            color="#000000"
          >
            A YUNO ON (nós, nosso ou aplicativo) está comprometido com a
            proteção de sua privacidade. Esta política de privavidade descreve
            como coletamos, usamos, divulgamos e protegemos informações pessoais
            dos usuários do nosso aplicativo.
          </Description>
          <Description
            style={{ marginTop: isMobile ? '24px' : '30px' }}
            color="#000000"
            type="description"
          >
            Informações coletadas
          </Description>
          <Description
            style={{ marginTop: isMobile ? '10px' : textMarginDesktop }}
            fontNormal
            color="#000000"
          >
            Quando você se cadastra ou faz login no nosso aplicativo, coletamos
            informações pessoais, como seu nome de usuário, endereço de email e
            senha.
          </Description>
          <Description
            style={{ marginTop: isMobile ? '10px' : textMarginDesktop }}
            fontNormal
            color="#000000"
          >
            Opcionalmente, você pode fornecer informações adicionais para criar
            um perfil, como foto de perfil, nome completo, data de nascimento,
            gênero, etc.
          </Description>
          <Description
            style={{ marginTop: isMobile ? '10px' : textMarginDesktop }}
            fontNormal
            color="#000000"
          >
            Coletamos informações sobre como você interage com o aplicativo,
            como páginas visitadas, ações realizadas, datas e horários
            de acesso e outros dados de uso.
          </Description>
          <Description
            style={{ marginTop: isMobile ? '24px' : '30px' }}
            color="#000000"
            type="description"
          >
            Uso de informações
          </Description>
          <Description
            style={{ marginTop: isMobile ? '10px' : textMarginDesktop }}
            fontNormal
            color="#000000"
          >
            Usamos suas informações para fornecer, manter e melhorar os serviços
            do aplicativo, incluindo autenticação, personalização e comunicação
            com você.
          </Description>
          <Description
            style={{ marginTop: isMobile ? '10px' : textMarginDesktop }}
            fontNormal
            color="#000000"
          >
            Podemos entrar em contato com você para enviar atualizações, notificações
            importantes e informações relacionadas ao serviço.
          </Description>
          <Description
            style={{ marginTop: isMobile ? '10px' : textMarginDesktop }}
            fontNormal
            color="#000000"
          >
            Usamos informações agregadas e anônimas para análises de dados,
            pesquisas de mercado e melhorias no aplicativo.
          </Description>
          <Description
            style={{ marginTop: isMobile ? '24px' : '30px' }}
            color="#000000"
            type="description"
          >
            Compartilhamento de Informações
          </Description>
          <Description
            style={{ marginTop: isMobile ? '10px' : textMarginDesktop }}
            fontNormal
            color="#000000"
          >
            Não compartilhamos suas informações pessoais com terceiros, exceto nos seguintes casos: <br/>
- Quando você nos autoriza a compartilhar suas informações.<br/>
- Para cumprir obrigações legais.<br/>
- Para proteger nossos direitos, privacidade, segurança e propriedade, bem como a segurança de nossos usuários e do público em geral.
          </Description>
          <Description
            style={{ marginTop: isMobile ? '24px' : '30px' }}
            color="#000000"
            type="description"
          >
            Seus direitos
          </Description>
          <Description
            style={{ marginTop: isMobile ? '10px' : textMarginDesktop }}
            fontNormal
            color="#000000"
          >
            Você tem o direito de acessar, corrigir, atualizar ou excluir suas informações pessoais
            a qualquer momento. Para exercer esses direitos, entre em contato conosco através do e-mail
            contato@yunofp.com.br.
          </Description>
          <Description
            style={{ marginTop: isMobile ? '24px' : '30px' }}
            color="#000000"
            type="description"
          >
            Contato
          </Description>
          <Description
            style={{ marginTop: isMobile ? '10px' : textMarginDesktop }}
            fontNormal
            color="#000000"
          >
           Se você tiver alguma dúvida sobre esta política de privacidade ou sobre 
           como suas informações são tratadas, entre em contato conosco em
           contato@yunofp.com.br.
          </Description>          
        </WrapperContent>
      </Container>
    </ContainerFluid>
  );
};

export default PoliticaComponent;
