/* eslint-disable */
import React from 'react';
import { Container, ContainerFull } from '../../assets/globalStyle';
import {
  PlanejamentoFinanceiroPessoal,
  TransformarVidaFinanceira,
  ParaGarantirmosIsso,
  VaiMuitoAlem,
  SeuDinheiro,
  ConhecaYuno,
  ConhecaQuem,
} from '../../components';

function ParaVocePage() {
  return (
    <React.Fragment>
      <ContainerFull>
        <PlanejamentoFinanceiroPessoal />
      </ContainerFull>
      <ContainerFull>
        <ParaGarantirmosIsso />
      </ContainerFull>
      <ContainerFull>
        <TransformarVidaFinanceira />
      </ContainerFull>
      {/*
        <Container column className="para-voce">
          <VaiMuitoAlem />
          <SeuDinheiro />
          <ConhecaQuem />
          <ConhecaYuno />
        </Container>
      */}
    </React.Fragment>
  );
}

export default ParaVocePage;
