import styled from 'styled-components';
import { motion } from 'framer-motion';
import { SeparatorX } from '../../assets';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${p => (p.column ? 'column' : 'row')};
  align-items: center;
  justify-content: ${p => (p.justifyCenter ? 'center' : 'space-between')};
  width: 100%;
  max-width: 1340px;
  margin: ${p => (p.margin ? p.margin : '0 auto')};
  padding: 20px;
  height: 100%;

  @media (min-width: 768px) {
    padding: ${p => (p.distance ? 'var(--border-distance)' : '0')};
  }
`;

export const ControleContainer = styled(motion.div)`
  position: relative;
  gap: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  max-width: 1340px;
  margin: 90px 0 40px 0;
  padding: 0 20px;
  height: 100%;
  flex-grow: 1;

  @media (min-width: 768px) {
    margin: 90px 0 70px 0;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    padding: ${p => (p.distance ? 'var(--border-distance)' : '0')};
    flex-grow: 1;
  }

  @media (min-width: 992px) {
    flex-wrap: initial;
    padding: 20px;
    flex-grow: 1;
  }
`;

export const CardControle = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 44px 20px;
  width: 100%;
  height: auto;
  align-self: stretch;
  color: #212121;

  h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #212121;

    @media (min-width: 768px) {
      font-size: 32px;
      line-height: 48px;
    }
  }

  br {
    @media (min-width: 768px) {
      display: none;
    }
  }

  background: #ffffff;
  box-shadow: 0px 12px 36px rgba(58, 52, 122, 0.1);
  border-radius: 16px;

  @media (min-width: 768px) {
    padding: 64px 40px;
  }

  @media (min-width: 992px) {
    padding: 64px 50px;
  }
`;

export const List = styled(motion.ul)`
  width: 100%;
  text-align: left;
`;

export const Item = styled(motion.li)`
  display: flex;
  align-items: center;
  text-align: left;

  p {
    font-weight: 500;
    font-size: 18px;
    line-height: 160%;
    text-align: left;
    color: #757575;
    display: grid;
    grid-template-columns: 30px 1fr;
  }

  svg {
    color: #5960ff;
    margin-right: 10px;
    margin-top: 4px;
  }
  svg.red {
    color: #ff0000;
  }
`;

export const Title = styled(motion.h5)`
  text-align: center;
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
  color: #212121;
  margin: 0;

  &.purple {
    color: #7386ff;
  }

  @media (min-width: 768px) {
    font-size: 40px;
    line-height: 48px;
    margin: 0 0 40px 0;
  }
`;

export const Img = styled(motion.img)`
  max-width: 90%;
  border-radius: 200px;
`;

export const Button = styled(motion.button)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: center;
  color: #ffffff;
  border: solid 1px currentColor;
  border-radius: 50px;
  background: #7386ff;
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 12px 40px;
  opacity: 1;

  &:hover {
    opacity: 0.9 !important;
  }

  @media (min-width: 768px) {
    font-size: 24px;
    margin-right: 18px;
    padding: 14px 82px;
  }
`;

export const Separator = styled(motion.div)`
  position: absolute;
  width: 64px;
  height: 64px;
  background-image: url(${SeparatorX});
  background-repeat: no-repeat;
  top: 49%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
`;

export const Text = styled(motion.p)`
  font-size: 16px;
  line-height: 24px;
  color: #212121;
  font-weight: 400;
  text-align: center;
  max-width: 80%;
  margin: ${p => (p.margin ? p.margin : '0 0')};
  @media (min-width: 768px) {
    font-size: 20px;
    line-height: 32px;
  }
`;

export const Section = styled.section`
  width: inherit;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 0px;
  min-height: 640px;
  background: ${p => p.bgcolor};

  @media (min-width: 768px) {
    margin-top: 150px;
    min-height: 600px;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  height: auto;

  @media (min-width: 768px) {
    flex-direction: column;
    height: auto;
    padding-bottom: 100px;
  }
`;
