import React, { useEffect, useState } from 'react';
import { HashLink } from 'react-router-hash-link';
import { Container } from '../../assets/globalStyle';
import {
  ContainerFluid,
  Row,
  Img,
  Title,
  SubTitle,
  Text,
  Button,
  ListCards,
  Card,
  Icon,
  Destaque,
} from './index.styled';
import Logo from './assets/logo-header.png';
import AnimacaoDireita from './assets/$yh72ew7usp.gif';
import AnimacaoDireitaViva from './assets/$y9qdjhz6ena.gif';
import Icon1 from './assets/icon-1.png';
import Icon2 from './assets/icon-2.png';
import Icon3 from './assets/icon-3.png';

function EscolhasViverOuInvestirCampanha() {
  const [formCreated, setFormCreated] = useState(false);

  useEffect(() => {
    if (window.RDStationForms && !formCreated) {
      new window.RDStationForms(
        'formulario-teste-0696838f5b3812fb760b',
        'UA-148177266-4'
      ).createForm();
      setFormCreated(true);
    }
  }, [formCreated]);

  return (
    <>
      <ContainerFluid className="container-fluid purple">
        <Container style={{ minHeight: 'inherit' }}>
          <Row className="row no-gutters align-items-center">
            <div className="col-12 col-md-6">
              <Img className="img-logo" src={Logo} alt="Yuno" />
              <Title className="mb-30">
                Curtir o presente ou investir no futuro?
              </Title>
              <Text className="mb-30">
                Com a <b>Yuno</b> você não precisa escolher!
              </Text>
              <Button className="center-sm" type="button">
                <HashLink to="./#fale-conosco">
                  Conheça o jeito Yuno de ser
                </HashLink>
              </Button>
            </div>
            <div className="col-12 col-md-6 align-self-end">
              <Img className="anim-dir" src={AnimacaoDireita} alt="Yuno" />
            </div>
          </Row>
        </Container>
      </ContainerFluid>
      <ContainerFluid className="container-fluid h-auto">
        <Container style={{ minHeight: 'inherit' }}>
          <Row className="row no-gutters no-hidden">
            <div className="col-12">
              <ListCards>
                <Card className="center">
                  <Title className="w-100 text-center bigger">
                    O Planejamento Financeiro que não corta o cafézinho
                  </Title>
                </Card>
                <Card>
                  <div className="row align-items-center h-100">
                    <div className="col-3">
                      <Icon src={Icon1} alt="Único e Personalizado" />
                    </div>
                    <div className="col-9">
                      <SubTitle className="m-0">Único e personalizado</SubTitle>
                      <Text className="smaller">
                        Sem fórmulas mágicas, levando em consideração o seu
                        contexto de vida.
                      </Text>
                    </div>
                  </div>
                </Card>
                <Card>
                  <div className="row align-items-center h-100">
                    <div className="col-3">
                      <Icon src={Icon2} alt="Prático" />
                    </div>
                    <div className="col-9">
                      <SubTitle className="m-0">Prático</SubTitle>
                      <Text className="smaller">
                        Para você que não tem tempo de estudar sobre finanças.
                      </Text>
                    </div>
                  </div>
                </Card>
                <Card>
                  <div className="row align-items-center h-100">
                    <div className="col-3">
                      <Icon src={Icon3} alt="Descomplicado" />
                    </div>
                    <div className="col-9">
                      <SubTitle className="m-0">Descomplicado</SubTitle>
                      <Text className="smaller">
                        Muitas coisas podem ser complicadas, o seu dinheiro e
                        futuros investimentos não precisam ser uma delas.
                      </Text>
                    </div>
                  </div>
                </Card>
              </ListCards>
            </div>
          </Row>
        </Container>
      </ContainerFluid>
      <ContainerFluid className="container-fluid purple short">
        <Container style={{ minHeight: 'inherit' }}>
          <Row className="row no-gutters align-items-center">
            <div className="col-12 col-md-6">
              <SubTitle className="mb-30 text-center">
                Errado é pensar que dinheiro não traz felicidade!
              </SubTitle>
              <Text className="mb-30 text-center">
                Luxo é aproveitar o presente enquanto se planeja o futuro
              </Text>
              <Button className="center" type="button">
                <HashLink to="./#fale-conosco">VIVA MELHOR AGORA</HashLink>
              </Button>
            </div>
            <div className="col-12 col-md-6 align-self-end">
              <Img className="anim-dir" src={AnimacaoDireitaViva} alt="Yuno" />
            </div>
          </Row>
        </Container>
      </ContainerFluid>
      <ContainerFluid className="container-fluid blue">
        <Container
          style={{ minHeight: 'inherit', paddingBottom: '40px' }}
          id="fale-conosco"
        >
          <Row className="row no-gutters align-items-center">
            <div className="col-12 col-md-6">
              <Title className="mb-30">Escolha viver melhor agora!</Title>
              <Text className="mb-30">
                Abrir mão do prazer de viver o hoje para construir um futuro não
                precisa mais ser um problema para você.
              </Text>
              <Text>
                <Destaque>Conheça o jeito Yuno de ser</Destaque>
              </Text>
            </div>
            <div className="col-12 col-md-6 align-self-center">
              <SubTitle className="smaller">
                Fale com a gente e conheça o melhor de uma{' '}
                <Destaque>vida planejada</Destaque>
              </SubTitle>
              <div role="main" id="formulario-teste-0696838f5b3812fb760b" />
              {/* <Button className="center-sm text-normal" type="button">
                Conhecer o planenamento Yuno
              </Button> */}
            </div>
          </Row>
        </Container>
      </ContainerFluid>
    </>
  );
}

export default EscolhasViverOuInvestirCampanha;
