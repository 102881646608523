import React from 'react';
import { Container } from '../../assets/globalStyle';
import { DescompliqueFinancas, VideoGallery } from '../../components';

function ParaAprenderPage() {
  return (
    <Container column>
      <DescompliqueFinancas />
      <VideoGallery />
    </Container>
  );
}

export default ParaAprenderPage;
