import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ButtonWrapper } from './index.styled';

const ButtonNavigateToTopComponent = ({ style }) => {
  const navigateToTop = e => {
    e.preventDefault();
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'auto',
    });
  };
  return (
    <ButtonWrapper style={style}>
      <Link to="/" onClick={navigateToTop}>
        &nbsp;
      </Link>
    </ButtonWrapper>
  );
};

ButtonNavigateToTopComponent.propTypes = {
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.shape]),
};

ButtonNavigateToTopComponent.defaultProps = {
  style: {},
};

export default ButtonNavigateToTopComponent;
